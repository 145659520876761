@charset "UTF-8";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * TABLE OF CONTENTS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * 1. Fonts
 * 2. Icons
 * 3. Layout
 * 4. Generic
 *  4.1 Typography
 *  4.2 Forms
 *  ..
 * 5. Content blocks (aka widgets/modules)
 * 6. Specific pages
 *  6.1 Product
 *  6.2 Category
 *  6.3 Checkout
 *  ..
 * 7. Browser specific
 * 8. Color schemes
 * 9. Shared Properties
 * */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * * PAGE - CHECKOUT
 * * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.checkout-block form {
  margin-bottom: 3em;
}

.payment-shipping .costs {
  margin-top: 6px;
}

.payment-shipping-methods .control {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
.payment-shipping-methods .control > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.payment-shipping-methods .control h3 {
  margin: 0;
  padding-left: 26px;
  position: relative;
}
.payment-shipping-methods .control h3:before {
  content: "";
  display: table;
}
.payment-shipping-methods .control h3:after {
  content: "";
  display: table;
  clear: both;
}
.payment-shipping-methods .control h3 label {
  float: left;
}
.payment-shipping-methods .control h3 input {
  float: left;
}
.payment-shipping-methods .control h3 input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
}
.payment-shipping-methods .control h3 label {
  display: block;
  width: 100%;
}
.payment-shipping-methods .control h3 .price.zero {
  display: none;
}
.payment-shipping-methods .control .figure {
  right: 75px;
  position: absolute;
  display: block;
  max-width: 80px;
  max-height: 40px;
}
.payment-shipping-methods .control .figure img {
  max-width: 80px;
  max-height: 40px;
}
.payment-shipping-methods .control .figure b {
  text-align: left;
}
.payment-shipping-methods .control .figure b img {
  height: auto;
}
.payment-shipping-methods .control p, .payment-shipping-methods .control .description {
  margin: 3px 0 0 27px;
  display: none;
}
.payment-shipping-methods .control li.checked h3 {
  margin-bottom: 0;
}
.payment-shipping-methods .control h3 .caption {
  display: block;
  margin-right: 0;
  padding: 0 160px 0 0;
  line-height: 18px;
}
.payment-shipping-methods .control li.checked .price {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
}
.payment-shipping-methods .control li.checked .price:before {
  display: none;
}
.payment-shipping-methods .control li.checked .price.zero {
  display: inline;
}
.payment-shipping-methods .control li.checked p, .payment-shipping-methods .control li.checked .description {
  margin: 0.4em 0 0 26px;
  display: block;
}

.summary p:before {
  content: "";
  display: table;
}
.summary p:after {
  content: "";
  display: table;
  clear: both;
}
.summary .caption {
  float: left;
}
.summary .price, .summary .weight {
  float: right;
}
.summary .grand-total h3 {
  display: inline;
}
.summary .left-for-free-shipping, .summary .account {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .summary .left-for-free-shipping, .summary .account {
    text-align: left;
  }
}
.field-wrap.customer-type .control:before, .field-wrap.customer-type .control:after {
  content: "";
  display: table;
}
.field-wrap.account-type .control:before, .field-wrap.account-type .control:after {
  content: "";
  display: table;
}
.field-wrap.customer-type .control:after, .field-wrap.account-type .control:after {
  clear: both;
}
.field-wrap.customer-type .control p, .field-wrap.account-type .control p {
  float: left;
  margin-right: 1em;
}

@media only screen and (max-width: 460px) {
  .payment-shipping-methods .control .figure {
    position: static;
    left: auto;
  }
  .payment-shipping-methods .control h3 .caption {
    padding: 0 40px 0 0;
    margin-top: 10px;
  }
  .payment-shipping-methods .control li.checked .price {
    top: 26px;
  }
  .payment-shipping-methods .control li.checked p {
    margin-right: 0px !important;
  }
  .payment-methods .checked .payment-method-options span.selected:after {
    display: none;
  }
}
.payment-method-content {
  margin: 10px 0 0 0;
}
.payment-method-content:after {
  content: "";
  display: table;
  clear: both;
}
.payment-method-content h4 {
  margin-top: 0;
}
.payment-method-content p {
  margin: 0 !important;
}

.payment-method-options, .selected-option-info {
  float: left;
  margin-bottom: 10px;
}

.payment-method-options span {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * * CARTS
 * * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.checkout-cart .description:before, .checkout-cart .description:after,
.checkout-cart .price-wrap:before,
.checkout-cart .price-wrap:after,
.checkout-cart .cart-item:before,
.checkout-cart .cart-item:after,
.import-cart .description:before,
.import-cart .description:after,
.import-cart .price-wrap:before,
.import-cart .price-wrap:after,
.import-cart .cart-item:before,
.import-cart .cart-item:after {
  content: "";
  display: table;
}

.import-cart .cart-item:before, .import-cart .cart-item:after,
.import-cart .cart-head ul:before,
.import-cart .cart-head ul:after {
  content: "";
  display: table;
}

.checkout-cart .cart-head ul:before, .checkout-cart .cart-head ul:after,
.import-cart .cart-head ul:before,
.import-cart .cart-head ul:after {
  content: "";
  display: table;
}

.checkout-cart .description:after,
.checkout-cart .price-wrap:after,
.checkout-cart .cart-item:after {
  clear: both;
}

.import-cart .cart-item:after,
.import-cart .cart-head ul:after {
  clear: both;
}

.checkout-cart .cart-head ul:after,
.import-cart .cart-head ul:after {
  clear: both;
}

.checkout-cart .cart-item:after,
.import-cart .cart-item:after {
  clear: both;
}

.checkout-cart,
.import-cart {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
.checkout-cart ul,
.import-cart ul {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}

.checkout-cart > li,
.checkout-cart ul > li,
.import-cart > li,
.import-cart ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}

.checkout-cart .cart-head li,
.import-cart .cart-head li {
  float: right;
}

.checkout-cart .cart-head .description,
.import-cart .cart-head .description {
  width: 41%;
  float: left;
  line-height: 1em;
}

.checkout-cart .cart-head .unit-price,
.import-cart .cart-head .unit-price {
  width: 15%;
  text-align: left;
}

.checkout-cart .cart-head .quantity,
.import-cart .cart-head .quantity {
  width: 15%;
}

.checkout-cart .cart-head .price,
.import-cart .cart-head .price {
  width: 9%;
}

.checkout-cart .cart-item,
.import-cart .cart-item {
  position: relative;
}

.checkout-cart .cart-item .variant:before,
.checkout-cart .cart-item .item-weight:before,
.import-cart .cart-item .variant:before,
.import-cart .cart-item .item-weight:before {
  content: ", ";
}

.checkout-cart .cart-item h4 span:first-child:before,
.import-cart .cart-item h4 span:first-child:before {
  content: "";
}

.checkout-cart .cart-item .stock-status-locations {
  display: inline-grid;
  font-size: 0.8rem;
  opacity: 0.6;
  margin-top: 5px;
  width: 100%;
  max-height: 115px;
  overflow: hidden;
}
.checkout-cart .cart-item .stock-status-locations span {
  line-height: 1.2em;
}
.checkout-cart .cart-item .stock-status-locations span .stock-name {
  float: left;
}
.checkout-cart .cart-item .stock-status-locations span .stock-quantity {
  float: right;
}

.checkout-cart .cart-item .stock-status-wrap .expand-checkout-stock-locations-button {
  display: none;
  margin-top: 3px;
  font-size: 0.85rem;
}
.checkout-cart .cart-item .stock-status-wrap .expand-checkout-stock-locations-button .less {
  display: none;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .stock-status-locations {
  max-height: none !important;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .expand-checkout-stock-locations-button .less {
  display: block;
}
.checkout-cart .cart-item .stock-status-wrap.expanded .expand-checkout-stock-locations-button .more {
  display: none;
}

.checkout-cart .cart-item .sku:before, .checkout-cart .cart-item .item-weight:before,
.import-cart .cart-item .sku:before,
.import-cart .cart-item .item-weight:before {
  content: ", " attr(data-title);
  padding-right: 4px;
}

.checkout-cart .cart-item.error,
.import-cart .cart-item.error {
  border: 1px solid red;
  padding-bottom: 40px !important;
}

.checkout-cart .cart-item.error .error,
.import-cart .cart-item.error .error {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
}

.checkout-cart .cart-item.error .error:before,
.import-cart .cart-item.error .error:before {
  display: none;
}

.checkout-cart .description, .checkout-cart .price-wrap {
  float: left;
  width: 50%;
}

.import-cart .description, .import-cart .price-wrap {
  float: left;
  width: 50%;
}

.checkout-cart .description > div, .checkout-cart .price-wrap > div {
  float: left;
}

.import-cart .description > div, .import-cart .price-wrap > div {
  float: left;
}

.checkout-cart .description > div a, .checkout-cart .price-wrap > div a,
.import-cart .description > div a,
.import-cart .price-wrap > div a {
  text-decoration: none;
}

.checkout-cart .description > div.quantity, .checkout-cart .description > div.delete, .checkout-cart .description > div.total-price, .checkout-cart .description > div.unit-price,
.import-cart .description > div.quantity,
.import-cart .description > div.delete,
.import-cart .description > div.total-price,
.import-cart .description > div.unit-price {
  float: right;
}
.checkout-cart .price-wrap > div.quantity, .checkout-cart .price-wrap > div.delete, .checkout-cart .price-wrap > div.total-price, .checkout-cart .price-wrap > div.unit-price,
.import-cart .price-wrap > div.quantity,
.import-cart .price-wrap > div.delete,
.import-cart .price-wrap > div.total-price,
.import-cart .price-wrap > div.unit-price {
  float: right;
}

.checkout-cart .image,
.import-cart .image {
  width: 14%;
}

.checkout-cart .title,
.import-cart .title {
  width: 86%;
}

.checkout-cart .quantity,
.import-cart .quantity {
  width: 35%;
}

.checkout-cart .unit-price, .checkout-cart .total-price {
  width: 32%;
  text-align: right;
}

.import-cart .unit-price, .import-cart .total-price {
  width: 32%;
  text-align: right;
}

.checkout-cart .quantity .quantity-field,
.import-cart .quantity .quantity-field {
  width: 70px;
  text-align: right;
}

.checkout-cart .quantity p, .checkout-cart .quantity button, .checkout-cart .quantity .quantity-field {
  float: right;
}

.import-cart .quantity p, .import-cart .quantity button, .import-cart .quantity .quantity-field {
  float: right;
}

.checkout-cart .quantity p,
.import-cart .quantity p {
  margin: 0;
  padding: 0;
  text-align: center;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FONTS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/OpenSans-Light.eot");
  src: url("../fonts/open-sans/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/OpenSans-Light.woff") format("woff"), url("../fonts/open-sans/OpenSans-Light.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Light.svg#") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/OpenSans-Regular.eot");
  src: url("../fonts/open-sans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/OpenSans-Regular.woff") format("woff"), url("../fonts/open-sans/OpenSans-Regular.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Regular.svg#") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/OpenSans-Italic.eot");
  src: url("../fonts/open-sans/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/OpenSans-Italic.woff") format("woff"), url("../fonts/open-sans/OpenSans-Italic.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Italic.svg#") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/OpenSans-Semibold.eot");
  src: url("../fonts/open-sans/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/OpenSans-Semibold.woff") format("woff"), url("../fonts/open-sans/OpenSans-Semibold.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Semibold.svg#") format("svg");
  font-weight: 600;
  font-style: normal;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * ICONS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@font-face {
  font-family: "swt";
  src: url("../../lib/fonts/swt-1.3/fonts/swt.eot");
  src: url("../../lib/fonts/swt-1.3/fonts/swt.eot?#iefix") format("embedded-opentype"), url("../../lib/fonts/swt-1.3/fonts/swt.woff") format("woff"), url("../../lib/fonts/swt-1.3/fonts/swt.ttf") format("truetype"), url("../../lib/fonts/swt-1.3/fonts/swt.svg#") format("svg");
  font-weight: normal;
  font-style: normal;
}
.swt-icon, .unifaun-checkout-option-header0 .unifaun-checkout-option-agents-wrapper:after, .icon-container .quick-view:before, .icon-container .favorite:before, .shopping-favorite-cart .cart-content-close:after, .shopping-favorite-cart .favorite:before, .favorite-list .add-to-cart:before, .open-off-canvas-favorites .favorite:before, .favorite:before, .favorite-cart-icon:before, #checkout-layout li a:before, .select-list:before, .filter-group-title:after, .remove-active-filter-action:after, .filter-menu-item:before, .clear-import-items:before,
.cancel-preview-cart-import:before, .rating-selector label:after, .rating:before, .rating:after, .select-list-adresses .select-list-option-selected .icon-selected-address:before, .select-list-adresses .toggle-favorite-address:before,
.select-list-adresses .icon-selected-address:before, .checkout-cart .decrease-quantity-action:after,
.import-cart .decrease-quantity-action:after, .checkout-cart .increase-quantity-action:after,
.import-cart .increase-quantity-action:after, p.toggle a:before, .gallery-layout a, .gallery-layout a:visited, .pagination .next > *:after, .pagination .previous > *:after, .add-to-wish-list:before, .product-offer .info:before, .product-offer .add-to-cart:before, .previous-next .next:after, .previous-next .previous:before, .select-locale-button-content .icon:before, .select-locale-modal-close-button:before, #site-footer .gallery li:after,
.gallery li:after, #site-header .search-block button:before,
#content-sidebar .search-block button:before, .cart-block .footer a:before, .cart-block .footer a:after, #site-header .cart-block .header .cart-icon:before, #content-sidebar .custom-menu-block .toggle-child-menu:before,
#site-footer .custom-menu-block .toggle-child-menu:before, .sidr .toggle-child-menu:before, .breadcrumbs a:before, .breadcrumbs .current:before, #menu:before, .field-wrap.select-type .control:after, .remove-item-action:before,
.remove-item:before {
  font-family: "swt";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swt-info:before {
  content: "\e602";
}

.swt-truck:before {
  content: "\e601";
}

.swt-sort:before {
  content: "\e600";
}

.swt-angle-right:before {
  content: "\f105";
}

.swt-select:before {
  content: "\f0dc";
}

.swt-star:before {
  content: "\f005";
}

.swt-angle-left:before {
  content: "\f104";
}

.swt-nav:before {
  content: "\e610";
}

.swt-cart:before {
  content: "\e611";
}

.swt-close:before {
  content: "\e61d";
}

.swt-plus:before {
  content: "\e616";
}

.swt-minus:before {
  content: "\e60e";
}

.swt-grid:before {
  content: "\e61a";
}

.swt-grid2:before {
  content: "\e61b";
}

.swt-list:before {
  content: "\e61c";
}

.swt-search:before {
  content: "\e609";
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Shared Properties
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-filter .sort-order-items:before, .has-sidebar .filter-groups:before, .has-active-filter-items .active-filters:before, .has-product-filter:before, .rating-selector .option:before, .rating-selector .control:before, .add-review-comment .login-menu:before, #product-reviews .product-rating:before, .social-sharing:before, nav.customer-pages ul:before, .order-document-footer:before, .customer-content .columns:before, #checkout-page.theme-checkout-layout-1 form:before, #checkout-page.theme-checkout-layout-2 form:before, .summary p:before, .discount-code-db-cart-wrap:before, .sort-order:before, .gallery-layout:before, .pagination:before, .gallery-header:before, .product-details:before, .product-variants:before, html#product-page .product-price:before, html#product-page .product-offer:before, .product-nav:before, .product-block:before, .product-long-description:before,
.text-block .column:before, .column-content:before, #site-footer .gallery:before,
.gallery:before, #site-header .cart-block .header:before, #site-footer nav ul:before, #site-header nav.custom ul:before,
#site-header .category-tree-menu ul:before, .layout:before, .two.group-items:before, #content:before, .product-filter .sort-order-items:after, .has-sidebar .filter-groups:after, .has-active-filter-items .active-filters:after, .has-product-filter:after, .rating-selector .option:after, .rating-selector .control:after, .add-review-comment .login-menu:after, #product-reviews .product-rating:after, .social-sharing:after, nav.customer-pages ul:after, .order-document-footer:after, .customer-content .columns:after, #checkout-page.theme-checkout-layout-1 form:after, #checkout-page.theme-checkout-layout-2 form:after, .summary p:after, .discount-code-db-cart-wrap:after, .sort-order:after, .gallery-layout:after, .pagination:after, .gallery-header:after, .product-details:after, .product-variants:after, html#product-page .product-price:after, html#product-page .product-offer:after, .product-nav:after, .product-block:after, .product-long-description:after,
.text-block .column:after, .column-content:after, #site-footer .gallery:after,
.gallery:after, #site-header .cart-block .header:after, #site-footer nav ul:after, #site-header nav.custom ul:after,
#site-header .category-tree-menu ul:after, .layout:after, .two.group-items:after, #content:after {
  content: "";
  display: table;
}
.product-filter .sort-order-items:after, .has-sidebar .filter-groups:after, .has-active-filter-items .active-filters:after, .has-product-filter:after, .rating-selector .option:after, .rating-selector .control:after, .add-review-comment .login-menu:after, #product-reviews .product-rating:after, .social-sharing:after, nav.customer-pages ul:after, .order-document-footer:after, .customer-content .columns:after, #checkout-page.theme-checkout-layout-1 form:after, #checkout-page.theme-checkout-layout-2 form:after, .summary p:after, .discount-code-db-cart-wrap:after, .sort-order:after, .gallery-layout:after, .pagination:after, .gallery-header:after, .product-details:after, .product-variants:after, html#product-page .product-price:after, html#product-page .product-offer:after, .product-nav:after, .product-block:after, .product-long-description:after,
.text-block .column:after, .column-content:after, #site-footer .gallery:after,
.gallery:after, #site-header .cart-block .header:after, #site-footer nav ul:after, #site-header nav.custom ul:after,
#site-header .category-tree-menu ul:after, .layout:after, .two.group-items:after, #content:after {
  clear: both;
}

.review-ratings {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}

.product-details dd.more-info-url {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * LAYOUT
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#page-container {
  overflow: hidden;
}

#content,
#site-header .wrapper,
#site-footer .wrapper {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 10px;
}

#content-sidebar {
  width: 22%;
  float: left;
}
#content-sidebar h2 {
  margin: 0;
}

html.has-sidebar #main-content {
  width: 75%;
  float: right;
}
html.has-sidebar#checkout-page #main-content, html.has-sidebar#product-page #main-content {
  width: auto;
  float: none;
}
html.has-sidebar#checkout-page #content-sidebar, html.has-sidebar#product-page #content-sidebar {
  display: none;
}
@media only screen and (max-width: 767px) {
  html.has-sidebar #main-content,
  html.has-sidebar #content-sidebar {
    width: auto;
    float: none;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * ION RANGE SLIDER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 11%;
}

.irs-line-mid {
  left: 9%;
  width: 82%;
}

.irs-line-right {
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}
.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}
.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs-slider {
  background: url(../../lib/ion-range-slider/img/sprite-skin-modern.png) repeat-x;
}

.irs {
  height: 50px;
}

.irs-with-grid {
  height: 70px;
}

.irs-line {
  height: 6px;
  top: 25px;
  background-color: #D1D6E0;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

.irs-line-left {
  height: 6px;
  background-position: 0 -30px;
}

.irs-line-mid {
  height: 6px;
  background-position: 0 0;
}

.irs-line-right {
  height: 6px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 6px;
  top: 25px;
  background-image: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
}

.irs-bar-edge {
  top: 25px;
  height: 6px;
  width: 6px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 5px;
  top: 25px;
  background: #000;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 11px;
  height: 18px;
  top: 31px;
  background-position: 0 -120px;
}
.irs-slider.state_hover, .irs-slider:hover {
  background-position: 0 -150px;
}

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  border-radius: 4px;
}

.irs-bar, .irs-from, .irs-to, .irs-single {
  background-color: #0082c5;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #0082c5;
}

.irs-grid {
  height: 34px;
}

.irs-grid-pol {
  background: #c0c0c0;
}

.irs-grid-text {
  bottom: 12px;
  color: #c0c0c0;
}

html {
  background-color: rgb(247, 247, 247);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * TYPOGRAPHY
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
html, body, input, textarea, select, button {
  font: 16px/160% "Open Sans", sans-serif;
  color: rgb(30, 30, 30);
  font-weight: 300;
}

h1, h2, h3, h4 {
  font-weight: 300;
  line-height: 1.4em;
}

h1 {
  margin: 0;
  margin-top: -0.7rem;
}

h2, h3, h4 {
  margin: 1em 0 0;
}

h1 {
  font-size: 33.6px;
  font-size: 2.1rem;
  line-height: 1.2em;
}

h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

h3 {
  font-size: 20.8px;
  font-size: 1.3rem;
}

h4 {
  font-size: 16px;
  font-size: 1rem;
}

abbr[title] {
  border-style: none;
  text-decoration: none;
}

.required-asterisk {
  color: #bf0505;
}

p {
  margin: 0.6em 0 0 0;
}

a {
  font-weight: 300;
  text-decoration: none;
  color: #1764a7;
}
a:visited {
  color: #1764a7;
}
a:hover {
  color: #1764a7;
  text-decoration: underline;
}
a:active {
  color: #1764a7;
}

b {
  font-weight: 600;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FORMS
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
form {
  margin: 20px 0;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  text-indent: 0.3em;
  color: rgb(180, 180, 180);
  font-weight: 300;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  text-indent: 0.3em;
  color: rgb(180, 180, 180);
  font-weight: 300;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  text-indent: 0.3em;
  color: rgb(180, 180, 180);
  font-weight: 300;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  text-indent: 0.3em;
  color: rgb(180, 180, 180);
  font-weight: 300;
}

fieldset {
  padding: 0;
}

.field-wrap {
  margin-bottom: 10px;
}
.field-wrap p {
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}
.field-wrap.type-submit label, .field-wrap.button-type label, .field-wrap.client-type-corporate {
  display: none;
}

fieldset {
  border-style: none;
}

label, .label {
  cursor: pointer;
  font-size: 14.4px;
  font-size: 0.9rem;
}
label.error, .label.error {
  background-color: #e23535;
  padding: 2px 6px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  font-size: 11.2px;
  font-size: 0.7rem;
  font-weight: 600;
}
label.error:before, .label.error:before {
  content: "↑";
  padding-right: 3px;
}
label.generic:before, .label.generic:before {
  display: none;
}

.error[for=terms-checkbox] {
  display: block !important;
  line-height: 1.4em;
  padding: 6px;
}

.type-select .control {
  margin-top: 0.1em;
}

input[type=text], input[type=password], input[type=email], input[type=number], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=file] {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  cursor: text;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  display: block;
  padding: 4px 3px 4px 6px;
  border: 1px solid silver;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  text-indent: 0;
  line-height: 22px;
}
input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=file]:focus {
  border-color: rgba(123, 154, 178, 0.8) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset, 0px 0px 8px rgba(123, 154, 178, 0.6) !important;
}
input[type=text].error, input[type=text].error:focus, input[type=password].error, input[type=password].error:focus, input[type=email].error, input[type=email].error:focus, input[type=number].error, input[type=number].error:focus, input[type=search].error, input[type=search].error:focus, input[type=tel].error, input[type=tel].error:focus, input[type=time].error, input[type=time].error:focus, input[type=url].error, input[type=url].error:focus, input[type=file].error, input[type=file].error:focus {
  border-color: rgba(226, 53, 53, 0.8);
  box-shadow: 0px 1px 2px rgba(226, 53, 53, 0.3) inset, 0px 0px 8px rgba(226, 53, 53, 0.3);
}
input[type=text][disabled=disabled], input[type=text][disabled], input[type=password][disabled=disabled], input[type=password][disabled], input[type=email][disabled=disabled], input[type=email][disabled], input[type=number][disabled=disabled], input[type=number][disabled], input[type=search][disabled=disabled], input[type=search][disabled], input[type=tel][disabled=disabled], input[type=tel][disabled], input[type=time][disabled=disabled], input[type=time][disabled], input[type=url][disabled=disabled], input[type=url][disabled], input[type=file][disabled=disabled], input[type=file][disabled] {
  background-color: rgb(220, 220, 220);
}

textarea {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  cursor: text;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  display: block;
  padding: 4px 3px 4px 6px;
  border: 1px solid silver;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  text-indent: 0;
  line-height: 22px;
  min-height: 4em;
  font-size: 16px;
  font-size: 1rem;
  resize: vertical;
}
textarea:focus {
  border-color: rgba(123, 154, 178, 0.8) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset, 0px 0px 8px rgba(123, 154, 178, 0.6) !important;
}
textarea.error, textarea.error:focus {
  border-color: rgba(226, 53, 53, 0.8);
  box-shadow: 0px 1px 2px rgba(226, 53, 53, 0.3) inset, 0px 0px 8px rgba(226, 53, 53, 0.3);
}
textarea[disabled=disabled], textarea[disabled] {
  background-color: rgb(220, 220, 220);
}

.button,
button,
input[type=button],
input[type=submit],
.cart-content-close,
.checkout-link {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  cursor: pointer;
  background-color: #000;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  line-height: 16px;
  padding: 12px 1em 11px;
  font-weight: 600;
}
.button:hover,
button:hover,
input[type=button]:hover,
input[type=submit]:hover,
.cart-content-close:hover,
.checkout-link:hover {
  background-color: #12588e;
  text-decoration: none;
}
.button[disabled=disabled]:hover, .button[disabled]:hover,
button[disabled=disabled]:hover,
button[disabled]:hover,
input[type=button][disabled=disabled]:hover,
input[type=button][disabled]:hover,
input[type=submit][disabled=disabled]:hover,
input[type=submit][disabled]:hover,
.cart-content-close[disabled=disabled]:hover,
.cart-content-close[disabled]:hover,
.checkout-link[disabled=disabled]:hover,
.checkout-link[disabled]:hover {
  background-color: #cccccc;
}
.button:visited,
button:visited,
input[type=button]:visited,
input[type=submit]:visited,
.cart-content-close:visited,
.checkout-link:visited {
  color: white;
}
.button[disabled=disabled], .button[disabled],
button[disabled=disabled],
button[disabled],
input[type=button][disabled=disabled],
input[type=button][disabled],
input[type=submit][disabled=disabled],
input[type=submit][disabled],
.cart-content-close[disabled=disabled],
.cart-content-close[disabled],
.checkout-link[disabled=disabled],
.checkout-link[disabled] {
  background-color: #cccccc;
  opacity: 0.5;
}
.button:focus,
button:focus,
input[type=button]:focus,
input[type=submit]:focus,
.cart-content-close:focus,
.checkout-link:focus {
  background-color: #12588e;
}
.button:active,
button:active,
input[type=button]:active,
input[type=submit]:active,
.cart-content-close:active,
.checkout-link:active {
  background-color: #0c3c61;
}

.copy-order-to-cart-action {
  width: auto;
}

.group-item button,
.group-item .button {
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.button-secondary {
  background-color: transparent;
  border: 1px dotted #B7B6B6;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 16px;
  font-size: 1rem;
}

.remove-item-action,
.remove-item {
  background-color: transparent !important;
  position: absolute;
  top: 1px;
  right: 10px;
  display: block;
  width: 26px;
  height: 26px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}
.remove-item-action span,
.remove-item span {
  display: none;
}
.remove-item-action:before,
.remove-item:before {
  content: "\e61d";
  color: rgb(150, 150, 150);
}
.remove-item-action:hover,
.remove-item:hover {
  text-decoration: none;
  background-color: transparent;
}
.remove-item-action:hover:before,
.remove-item:hover:before {
  color: rgb(50, 50, 50);
}

button.loading:before,
button.loading:hover:before {
  visibility: hidden;
}

button.loading span {
  visibility: hidden;
}
button.loading:after {
  content: "";
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
button.loading:hover {
  background-color: #000 !important;
}

input[type=submit],
button[type=submit] {
  font-weight: 600;
}

select {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  text-indent: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 0px;
  -moz-padding-end: 0px;
  -webkit-padding-start: 0px;
  -moz-padding-start: 0px;
  user-select: none;
  text-indent: 0.4em;
  text-overflow: "";
  cursor: pointer;
  padding: 3px 3px 3px 6px;
  -webkit-padding-end: 22px;
  border: 1px solid silver;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  text-indent: 0;
  background-color: white;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select:focus {
  border-color: rgba(123, 154, 178, 0.8);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset, 0px 0px 8px rgba(123, 154, 178, 0.6);
}
select[disabled=disabled], select[disabled] {
  background-color: rgb(220, 220, 220);
  color: rgb(180, 180, 180);
  cursor: default;
}

.field-wrap.select-type .control {
  position: relative;
}
.field-wrap.select-type .control:after {
  position: absolute;
  content: "\f0dc";
  line-height: 30px;
  right: 1px;
  top: 3px;
  width: 32px;
  height: 32px;
  text-align: center;
  color: silver;
  font-size: 16px;
  font-size: 1rem;
  transition: color linear 0.2s;
  pointer-events: none;
}
.field-wrap.select-type .control:hover:after {
  color: rgb(30, 30, 30);
  cursor: pointer;
}
.field-wrap.select-type.disabled .control:hover:after {
  color: silver;
}

.field-wrap.radio-type.disabled label, .field-wrap.checkbox-type.disabled label {
  color: rgb(180, 180, 180);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Header
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#menu {
  display: none;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  #menu {
    display: block;
  }
}
#menu:before {
  content: "\e610";
  text-align: center;
  width: 30px;
  display: block;
  font-size: 30px;
  color: rgb(220, 220, 220);
}
#menu:hover {
  text-decoration: none;
}
#menu:hover:before {
  color: white !important;
}

#site-header {
  position: relative;
  margin-bottom: 40px;
}
#site-header > .wrapper > .content {
  padding-top: 70px;
}
#site-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: black;
}
#site-header .content {
  position: relative;
}

#home, #home:visited {
  display: block;
  margin: 30px auto 0 auto;
  text-align: center;
  text-decoration: none;
  color: rgb(30, 30, 30);
}
#home:hover, #home:visited:hover {
  color: #1764a7;
}
#home .shop-title, #home:visited .shop-title {
  font-size: 2.1rem;
}

@media only screen and (max-width: 768px) {
  .has-sidebar-menu #site-header nav.menu {
    padding-top: 180px;
  }
  .has-sidebar-menu #site-header #home {
    position: absolute;
    top: 60px;
    width: 100%;
  }
}
.mobile #menu {
  display: block;
}
.mobile #site-header nav {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Sidebar
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#content-sidebar nav ul,
#site-header nav ul,
#site-footer nav ul {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
#content-sidebar nav ul > li,
#site-header nav ul > li,
#site-footer nav ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Footer
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-footer {
  padding: 40px 0 60px;
  background-color: rgb(60, 60, 60);
  color: white;
  margin-top: 40px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5) inset;
  font-size: 14.4px;
  font-size: 0.9rem;
  line-height: 1.4em;
}
#site-footer h1, #site-footer h2, #site-footer h3, #site-footer a {
  color: white !important;
}
#site-footer a:visited {
  color: white !important;
}
#site-footer a {
  text-decoration: underline;
}
#site-footer:after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
  content: "";
  width: 4px;
  height: 4px;
  border: 10px solid;
  border-color: rgb(247, 247, 247) transparent transparent transparent;
}
#site-footer .newsletter-signup-block button,
#site-footer .newsletter-signup-block input[type=button],
#site-footer .newsletter-signup-block input[type=submit],
#site-footer .search-block button,
#site-footer .search-block input[type=button],
#site-footer .search-block input[type=submit] {
  background-color: #000 !important;
}
#site-footer .newsletter-signup-block button:hover, #site-footer .newsletter-signup-block button:focus,
#site-footer .newsletter-signup-block input[type=button]:hover,
#site-footer .newsletter-signup-block input[type=button]:focus,
#site-footer .newsletter-signup-block input[type=submit]:hover,
#site-footer .newsletter-signup-block input[type=submit]:focus,
#site-footer .search-block button:hover,
#site-footer .search-block button:focus,
#site-footer .search-block input[type=button]:hover,
#site-footer .search-block input[type=button]:focus,
#site-footer .search-block input[type=submit]:hover,
#site-footer .search-block input[type=submit]:focus {
  background-color: #262626 !important;
}
#site-footer .cart-block .footer a:hover {
  background-color: #262626 !important;
}
#site-footer .vat-selector a {
  color: white;
  text-decoration: underline;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Mobile navigation
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#mobile-nav * {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Price
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-price,
.unit-price,
.price,
.quantity,
.weight {
  font-size: 32px;
  font-size: 2rem;
}
.product-price .currency,
.product-price .unit,
.unit-price .currency,
.unit-price .unit,
.price .currency,
.price .unit,
.quantity .currency,
.quantity .unit,
.weight .currency,
.weight .unit {
  font-size: 0.5em;
  text-transform: uppercase;
  padding-left: 0.15em;
}
.product-price .unit,
.unit-price .unit,
.price .unit,
.quantity .unit,
.weight .unit {
  padding-left: 0;
}

.original-price,
.selling-price {
  line-height: 1em;
  line-height: 1rem;
}

.original-price {
  margin-top: 0.7em;
}

.original-price {
  font-size: 16px;
  font-size: 1rem;
}
.original-price .price {
  font-size: 16px;
  font-size: 1rem;
}
.original-price .price:before, .original-price .price:after {
  font-size: 16px;
  font-size: 1rem;
}
.original-price .price .amount {
  text-decoration: line-through;
}

.unit-price .unit,
.product-price .unit {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Customer info form
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.customer-info-form-partial label[for=existing-account-type-radio] span:before {
  content: "(";
}
.customer-info-form-partial label[for=existing-account-type-radio] span:after {
  content: ")";
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Documents
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.product-documents {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
  font-size: 14.4px;
  font-size: 0.9rem;
  margin: 10px 0 20px;
}
.product-documents > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.product-documents li {
  padding-left: 28px;
  line-height: 1.3em;
  margin-bottom: 0.6em;
}
.product-documents a, .product-documents a:hover {
  text-decoration: none;
  position: relative;
}
.product-documents a:hover .file-title {
  text-decoration: underline;
}
.product-documents .file-size {
  color: rgb(30, 30, 30);
  opacity: 0.5;
  padding: 0px 3px;
  white-space: nowrap;
}
.product-documents .file-type .file-ext {
  display: none;
}
.product-documents .file-type a:before {
  content: attr(data-file-type);
  position: absolute;
  top: 0;
  left: -28px;
  background-color: black;
  opacity: 0.3;
  color: white;
  font-size: 9.6px;
  font-size: 0.6rem;
  padding: 0px 2px;
  text-transform: uppercase;
}
.product-documents .file-type a:hover:before {
  opacity: 0.6;
}

.order-documents {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
  font-size: 14.4px;
  font-size: 0.9rem;
  margin: 1em 0 20px;
}
.order-documents > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.order-documents li {
  line-height: 1.4em;
}
.order-documents [data-file-type] {
  position: relative;
}
.order-documents [data-file-type] .file-ext {
  display: none;
}
.order-documents [data-file-type] .file-size {
  color: rgb(30, 30, 30);
  opacity: 0.5;
  padding: 0px 3px;
  white-space: nowrap;
}
.order-documents [data-file-type]:hover {
  text-decoration: none;
}
.order-documents [data-file-type]:hover .file-title {
  text-decoration: underline;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Groups
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.two.group-items {
  margin-left: -2%;
}
@media only screen and (max-width: 767px) {
  .two.group-items {
    margin-left: 0;
  }
}
.two.group-items .group-item {
  float: left;
  margin-left: 2%;
  width: 48%;
}
@media only screen and (max-width: 767px) {
  .two.group-items .group-item {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    float: none;
    min-height: 0;
    position: static;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Breadcrumbs
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.breadcrumbs {
  display: none;
}

#product-list-page .breadcrumbs,
#product-page .breadcrumbs {
  display: block;
}

.breadcrumbs {
  margin: -8px 0 10px;
}
.breadcrumbs a, .breadcrumbs .current {
  color: inherit;
  display: inline-block;
  padding: 0px 6px 0 10px;
  text-decoration: none;
  font-size: 12.8px;
  font-size: 0.8rem;
  position: relative;
  line-height: 26px;
  color: #777777;
  margin-left: 5px;
}
.breadcrumbs a:before, .breadcrumbs .current:before {
  content: "\f105";
  position: absolute;
  left: -4px;
  top: 6px;
  color: #777777;
}
.breadcrumbs span.current,
.breadcrumbs a.current {
  color: rgb(30, 30, 30);
  font-weight: 400;
}
.breadcrumbs a span {
  border-bottom: 1px dotted silver;
}
.breadcrumbs a:hover {
  color: rgb(30, 30, 30);
}
.breadcrumbs a:hover span {
  border-bottom-color: rgb(30, 30, 30);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Override admin css
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
body {
  padding: 0;
}

label {
  display: inline;
}

input,
select {
  background-image: none;
}

input[disabled=disabled], input[disabled],
select[disabled=disabled],
select[disabled] {
  filter: alpha(opacity=100);
  opacity: 1;
}

select {
  min-height: 0;
}

.field-wrap.error input,
input.error-field,
textarea.error-field {
  background-color: white !important;
  border-color: silver !important;
}
.field-wrap.error input::-webkit-input-placeholder,
input.error-field::-webkit-input-placeholder,
textarea.error-field::-webkit-input-placeholder {
  color: rgb(180, 180, 180);
}
.field-wrap.error input:-moz-placeholder,
input.error-field:-moz-placeholder,
textarea.error-field:-moz-placeholder {
  color: rgb(180, 180, 180);
}
.field-wrap.error input::-moz-placeholder,
input.error-field::-moz-placeholder,
textarea.error-field::-moz-placeholder {
  color: rgb(180, 180, 180);
}
.field-wrap.error input:-ms-input-placeholder,
input.error-field:-ms-input-placeholder,
textarea.error-field:-ms-input-placeholder {
  color: rgb(180, 180, 180);
}
.field-wrap.error input[disabled=disabled], .field-wrap.error input[disabled],
input.error-field[disabled=disabled],
input.error-field[disabled],
textarea.error-field[disabled=disabled],
textarea.error-field[disabled] {
  background-color: rgb(220, 220, 220) !important;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Sidr
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.toggle-child-menu {
  display: none;
}

#sidr-main #sidr-id-cart {
  display: none;
}

.sidr ul li {
  display: block;
  margin: 0;
  padding: 0;
}
.sidr ul li a {
  text-transform: uppercase;
  padding: 0 0 0 20px;
  margin: 0;
  font-size: 14.4px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.sidr ul li ul li a {
  text-transform: none;
  font-size: 12.8px;
  font-size: 0.8rem;
  padding-left: 20px;
}
.sidr ul li ul li ul li a {
  font-size: 11.2px;
  font-size: 0.7rem;
  padding-left: 30px;
}
.sidr ul li ul li ul li ul li a {
  font-size: 11.2px;
  font-size: 0.7rem;
  padding-left: 45px;
  font-weight: 300;
}
.sidr ul a {
  cursor: pointer;
  padding-left: 21px;
  color: white;
}
.sidr ul a:before {
  content: "•";
  padding-right: 2px;
}
.sidr ul a.load-tree {
  color: white;
  padding-left: 20px;
}
.sidr ul a.load-tree:hover {
  text-decoration: none;
}
.sidr ul a.load-tree:before {
  content: "\e616";
  content: "+";
  padding-right: 0;
}
.sidr ul a.load-tree.open:before {
  content: "\e60e";
  content: "-";
}
.sidr ul a.selected {
  font-weight: 600;
}
.sidr ul a.selected:before {
  font-weight: 300;
}
.sidr ul li ul li a, .sidr ul li ul li a.load-tree {
  padding-left: 28px;
}
.sidr ul li ul li ul li a, .sidr ul li ul li ul li a.load-tree {
  padding-left: 34px;
}

.sidr {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0);
  z-index: 0 !important;
  overflow: scroll !important;
}
.sidr nav {
  margin-bottom: 0;
}
.sidr ul {
  margin-bottom: 30px;
}
.sidr ul li {
  position: relative;
}
.sidr ul li a:hover {
  background-color: transparent;
}
.sidr ul li a:active {
  box-shadow: none;
}
.sidr .toggle-child-menu {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 40px;
  text-align: center;
  display: block;
  padding: 0 15px;
  line-height: 44px;
  font-size: 28px;
  top: 0;
  right: 0;
  padding: 0 15px;
  display: none;
}
.sidr .toggle-child-menu:before {
  content: "\e900";
}
.sidr .menu-item-show-children > .toggle-child-menu:before {
  content: "\e901";
}
.sidr .dynamic-menu-item.has-children ul {
  display: none;
}
.sidr .custom-menu-block .toggle-child-menu,
.sidr .category-tree-menu .toggle-child-menu {
  display: block;
}

#site-header nav.category ul li .toggle-category-child-menu,
#content-sidebar nav ul li ul li .toggle-category-child-menu,
#content-sidebar nav ul li .toggle-category-child-menu,
.category-menu-block .toggle-category-child-menu {
  display: none;
}

.category-tree-menu .toggle-category-child-menu {
  display: block;
}

.mobile .sidr {
  height: 90%;
}

.desktop .no-link:hover {
  cursor: default;
}

.off-canvas-nav .menu-item-has-children > .menu {
  display: none;
}
.off-canvas-nav .menu-item-has-children.menu-item-show-children > .menu {
  display: block;
}

.sidebar .category-menu-block .menu-item-has-children > .menu {
  display: none;
}
.sidebar .category-menu-block .menu-item-has-children.current-menu-ancestor > .menu {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Product images
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.gallery figure,
.product-media figure {
  position: relative;
  text-align: center;
}
.gallery figure:before,
.product-media figure:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.gallery figure .figure-content,
.product-media figure .figure-content {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.gallery figure .img-wrap,
.product-media figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
  transform: translate(50%, 50%) translateZ(0);
}
.gallery figure img,
.product-media figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Contents layout
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.layout-1 .first.layout-content,
.layout-2 .last.layout-content {
  width: 38%;
}

.layout-1 .last.layout-content,
.layout-2 .first.layout-content {
  width: 58%;
}

.layout-3 .layout-content {
  width: 48%;
}

.layout-4 .layout-content {
  width: 31.3333333333%;
}

.layout {
  margin-left: -2%;
}
.layout .layout-content {
  float: left;
  margin-left: 2%;
}

@media only screen and (max-width: 767px) {
  .layout {
    margin-left: 0 !important;
  }
  .layout .layout-content {
    float: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Lightbox
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.ilightbox-holder div.ilightbox-container,
.ilightbox-thumbnails img {
  background-color: white;
}

.ilightbox-button.mac {
  display: block !important;
}

.isMobile .ilightbox-button.mac {
  display: none !important;
}

.ilightbox-toolbar.mac,
.isMobile .ilightbox-toolbar.mac,
.ilightbox-button.mac,
.ilightbox-button.ilightbox-button.mac:hover,
.ilightbox-button.ilightbox-next-button.mac.horizontal,
.ilightbox-button.ilightbox-prev-button.mac.horizontal {
  background-image: none;
  border-style: none;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Fotorama
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.slideshow-media .fotorama__html {
  position: relative;
}
.slideshow-media .fotorama__html .caption {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}
.slideshow-media .fotorama__html .caption h3, .slideshow-media .fotorama__html .caption p {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  color: white;
}
.slideshow-media .fotorama__html .caption h3 {
  font-size: 24px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 767px) {
  .slideshow-media .fotorama__html .caption h3 {
    font-size: 20.8px;
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 479px) {
  .slideshow-media .fotorama__html .caption h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}
.slideshow-media .fotorama__html .caption-empty {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Highlight
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@-webkit-keyframes yellow-fade {
  0% {
    background: #EBBF3C;
  }
  100% {
    background: none;
  }
}
@keyframes yellow-fade {
  0% {
    background: #EBBF3C;
  }
  100% {
    background: none;
  }
}
.highlight {
  -webkit-animation: yellow-fade 2s ease-in 1;
  animation: yellow-fade 2s ease-in 1;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Specific languages
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
[lang=fi] .gallery li.save-percent .save-percent .label {
  font-size: 9px;
}
[lang=fi] .product-media.save-percent .save-percent .label {
  font-size: 11px;
}

[lang=de] .gallery li.save-percent .save-percent .label {
  font-size: 8px;
}
[lang=de] .product-media.save-percent .save-percent .label {
  font-size: 11px;
}

[lang=fr] .gallery li.save-percent .save-percent .label {
  font-size: 6px;
}
[lang=fr] .product-media.save-percent .save-percent .label {
  font-size: 9px;
}

@media only screen and (max-width: 479px) {
  [lang=fi] .gallery li.save-percent .save-percent .label {
    font-size: 7px;
  }
  [lang=de] .gallery li.save-percent .save-percent .label {
    font-size: 6px;
  }
  [lang=fr] .gallery li.save-percent .save-percent .label {
    font-size: 4px;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * instafeed Instagram v2 module
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.instagram-module {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  gap: 28px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.instagram-module a.instagram-link,
.instagram-module a.instagram-link:hover {
  text-decoration: none;
  color: initial;
}
.instagram-module a.instagram-link figure.instagram-figure,
.instagram-module a.instagram-link:hover figure.instagram-figure {
  position: relative;
}
.instagram-module a.instagram-link img.instagram-img,
.instagram-module a.instagram-link:hover img.instagram-img {
  height: 300px;
  width: 300px;
}
.instagram-module a.instagram-link .instagram-album.instagram-figure:before,
.instagram-module a.instagram-link:hover .instagram-album.instagram-figure:before {
  content: "\e413";
  font-family: "Material Icons";
  color: #FFF;
  position: absolute;
  font-size: 32px;
  top: 8px;
  right: 8px;
}
.instagram-module a.instagram-link .instagram-video.instagram-figure:before,
.instagram-module a.instagram-link:hover .instagram-video.instagram-figure:before {
  content: "\e037";
  font-family: "Material Icons";
  color: #FFF;
  position: absolute;
  font-size: 40px;
  top: 8px;
  right: 4px;
}
.instagram-module a.instagram-link figcaption.instagram-caption,
.instagram-module a.instagram-link:hover figcaption.instagram-caption {
  display: none;
}
@media only screen and (max-width: 650px) {
  .instagram-module a.instagram-link figcaption.instagram-caption,
  .instagram-module a.instagram-link:hover figcaption.instagram-caption {
    margin-top: -4px;
    text-align: left;
    font-weight: 600;
    text-decoration: none;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CONTENT BLOCKS - generic
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.newsletter-signup-block,
.search-block,
.vat-selector-block,
.currency-block,
.language-block,
.social-like-block,
nav {
  margin-bottom: 20px;
}
.newsletter-signup-block h3,
.search-block h3,
.vat-selector-block h3,
.currency-block h3,
.language-block h3,
.social-like-block h3,
nav h3 {
  margin: 0 0 7px;
}
.newsletter-signup-block form,
.search-block form,
.vat-selector-block form,
.currency-block form,
.language-block form,
.social-like-block form,
nav form {
  margin: 0;
}

.product-block,
.product-list-block,
.search-results-block,
.category-list-block,
.faq-block {
  margin-bottom: 20px;
}

.product-list-block h2,
.category-list-block h2,
.faq-block h2 {
  margin-top: -6px;
}

.newsletter-signup-block label,
.search-block label,
.currency-block label,
.language-block label {
  display: none;
}

.customer-form-block label.caption {
  display: none;
}

#site-header .vat-selector-block,
#site-header .currency-block,
#site-header .text-block {
  color: rgb(30, 30, 30);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CONTENT BLOCKS - In header / footer
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-header .newsletter-signup-block,
#site-header .currency-block,
#site-header .language-block,
#site-header .vat-selector-block {
  max-width: 44%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  #site-header .newsletter-signup-block,
  #site-header .currency-block,
  #site-header .language-block,
  #site-header .vat-selector-block {
    max-width: none;
  }
}

#site-footer .newsletter-signup-block,
#site-footer .search-block,
#site-footer .currency-block,
#site-footer .cart-block,
#site-footer .language-block,
#site-footer .vat-selector-block {
  max-width: 44%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  #site-footer .newsletter-signup-block,
  #site-footer .search-block,
  #site-footer .currency-block,
  #site-footer .cart-block,
  #site-footer .language-block,
  #site-footer .vat-selector-block {
    max-width: none;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * MENU - In header
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-header nav.custom,
#site-header .category-tree-menu {
  width: 80%;
  position: absolute;
  top: 0;
  left: 10px;
}
#site-header nav.custom > ul > li > a,
#site-header .category-tree-menu > ul > li > a {
  position: relative;
}
#site-header nav.custom > ul > li > a:after,
#site-header .category-tree-menu > ul > li > a:after {
  content: "▾";
  font-size: 12px;
  padding-left: 5px;
}
#site-header nav.custom > ul > li > a:not(.has-children):only-child:after,
#site-header .category-tree-menu > ul > li > a:not(.has-children):only-child:after {
  content: "";
  display: none;
}
#site-header nav.custom > ul > li > ul,
#site-header .category-tree-menu > ul > li > ul {
  visibility: hidden;
  padding: 20px 0px 20px;
  transition: all 0.3s ease 0.15s;
}
#site-header nav.custom > ul > li > ul:before,
#site-header .category-tree-menu > ul > li > ul:before {
  display: block !important;
  content: "";
  position: absolute;
  left: -3000px;
  right: -3000px;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 9998;
  border-bottom: 1px solid #DFDFDF;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
#site-header nav.custom > ul > li:hover > ul,
#site-header .category-tree-menu > ul > li:hover > ul {
  visibility: visible;
}
#site-header nav.custom ul,
#site-header .category-tree-menu ul {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
#site-header nav.custom ul > li,
#site-header .category-tree-menu ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
#site-header nav.custom ul li,
#site-header .category-tree-menu ul li {
  float: left;
}
#site-header nav.custom ul li a,
#site-header .category-tree-menu ul li a {
  display: block;
  float: left;
  padding: 0;
  margin-left: 20px;
  text-decoration: none;
  color: rgb(30, 30, 30);
  color: rgb(220, 220, 220);
  line-height: 52px;
}
#site-header nav.custom ul li a:hover,
#site-header .category-tree-menu ul li a:hover {
  color: white;
}
#site-header nav.custom ul li:first-child a,
#site-header .category-tree-menu ul li:first-child a {
  margin-left: 0;
}
#site-header nav.custom ul li ul,
#site-header .category-tree-menu ul li ul {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  margin-left: -30px;
  background-color: white;
  z-index: 9997;
}
#site-header nav.custom ul li ul li,
#site-header .category-tree-menu ul li ul li {
  float: left;
  margin: 0 0 20px 30px;
  position: relative;
  z-index: 9999;
}
#site-header nav.custom ul li ul li a,
#site-header .category-tree-menu ul li ul li a {
  display: block;
  float: none;
  padding: 0 0 0.4em;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  margin-left: 0px;
  font-size: 14.4px;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(50, 50, 50) !important;
}
#site-header nav.custom ul li ul li a:hover,
#site-header .category-tree-menu ul li ul li a:hover {
  color: #1764a7 !important;
}
#site-header nav.custom ul li ul li ul,
#site-header .category-tree-menu ul li ul li ul {
  padding: 0;
  background-color: transparent;
  position: static;
  left: auto;
  top: auto;
  margin: 0.2em 0 0;
}
#site-header nav.custom ul li ul li ul li,
#site-header .category-tree-menu ul li ul li ul li {
  display: block;
  float: none;
  margin-bottom: 0;
  margin-left: 0;
}
#site-header nav.custom ul li ul li ul li a,
#site-header .category-tree-menu ul li ul li ul li a {
  float: none;
  text-transform: none;
  font-size: 14.4px;
  font-size: 0.9rem;
  font-weight: 300;
  padding: 0.3em 0;
}
#site-header nav.custom ul.open li:first-child,
#site-header .category-tree-menu ul.open li:first-child {
  padding-left: 20px;
}

@media only screen and (max-width: 767px) {
  #site-header nav ul {
    display: none;
  }
  .has-sidebar-menu #site-header nav {
    display: none;
    padding-top: 90px;
  }
  .has-sidebar-menu #site-header nav ul li {
    display: block;
    margin-bottom: 0.2em;
  }
  .has-sidebar-menu #site-header nav ul li a, .has-sidebar-menu #site-header nav ul li span {
    text-transform: uppercase;
    display: block;
    font-size: 14.4px;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(30, 30, 30);
  }
  .has-sidebar-menu #site-header nav ul li a:hover, .has-sidebar-menu #site-header nav ul li span:hover {
    text-decoration: none;
  }
  .has-sidebar-menu #site-header nav ul li a:hover {
    color: #1764a7;
  }
  .has-sidebar-menu #site-header nav ul li a:before {
    content: "•";
    padding-right: 2px;
  }
  .has-sidebar-menu #site-header nav ul li ul li {
    padding-left: 10px;
    margin-bottom: 0;
  }
  .has-sidebar-menu #site-header nav ul li ul li a, .has-sidebar-menu #site-header nav ul li ul li span {
    text-transform: none;
    font-size: 14.4px;
    font-size: 0.9rem;
  }
  .has-sidebar-menu #site-header nav ul li ul li ul li {
    padding-left: 15px;
    position: relative;
  }
  .has-sidebar-menu #site-header nav ul li ul li ul li a, .has-sidebar-menu #site-header nav ul li ul li ul li span {
    font-size: 12.8px;
    font-size: 0.8rem;
  }
  .has-sidebar-menu #site-header nav ul li ul li ul li ul li {
    padding-left: 15px;
  }
  .has-sidebar-menu #site-header nav ul li ul li ul li ul li:before {
    content: "";
    padding-right: 6px;
  }
  .has-sidebar-menu #site-header nav ul li ul li ul li ul li a, .has-sidebar-menu #site-header nav ul li ul li ul li ul li span {
    font-size: 12.8px;
    font-size: 0.8rem;
    font-weight: 300;
  }
  .has-sidebar-menu #site-header nav ul {
    padding: 0;
  }
  .has-sidebar-menu #site-header nav ul li, .has-sidebar-menu #site-header nav ul li a {
    float: none;
    display: block;
    line-height: 1.6em;
  }
  .has-sidebar-menu #site-header nav ul li a, .has-sidebar-menu #site-header nav ul li a a {
    margin-left: 0;
    color: #1764a7;
    text-transform: uppercase;
    font-size: 14.4px;
    font-size: 0.9rem;
    padding: 0;
  }
  .has-sidebar-menu #site-header nav ul li a:hover, .has-sidebar-menu #site-header nav ul li a a:hover {
    text-decoration: underline;
  }
  .has-sidebar-menu #site-header nav ul li a:after, .has-sidebar-menu #site-header nav ul li a a:after {
    display: none;
  }
  .has-sidebar-menu #site-header nav ul li ul, .has-sidebar-menu #site-header nav ul li a ul {
    filter: alpha(opacity=100);
    opacity: 1;
    visibility: visible;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    background-color: transparent;
  }
  .has-sidebar-menu #site-header nav ul li ul:before, .has-sidebar-menu #site-header nav ul li a ul:before {
    display: none !important;
  }
  .has-sidebar-menu #site-header nav ul li ul li a, .has-sidebar-menu #site-header nav ul li a ul li a {
    font-weight: 300;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Category menu
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#content-sidebar nav a,
#site-header nav.category a,
#site-footer nav.category a {
  cursor: pointer;
  padding-left: 1px;
}
#content-sidebar nav a:before,
#site-header nav.category a:before,
#site-footer nav.category a:before {
  content: "•";
  padding-right: 2px;
}
#content-sidebar nav a.load-tree.has-children,
#site-header nav.category a.load-tree.has-children,
#site-footer nav.category a.load-tree.has-children {
  padding-left: 0;
}
#content-sidebar nav a.load-tree.has-children:hover,
#site-header nav.category a.load-tree.has-children:hover,
#site-footer nav.category a.load-tree.has-children:hover {
  text-decoration: none;
}
#content-sidebar nav a.load-tree.has-children:before,
#site-header nav.category a.load-tree.has-children:before,
#site-footer nav.category a.load-tree.has-children:before {
  content: "+";
  padding-right: 0;
}
#content-sidebar nav a.load-tree.has-children.open:before,
#site-header nav.category a.load-tree.has-children.open:before,
#site-footer nav.category a.load-tree.has-children.open:before {
  content: "-";
  padding-right: 3px;
}
#content-sidebar nav a.load-tree.no-children,
#site-header nav.category a.load-tree.no-children,
#site-footer nav.category a.load-tree.no-children {
  padding-left: 0;
}
#content-sidebar nav a.load-tree.no-children:before,
#site-header nav.category a.load-tree.no-children:before,
#site-footer nav.category a.load-tree.no-children:before {
  display: none;
}
#content-sidebar nav a.selected,
#site-header nav.category a.selected,
#site-footer nav.category a.selected {
  font-weight: 600;
}
#content-sidebar nav a.selected:before,
#site-header nav.category a.selected:before,
#site-footer nav.category a.selected:before {
  font-weight: 300;
}
#content-sidebar nav ul ul ul,
#site-header nav.category ul ul ul,
#site-footer nav.category ul ul ul {
  margin-left: 1em;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * MENU - In sidebar & .category in header
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@media only screen and (max-width: 767px) {
  #content-sidebar nav,
  #site-header nav.category {
    display: none;
  }
}
#content-sidebar nav ul li,
#site-header nav.category ul li {
  display: block;
  margin-bottom: 0.2em;
}
#content-sidebar nav ul li a, #content-sidebar nav ul li span,
#site-header nav.category ul li a,
#site-header nav.category ul li span {
  text-transform: uppercase;
  display: block;
  font-size: 14.4px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(30, 30, 30);
}
#content-sidebar nav ul li a:hover, #content-sidebar nav ul li span:hover,
#site-header nav.category ul li a:hover,
#site-header nav.category ul li span:hover {
  text-decoration: none;
}
#content-sidebar nav ul li a:hover,
#site-header nav.category ul li a:hover {
  color: #1764a7;
}
#content-sidebar nav ul li a:before,
#site-header nav.category ul li a:before {
  content: "•";
  padding-right: 2px;
}
#content-sidebar nav ul li ul li,
#site-header nav.category ul li ul li {
  padding-left: 10px;
  margin-bottom: 0;
}
#content-sidebar nav ul li ul li a, #content-sidebar nav ul li ul li span,
#site-header nav.category ul li ul li a,
#site-header nav.category ul li ul li span {
  text-transform: none;
  font-size: 14.4px;
  font-size: 0.9rem;
}
#content-sidebar nav ul li ul li ul li,
#site-header nav.category ul li ul li ul li {
  padding-left: 15px;
  position: relative;
}
#content-sidebar nav ul li ul li ul li a, #content-sidebar nav ul li ul li ul li span,
#site-header nav.category ul li ul li ul li a,
#site-header nav.category ul li ul li ul li span {
  font-size: 12.8px;
  font-size: 0.8rem;
}
#content-sidebar nav ul li ul li ul li ul li,
#site-header nav.category ul li ul li ul li ul li {
  padding-left: 15px;
}
#content-sidebar nav ul li ul li ul li ul li:before,
#site-header nav.category ul li ul li ul li ul li:before {
  content: "";
  padding-right: 6px;
}
#content-sidebar nav ul li ul li ul li ul li a, #content-sidebar nav ul li ul li ul li ul li span,
#site-header nav.category ul li ul li ul li ul li a,
#site-header nav.category ul li ul li ul li ul li span {
  font-size: 12.8px;
  font-size: 0.8rem;
  font-weight: 300;
}
#content-sidebar nav li:before,
#site-header nav.category li:before {
  display: none;
}
#content-sidebar nav a,
#site-header nav.category a {
  color: rgb(30, 30, 30);
}
#content-sidebar nav a.load-tree.has-children,
#site-header nav.category a.load-tree.has-children {
  color: rgb(30, 30, 30);
}
#content-sidebar nav a:hover,
#site-header nav.category a:hover {
  text-decoration: none;
}

#site-header nav.custom a:before, #site-header nav.custom li:before {
  display: none !important;
}

#site-footer nav a {
  color: white !important;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * MENU - In sidebar & footer
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#content-sidebar .custom-menu-block .toggle-child-menu,
#site-footer .custom-menu-block .toggle-child-menu {
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  top: 1px;
  right: 0;
  display: none;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  color: inherit;
}
#content-sidebar .custom-menu-block .toggle-child-menu:before,
#site-footer .custom-menu-block .toggle-child-menu:before {
  content: "\e900";
  line-height: 20px;
}
#content-sidebar .custom-menu-block .menu-item-has-loading-children .toggle-child-menu:before,
#site-footer .custom-menu-block .menu-item-has-loading-children .toggle-child-menu:before {
  content: "";
  border-radius: 50%;
  border-color: inherit;
  border-width: 1px;
  border-style: solid;
  border-top: 1px solid transparent;
  width: 13px;
  height: 13px;
  top: 5px;
  right: 10px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  display: block;
  position: absolute;
}
#content-sidebar .custom-menu-block .menu-title-has-children,
#site-footer .custom-menu-block .menu-title-has-children {
  padding-right: 40px;
}
#content-sidebar .custom-menu-block .menu-item-has-children,
#site-footer .custom-menu-block .menu-item-has-children {
  position: relative;
}
#content-sidebar .custom-menu-block .menu-item-has-children > .menu,
#site-footer .custom-menu-block .menu-item-has-children > .menu {
  display: none;
}
#content-sidebar .custom-menu-block .menu-item-has-children.menu-item-show-children > .menu,
#site-footer .custom-menu-block .menu-item-has-children.menu-item-show-children > .menu {
  display: block;
}
#content-sidebar .custom-menu-block .menu-item-show-children > .toggle-child-menu,
#site-footer .custom-menu-block .menu-item-show-children > .toggle-child-menu {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
#content-sidebar .custom-menu-block .toggle-child-menu,
#content-sidebar .category-tree-menu .toggle-child-menu,
#site-footer .custom-menu-block .toggle-child-menu,
#site-footer .category-tree-menu .toggle-child-menu {
  display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Category tree menu
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-header nav.category-tree-menu ul li a,
#site-header nav.category-tree-menu ul li a.load-tree.has-children {
  color: rgb(220, 220, 220);
}
#site-header nav.category-tree-menu ul li a:hover,
#site-header nav.category-tree-menu ul li a.load-tree.has-children:hover {
  color: #fff;
}
#site-header nav.category-tree-menu ul li a:before {
  display: none;
}
#site-header nav.category-tree-menu ul li a.load-tree.has-children:before {
  display: none;
}
#site-header nav.category-tree-menu ul li a.load-tree.has-children.open:before {
  display: none;
}
#site-header nav.category-tree-menu ul ul ul {
  margin-left: 0;
}
#site-header nav.category-tree-menu ul li ul li a {
  text-transform: uppercase;
}
#site-header nav.category-tree-menu ul li ul li ul li {
  padding-left: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * MENU - In Footer
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-footer nav {
  text-align: center;
}
#site-footer nav ul li {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
#site-footer nav ul li a {
  padding: 3px 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
#site-footer nav ul li li {
  display: block;
}
#site-footer nav ul li li a {
  text-transform: none;
  margin-bottom: 0px;
  display: block;
  font-size: 12.8px;
  font-size: 0.8rem;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CART (generic)
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.bundled-product-items {
  font-size: 0.8rem;
  line-height: 1.4em;
  margin-top: 0.4em;
  margin-bottom: 0.6em;
  margin-left: 1.4em;
  color: #000;
}

.bundled-product-item {
  position: relative;
  line-height: 1.1em;
  margin-bottom: 0.3em;
}
.bundled-product-item:before {
  content: "-";
  position: absolute;
  top: 0px;
  left: -10px;
}
.bundled-product-item .quantity {
  font-size: 16px;
  font-size: 1rem;
}
.bundled-product-item .quantity .unit {
  text-transform: uppercase;
  font-size: 0.6em;
}
.bundled-product-item .variant:before {
  content: ", " attr(data-title);
}
.bundled-product-item .sku {
  opacity: 0.4;
}
.bundled-product-item .sku:before {
  content: " " attr(data-title);
  padding-right: 0.2em;
}

#site-header .cart-block {
  position: absolute;
  top: 0px;
  right: 10px;
  line-height: 50px;
  height: 50px;
  width: 200px;
}
@media only screen and (max-width: 768px) {
  #site-header .cart-block {
    width: 100%;
  }
}
#site-header .cart-block .header {
  color: rgb(220, 220, 220);
  display: block;
}
#site-header .cart-block .header .cart-title,
#site-header .cart-block .header .cart-item-count,
#site-header .cart-block .header .cart-sum-label {
  display: none;
}
#site-header .cart-block .header .cart-icon {
  display: block;
  float: right;
  width: 50px;
  position: relative;
}
#site-header .cart-block .header .cart-icon b {
  display: block;
  text-indent: -9999px;
}
#site-header .cart-block .header .cart-icon:before {
  content: "\e611";
  text-align: center;
  width: 50px;
  display: block;
  font-size: 30px;
  position: absolute;
  top: 10px;
}
#site-header .cart-block .header .cart-icon:after {
  display: block;
  content: attr(data-item-count);
  color: black;
  background-color: rgb(220, 220, 220);
  position: absolute;
  top: 6px;
  right: 3px;
  text-align: center;
  height: 20px;
  min-width: 20px;
  line-height: 15px;
  padding: 1px 4px;
  border: 1px solid black;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
}
#site-header .cart-block .header .price {
  display: block;
  float: right;
  margin-left: 4px;
  font-size: 22.4px;
  font-size: 1.4rem;
  line-height: 52px;
}
#site-header .cart-block .header:hover {
  text-decoration: none;
  color: white !important;
}
#site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
#site-header .cart-block .empty-cart .price,
#site-header .cart-block .empty-cart .cart-subtotal,
#site-header .cart-block .empty-cart .checkout-link {
  display: none;
}
#site-header .cart-block .cart-content {
  position: absolute;
  display: none;
  background-color: white;
  color: rgb(30, 30, 30);
  top: 50px;
  right: 0px;
  z-index: 9999;
  width: 300px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 768px) {
  #site-header .cart-block .cart-content {
    width: 100%;
  }
}
#site-header .cart-block .cart-content:after {
  position: absolute;
  top: 0px;
  right: 55px;
  content: "";
  width: 4px;
  height: 4px;
  border: 10px solid;
  border-color: black transparent transparent transparent;
}
#site-header .cart-block .items {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
#site-header .cart-block .items > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
#site-header .cart-block .items li {
  position: relative;
  line-height: 1.2em;
  padding: 15px 10px 10px;
}
#site-header .cart-block .items li h3, #site-header .cart-block .items li h4 {
  margin: 0;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(30, 30, 30) !important;
}
#site-header .cart-block .items li h3 {
  font-size: 16px;
  font-size: 1rem;
}
#site-header .cart-block .items li h4 {
  font-size: 12.8px;
  font-size: 0.8rem;
}
#site-header .cart-block .items li h4 span {
  color: rgb(170, 170, 170);
}
#site-header .cart-block .items li .variant:before,
#site-header .cart-block .items li .stock-status:before {
  content: ". ";
}
#site-header .cart-block .items li h4 span:first-child:before {
  display: none;
}
#site-header .cart-block .items li .sku:before {
  content: ". " attr(data-title);
  padding-right: 4px;
}
#site-header .cart-block .items li .info {
  margin: 0.3em 0 0;
}
#site-header .cart-block .items li .info:before, #site-header .cart-block .items li .info:after {
  content: "";
  display: table;
}
#site-header .cart-block .items li .info:after {
  clear: both;
}
#site-header .cart-block .items li .info .quantity {
  float: left;
}
#site-header .cart-block .items li .info .price {
  float: right;
}
#site-header .cart-block .items li .price, #site-header .cart-block .items li .quantity {
  font-size: 16px;
  font-size: 1rem;
}
#site-header .cart-block .items li.cart-item {
  border-bottom: 1px solid rgb(231, 227, 227);
  position: relative;
}
#site-header .cart-block .items li.cart-item a:hover {
  text-decoration: none;
}
#site-header .cart-block .items li.cart-item:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: black;
}
#site-header .cart-block .items li.cart-item .remove-item-action {
  line-height: 16px !important;
  top: 6px !important;
}
#site-header .cart-block .items li.cart-subtotal {
  font-weight: 600;
}
#site-header .cart-block .items li.cart-subtotal:before, #site-header .cart-block .items li.cart-subtotal:after {
  content: "";
  display: table;
}
#site-header .cart-block .items li.cart-subtotal:after {
  clear: both;
}
#site-header .cart-block .items li.cart-subtotal .caption {
  float: left;
}
#site-header .cart-block .items li.cart-subtotal .price {
  float: right;
}
#site-header .cart-block .items li.cart-subtotal .currency {
  font-weight: 300;
}
#site-header .cart-block .empty-cart-message, #site-header .cart-block .db-cart-message {
  padding: 10px;
  display: block;
  line-height: 1.2em;
}
#site-header .cart-block .footer {
  padding: 10px;
}
#site-header .cart-block .footer:before, #site-header .cart-block .footer:after {
  content: "";
  display: table;
}
#site-header .cart-block .footer:after {
  clear: both;
}
#site-header .cart-block .footer a {
  text-decoration: none;
  text-align: center;
  position: relative;
  padding: 6px 30px;
  width: auto;
  font-size: 14px;
  line-height: 22px;
}
#site-header .cart-block .cart-content-close {
  float: left;
  background-color: transparent;
  padding-left: 40px;
  color: #aaa;
}
#site-header .cart-block .cart-content-close:hover {
  background-color: #d1d1d1;
  color: white;
}
#site-header .cart-block .cart-content-close:before {
  content: "\e61d";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 13px;
  font-size: 13px;
}
#site-header .cart-block .checkout-link {
  float: right;
  padding-right: 33px;
  padding-left: 20px;
}
#site-header .cart-block .checkout-link:after {
  content: "\f105";
  padding-left: 10px;
  font-size: 22px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 10px;
}
#site-header .cart-block .cart-wrap.empty-cart .price,
#site-header .cart-block .cart-wrap.empty-cart .items,
#site-header .cart-block .cart-wrap.empty-cart .checkout-link {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * CART (sidebar)
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#content-sidebar .cart-block,
#site-footer .cart-block {
  margin-bottom: 20px;
}
#content-sidebar .cart-block .items,
#site-footer .cart-block .items {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
#content-sidebar .cart-block .items > li,
#site-footer .cart-block .items > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
#content-sidebar .cart-block .items li,
#site-footer .cart-block .items li {
  position: relative;
  line-height: 1.2em;
  padding: 15px 10px 10px;
}
#content-sidebar .cart-block .items li h3, #content-sidebar .cart-block .items li h4,
#site-footer .cart-block .items li h3,
#site-footer .cart-block .items li h4 {
  margin: 0;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(30, 30, 30) !important;
}
#content-sidebar .cart-block .items li h3,
#site-footer .cart-block .items li h3 {
  font-size: 16px;
  font-size: 1rem;
}
#content-sidebar .cart-block .items li h4,
#site-footer .cart-block .items li h4 {
  font-size: 12.8px;
  font-size: 0.8rem;
}
#content-sidebar .cart-block .items li h4 span,
#site-footer .cart-block .items li h4 span {
  color: rgb(170, 170, 170);
}
#content-sidebar .cart-block .items li .variant:before,
#content-sidebar .cart-block .items li .stock-status:before,
#site-footer .cart-block .items li .variant:before,
#site-footer .cart-block .items li .stock-status:before {
  content: ". ";
}
#content-sidebar .cart-block .items li h4 span:first-child:before,
#site-footer .cart-block .items li h4 span:first-child:before {
  display: none;
}
#content-sidebar .cart-block .items li .sku:before,
#site-footer .cart-block .items li .sku:before {
  content: ". " attr(data-title);
  padding-right: 4px;
}
#content-sidebar .cart-block .items li .info,
#site-footer .cart-block .items li .info {
  margin: 0.3em 0 0;
}
#content-sidebar .cart-block .items li .info:before, #content-sidebar .cart-block .items li .info:after,
#site-footer .cart-block .items li .info:before,
#site-footer .cart-block .items li .info:after {
  content: "";
  display: table;
}
#content-sidebar .cart-block .items li .info:after,
#site-footer .cart-block .items li .info:after {
  clear: both;
}
#content-sidebar .cart-block .items li .info .quantity,
#site-footer .cart-block .items li .info .quantity {
  float: left;
}
#content-sidebar .cart-block .items li .info .price,
#site-footer .cart-block .items li .info .price {
  float: right;
}
#content-sidebar .cart-block .items li .price, #content-sidebar .cart-block .items li .quantity,
#site-footer .cart-block .items li .price,
#site-footer .cart-block .items li .quantity {
  font-size: 16px;
  font-size: 1rem;
}
#content-sidebar .cart-block .items li.cart-item,
#site-footer .cart-block .items li.cart-item {
  border-bottom: 1px solid rgb(231, 227, 227);
  position: relative;
}
#content-sidebar .cart-block .items li.cart-item a:hover,
#site-footer .cart-block .items li.cart-item a:hover {
  text-decoration: none;
}
#content-sidebar .cart-block .items li.cart-item:hover:before,
#site-footer .cart-block .items li.cart-item:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: black;
}
#content-sidebar .cart-block .items li.cart-item .remove-item-action,
#site-footer .cart-block .items li.cart-item .remove-item-action {
  line-height: 16px !important;
  top: 6px !important;
}
#content-sidebar .cart-block .items li.cart-subtotal,
#site-footer .cart-block .items li.cart-subtotal {
  font-weight: 600;
}
#content-sidebar .cart-block .items li.cart-subtotal:before, #content-sidebar .cart-block .items li.cart-subtotal:after,
#site-footer .cart-block .items li.cart-subtotal:before,
#site-footer .cart-block .items li.cart-subtotal:after {
  content: "";
  display: table;
}
#content-sidebar .cart-block .items li.cart-subtotal:after,
#site-footer .cart-block .items li.cart-subtotal:after {
  clear: both;
}
#content-sidebar .cart-block .items li.cart-subtotal .caption,
#site-footer .cart-block .items li.cart-subtotal .caption {
  float: left;
}
#content-sidebar .cart-block .items li.cart-subtotal .price,
#site-footer .cart-block .items li.cart-subtotal .price {
  float: right;
}
#content-sidebar .cart-block .items li.cart-subtotal .currency,
#site-footer .cart-block .items li.cart-subtotal .currency {
  font-weight: 300;
}
#content-sidebar .cart-block .empty-cart-message, #content-sidebar .cart-block .db-cart-message,
#site-footer .cart-block .empty-cart-message,
#site-footer .cart-block .db-cart-message {
  padding: 10px;
  display: block;
  line-height: 1.2em;
}
#content-sidebar .cart-block .footer,
#site-footer .cart-block .footer {
  padding: 10px;
}
#content-sidebar .cart-block .footer:before, #content-sidebar .cart-block .footer:after,
#site-footer .cart-block .footer:before,
#site-footer .cart-block .footer:after {
  content: "";
  display: table;
}
#content-sidebar .cart-block .footer:after,
#site-footer .cart-block .footer:after {
  clear: both;
}
#content-sidebar .cart-block .footer a,
#site-footer .cart-block .footer a {
  text-decoration: none;
  text-align: center;
  position: relative;
  padding: 6px 30px;
  width: auto;
  font-size: 14px;
  line-height: 22px;
}
#content-sidebar .cart-block .cart-content-close,
#site-footer .cart-block .cart-content-close {
  float: left;
  background-color: transparent;
  padding-left: 40px;
  color: #aaa;
}
#content-sidebar .cart-block .cart-content-close:hover,
#site-footer .cart-block .cart-content-close:hover {
  background-color: #d1d1d1;
  color: white;
}
#content-sidebar .cart-block .cart-content-close:before,
#site-footer .cart-block .cart-content-close:before {
  content: "\e61d";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 13px;
  font-size: 13px;
}
#content-sidebar .cart-block .checkout-link,
#site-footer .cart-block .checkout-link {
  float: right;
  padding-right: 33px;
  padding-left: 20px;
}
#content-sidebar .cart-block .checkout-link:after,
#site-footer .cart-block .checkout-link:after {
  content: "\f105";
  padding-left: 10px;
  font-size: 22px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 10px;
}
#content-sidebar .cart-block .cart-wrap.empty-cart .price,
#content-sidebar .cart-block .cart-wrap.empty-cart .items,
#content-sidebar .cart-block .cart-wrap.empty-cart .checkout-link,
#site-footer .cart-block .cart-wrap.empty-cart .price,
#site-footer .cart-block .cart-wrap.empty-cart .items,
#site-footer .cart-block .cart-wrap.empty-cart .checkout-link {
  display: none;
}
#content-sidebar .cart-block .header,
#site-footer .cart-block .header {
  padding: 11px 1em 9px;
  text-decoration: none;
  position: relative;
  color: white;
  background-color: black;
  display: block;
  text-align: center;
  line-height: 16px;
}
#content-sidebar .cart-block .header .cart-title,
#site-footer .cart-block .header .cart-title {
  font-size: 16px;
  font-size: 1rem;
  text-transform: uppercase;
}
#content-sidebar .cart-block .header .cart-item-count,
#site-footer .cart-block .header .cart-item-count {
  font-size: 16px;
  font-size: 1rem;
}
#content-sidebar .cart-block .header .cart-sum-label,
#content-sidebar .cart-block .header .cart-icon,
#site-footer .cart-block .header .cart-sum-label,
#site-footer .cart-block .header .cart-icon {
  display: none;
}
#content-sidebar .cart-block .header .price,
#site-footer .cart-block .header .price {
  font-size: 16px;
  font-size: 1rem;
}
#content-sidebar .cart-block .cart-wrap .db-cart-message,
#site-footer .cart-block .cart-wrap .db-cart-message {
  display: block;
  margin-top: 0;
}
#content-sidebar .cart-block .cart-wrap.empty-cart .cart-subtotal,
#content-sidebar .cart-block .cart-wrap.empty-cart .checkout-link,
#content-sidebar .cart-block .cart-wrap.empty-cart .quantity,
#site-footer .cart-block .cart-wrap.empty-cart .cart-subtotal,
#site-footer .cart-block .cart-wrap.empty-cart .checkout-link,
#site-footer .cart-block .cart-wrap.empty-cart .quantity {
  display: none;
}
#content-sidebar .cart-block .cart-wrap.empty-cart .empty-cart-message,
#site-footer .cart-block .cart-wrap.empty-cart .empty-cart-message {
  display: block;
}
#content-sidebar .cart-block .cart-content,
#site-footer .cart-block .cart-content {
  background-color: white;
  display: none;
  position: relative;
}
#content-sidebar .cart-block .cart-content:after,
#site-footer .cart-block .cart-content:after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
  content: "";
  width: 4px;
  height: 4px;
  border: 10px solid;
  border-color: black transparent transparent transparent;
}
#content-sidebar .cart-block .cart-content .footer .cart-content-close,
#site-footer .cart-block .cart-content .footer .cart-content-close {
  text-indent: -9999px;
  padding-left: 10px;
}
#content-sidebar .cart-block .cart-content .footer .cart-content-close:before,
#site-footer .cart-block .cart-content .footer .cart-content-close:before {
  text-indent: 0;
}

#site-footer .cart-block {
  color: rgb(30, 30, 30);
}
#site-footer .cart-block .cart-content .footer a:hover {
  background-color: #000;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Starweb
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.software-by-starweb a {
  text-decoration: none !important;
}
.software-by-starweb a:hover {
  text-decoration: none;
}
.software-by-starweb i, .software-by-starweb a {
  font-style: normal;
  font-size: 9.6px;
  font-size: 0.6rem;
  color: white;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Search (header & sidebar)
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-header .search-block,
#content-sidebar .search-block {
  margin: 0;
}
#site-header .search-block h3,
#content-sidebar .search-block h3 {
  display: none;
}
#site-header .search-block .content,
#content-sidebar .search-block .content {
  position: relative;
}
#site-header .search-block .field-wrap,
#content-sidebar .search-block .field-wrap {
  width: 100%;
}
#site-header .search-block button,
#content-sidebar .search-block button {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
  top: 1px;
  right: 0;
  background-color: transparent !important;
  box-shadow: none;
}
#site-header .search-block button span,
#content-sidebar .search-block button span {
  display: none;
}
#site-header .search-block button:before,
#content-sidebar .search-block button:before {
  content: "\e609";
  color: silver;
  display: block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
#site-header .search-block button:hover:before, #site-header .search-block button:focus:before,
#content-sidebar .search-block button:hover:before,
#content-sidebar .search-block button:focus:before {
  color: #12588e !important;
}

#site-header .search-block {
  width: 170px;
  position: absolute;
  top: 10px;
  right: 10px;
}
#site-header .search-block form {
  margin: 0;
}
#site-header .search-block input[type=search] {
  border-style: none;
}

#site-header.has-cart-block.has-search-block .search-block {
  width: 88px;
}
#site-header.has-cart-block.has-search-block .cart-block {
  right: 110px;
}

#content-sidebar .search-block {
  margin-bottom: 20px;
}
#content-sidebar .search-block form {
  margin: 0;
}

.autocomplete-suggestions {
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(123, 154, 178, 0.8);
  border-top: none;
  box-shadow: 0 3px 8px rgba(23, 100, 167, 0.2);
  color: rgb(30, 30, 30);
  background-color: rgb(247, 247, 247);
}
.autocomplete-suggestions .autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: white;
}
.autocomplete-suggestions strong {
  font-weight: normal;
  color: #1764a7;
}
.autocomplete-suggestions .autocomplete-group {
  padding: 2px 5px;
}
.autocomplete-suggestions .autocomplete-group strong {
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom: 1px solid #1764a7;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Newsletter (header)
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-header .newsletter-signup-block h3 {
  margin-bottom: 0.3em;
  color: rgb(30, 30, 30) !important;
}
#site-header .newsletter-signup-block .content {
  position: relative;
}
#site-header .newsletter-signup-block .field-wrap {
  width: 100%;
  padding-right: 160px;
}
#site-header .newsletter-signup-block .field-wrap .control input {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
@media only screen and (max-width: 767px) {
  #site-header .newsletter-signup-block .field-wrap {
    padding: 0;
  }
  #site-header .newsletter-signup-block .field-wrap .control input {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}
#site-header .newsletter-signup-block button {
  margin: 0;
  width: 160px;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
@media only screen and (max-width: 767px) {
  #site-header .newsletter-signup-block button {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Newsletter
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-footer .newsletter-signup-block h3,
#main-content .newsletter-signup-block h3,
#site-footer .search-block h3,
#main-content .search-block h3,
.password-recovery h3 {
  margin-bottom: 0.3em;
}
#site-footer .newsletter-signup-block .content,
#main-content .newsletter-signup-block .content,
#site-footer .search-block .content,
#main-content .search-block .content,
.password-recovery .content {
  position: relative;
}
#site-footer .newsletter-signup-block .field-wrap,
#main-content .newsletter-signup-block .field-wrap,
#site-footer .search-block .field-wrap,
#main-content .search-block .field-wrap,
.password-recovery .field-wrap {
  width: 100%;
  padding-right: 160px;
}
@media only screen and (max-width: 767px) {
  #site-footer .newsletter-signup-block .field-wrap,
  #main-content .newsletter-signup-block .field-wrap,
  #site-footer .search-block .field-wrap,
  #main-content .search-block .field-wrap,
  .password-recovery .field-wrap {
    padding: 0;
  }
}
#site-footer .newsletter-signup-block button,
#main-content .newsletter-signup-block button,
#site-footer .search-block button,
#main-content .search-block button,
.password-recovery button {
  margin: 0;
  width: 160px;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  #site-footer .newsletter-signup-block button,
  #main-content .newsletter-signup-block button,
  #site-footer .search-block button,
  #main-content .search-block button,
  .password-recovery button {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Gallery
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#site-footer .gallery,
.gallery {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
  margin-left: -2%;
  margin-top: 10px;
}
#site-footer .gallery > li,
.gallery > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
#site-footer .gallery.empty-block,
.gallery.empty-block {
  display: none;
}
@media only screen and (max-width: 767px) {
  #site-footer .gallery,
  .gallery {
    margin-left: 0;
  }
}
#site-footer .gallery a,
.gallery a {
  text-decoration: none;
  color: rgb(30, 30, 30) !important;
  font-weight: 300;
  display: block;
  padding: 4px;
}
#site-footer .gallery h3,
.gallery h3 {
  color: rgb(30, 30, 30) !important;
}
#site-footer .gallery .price,
#site-footer .gallery .product-price,
.gallery .price,
.gallery .product-price {
  font-size: 16px;
  font-size: 1rem;
}
#site-footer .gallery .original-price,
.gallery .original-price {
  color: #777777;
  margin-top: 0;
}
#site-footer .gallery .original-price,
#site-footer .gallery .original-price .price,
.gallery .original-price,
.gallery .original-price .price {
  font-size: 11.2px;
  font-size: 0.7rem;
}
#site-footer .gallery .details,
.gallery .details {
  min-height: 134px;
}
#site-footer .gallery .details.no-price,
.gallery .details.no-price {
  min-height: 65px;
}
#site-footer .gallery .product-sku,
.gallery .product-sku {
  display: none;
}
#site-footer .gallery .product-price,
.gallery .product-price {
  margin: 0;
}
#site-footer .gallery > li > a,
.gallery > li > a {
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.25), 0 -1px 0px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s linear;
}
#site-footer .gallery > li > a:hover,
.gallery > li > a:hover {
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
}
#site-footer .gallery li:after,
.gallery li:after {
  content: "\f105";
  position: absolute;
  height: 40px;
  margin-top: -20px;
  top: 50%;
  right: 10px;
  font-size: 40px;
  display: none;
}
#site-footer .gallery li.save-percent .save-percent,
.gallery li.save-percent .save-percent {
  position: absolute;
  top: 50px;
  left: 20px;
  background-color: rgb(33, 33, 33);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
}
#site-footer .gallery li.save-percent .save-percent > span,
.gallery li.save-percent .save-percent > span {
  text-shadow: none;
  color: white;
  display: block;
}
#site-footer .gallery li.save-percent .save-percent .label,
.gallery li.save-percent .save-percent .label {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  margin-top: 12px;
}
#site-footer .gallery li.save-percent .save-percent .percentage,
.gallery li.save-percent .save-percent .percentage {
  white-space: nowrap;
  font-size: 26px;
  line-height: 20px;
  font-weight: 300;
  margin-top: 4px;
}
#site-footer .gallery li.save-percent .save-percent .percentage .value,
.gallery li.save-percent .save-percent .percentage .value {
  padding-left: 8px;
}
#site-footer .gallery li.save-percent .save-percent .percentage .unit,
.gallery li.save-percent .save-percent .percentage .unit {
  font-size: 14px;
  position: relative;
  top: -7px;
  left: -6px;
}
#site-footer .gallery.gallery-layout-2 li,
.gallery.gallery-layout-2 li {
  width: 48%;
}
#site-footer .gallery.gallery-layout-3 li,
.gallery.gallery-layout-3 li {
  width: 31.333333%;
}
#site-footer .gallery li,
.gallery li {
  width: 23%;
  float: left;
  margin-left: 2%;
  position: relative;
  margin-bottom: 20px;
  transition: width linear 0.2s;
}
#site-footer .gallery .product-details,
.gallery .product-details {
  display: none;
}
#site-footer .gallery .details,
.gallery .details {
  padding: 10px;
}
#site-footer .gallery .description,
.gallery .description {
  margin-bottom: 20px;
  text-align: center;
}
#site-footer .gallery .description h3, #site-footer .gallery .description p,
.gallery .description h3,
.gallery .description p {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
#site-footer .gallery .description h3,
.gallery .description h3 {
  margin-top: 0.2em;
  font-size: 16px;
  font-size: 1rem;
  word-break: break-all;
}
#site-footer .gallery .description p,
.gallery .description p {
  font-size: 11.2px;
  font-size: 0.7rem;
  margin-top: 0.3em;
  line-height: 1.3em;
}
#site-footer .gallery .no-price .description,
.gallery .no-price .description {
  margin-bottom: 0;
}
#site-footer .gallery .add-to-cart,
#site-footer .gallery .info,
.gallery .add-to-cart,
.gallery .info {
  position: absolute;
  right: -10px;
  width: 170px;
  height: 40px;
  padding: 0;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  max-width: 150px !important;
}
#site-footer .gallery .add-to-cart span,
#site-footer .gallery .info span,
.gallery .add-to-cart span,
.gallery .info span {
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  right: 40px;
  padding: 0;
  width: 110px;
  top: 0;
  height: 40px;
  line-height: 40px;
  font-size: 12.8px;
  font-size: 0.8rem;
  color: white;
}
#site-footer .gallery .add-to-cart span:after,
#site-footer .gallery .info span:after,
.gallery .add-to-cart span:after,
.gallery .info span:after {
  content: "";
  width: 0;
  height: 0;
  border: 4px solid;
  position: absolute;
  right: -8px;
  top: 50%;
  margin-top: -4px;
  border-color: transparent transparent transparent black;
  z-index: 2;
}
#site-footer .gallery .add-to-cart:before,
#site-footer .gallery .info:before,
.gallery .add-to-cart:before,
.gallery .info:before {
  padding: 0 !important;
  color: black;
  background-color: white;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  line-height: 40px !important;
  right: 0;
  top: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in;
}
#site-footer .gallery .add-to-cart:hover:before,
#site-footer .gallery .info:hover:before,
.gallery .add-to-cart:hover:before,
.gallery .info:hover:before {
  color: #1764a7;
  font-size: 20px;
}
#site-footer .gallery .add-to-cart:hover span:after,
#site-footer .gallery .info:hover span:after,
.gallery .add-to-cart:hover span:after,
.gallery .info:hover span:after {
  border-left-color: #12588e;
}
#site-footer .gallery .add-to-cart,
#site-footer .gallery .info,
.gallery .add-to-cart,
.gallery .info {
  top: 0;
}
#site-footer .gallery.add-to-wish-list,
.gallery.add-to-wish-list {
  top: 41px;
}
#site-footer .gallery .product-price,
.gallery .product-price {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #site-footer .gallery li,
  .gallery li {
    position: relative;
    float: none;
    width: auto !important;
    margin-bottom: 10px;
    margin-left: 0;
  }
  #site-footer .gallery li:after,
  .gallery li:after {
    display: block;
  }
  #site-footer .gallery li.selling-price .details,
  .gallery li.selling-price .details {
    padding-top: 20px;
  }
  #site-footer .gallery figure,
  .gallery figure {
    position: absolute;
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
    float: left;
    width: 170px;
  }
  #site-footer .gallery a,
  .gallery a {
    padding: 10px;
    padding-right: 30px;
    padding-left: 180px;
    display: block;
  }
  #site-footer .gallery a:before, #site-footer .gallery a:after,
  .gallery a:before,
  .gallery a:after {
    content: "";
    display: table;
  }
  #site-footer .gallery a:after,
  .gallery a:after {
    clear: both;
  }
  #site-footer .gallery a:hover figure:after,
  .gallery a:hover figure:after {
    color: red;
  }
  #site-footer .gallery .details,
  .gallery .details {
    min-height: 170px;
    width: 100%;
    margin-left: 2%;
    padding-top: 0 !important;
    padding-right: 0 !important;
  }
  #site-footer .gallery .product-offer,
  .gallery .product-offer {
    display: none;
  }
  #site-footer .gallery .product-price,
  .gallery .product-price {
    text-align: left !important;
    position: static !important;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin-bottom: 10px;
    min-height: 0;
  }
  #site-footer .gallery .description,
  .gallery .description {
    text-align: left;
    margin-bottom: 15px !important;
  }
  #site-footer .gallery .description h3,
  .gallery .description h3 {
    margin-top: 0 !important;
    line-height: 1.1em;
  }
  #site-footer .gallery .description h3, #site-footer .gallery .description p,
  .gallery .description h3,
  .gallery .description p {
    overflow: visible;
    text-overflow: clip;
    -o-text-overflow: clip;
    white-space: normal;
  }
  #site-footer .gallery .description h3,
  .gallery .description h3 {
    font-size: 16px;
    font-size: 1rem;
  }
  #site-footer .gallery .description p,
  .gallery .description p {
    font-size: 9.6px;
    font-size: 0.6rem;
    margin-top: 0;
  }
  #site-footer .gallery .price,
  #site-footer .gallery .product-price,
  .gallery .price,
  .gallery .product-price {
    font-size: 16px;
    font-size: 1rem;
  }
  #site-footer .gallery .original-price,
  .gallery .original-price {
    margin-top: 0;
  }
  #site-footer .gallery .original-price .price,
  .gallery .original-price .price {
    font-size: 11.2px;
    font-size: 0.7rem;
    line-height: 11.2px;
    line-height: 0.7rem;
  }
}
@media only screen and (max-width: 479px) {
  #site-footer .gallery a,
  .gallery a {
    padding-left: 110px !important;
  }
  #site-footer .gallery figure,
  .gallery figure {
    width: 90px !important;
  }
  #site-footer .gallery .details,
  .gallery .details {
    min-height: 80px !important;
    padding: 0 !important;
  }
  #site-footer .gallery li.save-percent .save-percent,
  .gallery li.save-percent .save-percent {
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
  }
  #site-footer .gallery li.save-percent .save-percent .label,
  .gallery li.save-percent .save-percent .label {
    font-size: 8px;
    line-height: 1em;
    margin-top: 8px;
  }
  #site-footer .gallery li.save-percent .save-percent .percentage,
  .gallery li.save-percent .save-percent .percentage {
    font-size: 16px;
    margin-top: 2px;
    line-height: 1em;
    margin-top: 1px;
  }
  #site-footer .gallery li.save-percent .save-percent .percentage .value,
  .gallery li.save-percent .save-percent .percentage .value {
    padding-left: 3px;
  }
  #site-footer .gallery li.save-percent .save-percent .percentage .unit,
  .gallery li.save-percent .save-percent .percentage .unit {
    font-size: 10px;
    top: -3px;
    left: -3px;
  }
}
#site-footer .gallery .no-products,
.gallery .no-products {
  width: 100%;
  font-size: 1.5rem;
}

.product-label {
  position: absolute;
  overflow: hidden;
  text-align: center;
}
.product-label span {
  display: block;
  position: absolute;
  font-size: 10px;
  color: #FFF;
  background-color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.product-label-1 {
  top: 0;
  right: 0;
  width: 75px;
  height: 75px;
}
.product-label-1 span {
  top: 18px;
  right: -22px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 100px;
}

.product-label-0 {
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
}
.product-label-0 span {
  left: 0;
  right: 0;
}

.has-product-labels .details {
  min-height: 154px;
}
@media only screen and (max-width: 767px) {
  .has-product-labels li:after {
    display: none;
  }
}

#site-header .gallery-layout-1 li,
#main-content .gallery-layout-1 li,
#site-footer .gallery-layout-1 li {
  margin-bottom: 20px;
  position: relative;
  float: none;
  width: auto;
}
#site-header .gallery-layout-1 > li > a,
#main-content .gallery-layout-1 > li > a,
#site-footer .gallery-layout-1 > li > a {
  padding: 10px;
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.25), 0 -1px 0px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s linear;
}
#site-header .gallery-layout-1 > li > a:hover,
#main-content .gallery-layout-1 > li > a:hover,
#site-footer .gallery-layout-1 > li > a:hover {
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
}
#site-header .gallery-layout-1 figure,
#main-content .gallery-layout-1 figure,
#site-footer .gallery-layout-1 figure {
  width: 170px;
  position: absolute;
  top: 10px;
  left: 10px;
}
#site-header .gallery-layout-1 .details,
#main-content .gallery-layout-1 .details,
#site-footer .gallery-layout-1 .details {
  padding: 5px 170px 0 190px;
  min-height: 170px;
  position: relative;
  margin-left: 0;
}
#site-header .gallery-layout-1 .description,
#main-content .gallery-layout-1 .description,
#site-footer .gallery-layout-1 .description {
  text-align: left;
  margin-bottom: 0;
}
#site-header .gallery-layout-1 .description h3,
#main-content .gallery-layout-1 .description h3,
#site-footer .gallery-layout-1 .description h3 {
  margin: -6px 0 0;
  line-height: 1.1em;
}
#site-header .gallery-layout-1 .description h3, #site-header .gallery-layout-1 .description p,
#main-content .gallery-layout-1 .description h3,
#main-content .gallery-layout-1 .description p,
#site-footer .gallery-layout-1 .description h3,
#site-footer .gallery-layout-1 .description p {
  overflow: visible;
  text-overflow: clip;
  -o-text-overflow: clip;
  white-space: normal;
}
#site-header .gallery-layout-1 .product-offer,
#site-header .gallery-layout-1 .product-price,
#main-content .gallery-layout-1 .product-offer,
#main-content .gallery-layout-1 .product-price,
#site-footer .gallery-layout-1 .product-offer,
#site-footer .gallery-layout-1 .product-price {
  width: 150px;
  position: absolute;
  top: 70px;
  right: 0px;
}
#site-header .gallery-layout-1 .product-offer,
#main-content .gallery-layout-1 .product-offer,
#site-footer .gallery-layout-1 .product-offer {
  right: 10px;
}
#site-header .gallery-layout-1 .product-price,
#main-content .gallery-layout-1 .product-price,
#site-footer .gallery-layout-1 .product-price {
  text-align: right;
  margin-bottom: 10px;
  top: -3px;
}
#site-header .gallery-layout-1 .product-details,
#main-content .gallery-layout-1 .product-details,
#site-footer .gallery-layout-1 .product-details {
  display: block;
  font-size: 11.2px;
  font-size: 0.7rem;
  color: #919191;
  margin: 0;
}
#site-header .gallery-layout-1 .product-details dd,
#main-content .gallery-layout-1 .product-details dd,
#site-footer .gallery-layout-1 .product-details dd {
  float: none;
  width: auto;
}
#site-header .gallery-layout-1 .product-details dt,
#main-content .gallery-layout-1 .product-details dt,
#site-footer .gallery-layout-1 .product-details dt {
  display: none;
}
#site-header .gallery-layout-1 .add-to-cart,
#site-header .gallery-layout-1 .info,
#site-header .gallery-layout-1 .add-to-cart:before,
#site-header .gallery-layout-1 .info:before,
#site-header .gallery-layout-1 .add-to-cart span,
#site-header .gallery-layout-1 .info span,
#main-content .gallery-layout-1 .add-to-cart,
#main-content .gallery-layout-1 .info,
#main-content .gallery-layout-1 .add-to-cart:before,
#main-content .gallery-layout-1 .info:before,
#main-content .gallery-layout-1 .add-to-cart span,
#main-content .gallery-layout-1 .info span,
#site-footer .gallery-layout-1 .add-to-cart,
#site-footer .gallery-layout-1 .info,
#site-footer .gallery-layout-1 .add-to-cart:before,
#site-footer .gallery-layout-1 .info:before,
#site-footer .gallery-layout-1 .add-to-cart span,
#site-footer .gallery-layout-1 .info span {
  position: relative;
  top: auto;
  right: auto;
  box-shadow: none;
}
#site-header .gallery-layout-1 .add-to-cart:before,
#site-header .gallery-layout-1 .info:before,
#main-content .gallery-layout-1 .add-to-cart:before,
#main-content .gallery-layout-1 .info:before,
#site-footer .gallery-layout-1 .add-to-cart:before,
#site-footer .gallery-layout-1 .info:before {
  background-color: transparent;
  color: white;
}
#site-header .gallery-layout-1 .add-to-cart:before,
#site-header .gallery-layout-1 .info:before,
#main-content .gallery-layout-1 .add-to-cart:before,
#main-content .gallery-layout-1 .info:before,
#site-footer .gallery-layout-1 .add-to-cart:before,
#site-footer .gallery-layout-1 .info:before {
  display: inline;
  line-height: 160% !important;
}
#site-header .gallery-layout-1 .add-to-cart:hover:before,
#site-header .gallery-layout-1 .info:hover:before,
#main-content .gallery-layout-1 .add-to-cart:hover:before,
#main-content .gallery-layout-1 .info:hover:before,
#site-footer .gallery-layout-1 .add-to-cart:hover:before,
#site-footer .gallery-layout-1 .info:hover:before {
  font-size: 16px;
}
#site-header .gallery-layout-1 .add-to-cart span,
#site-header .gallery-layout-1 .info span,
#main-content .gallery-layout-1 .add-to-cart span,
#main-content .gallery-layout-1 .info span,
#site-footer .gallery-layout-1 .add-to-cart span,
#site-footer .gallery-layout-1 .info span {
  height: auto;
  width: auto;
  display: inline;
  line-height: 40px;
  padding-left: 10px;
}
#site-header .gallery-layout-1 .add-to-cart span:after,
#site-header .gallery-layout-1 .info span:after,
#main-content .gallery-layout-1 .add-to-cart span:after,
#main-content .gallery-layout-1 .info span:after,
#site-footer .gallery-layout-1 .add-to-cart span:after,
#site-footer .gallery-layout-1 .info span:after {
  display: none;
}
#site-header .gallery-layout-1 .add-to-cart,
#site-header .gallery-layout-1 .info,
#main-content .gallery-layout-1 .add-to-cart,
#main-content .gallery-layout-1 .info,
#site-footer .gallery-layout-1 .add-to-cart,
#site-footer .gallery-layout-1 .info {
  width: 100%;
  filter: alpha(opacity=100);
  opacity: 1;
  padding: 0 !important;
}
#site-header .gallery-layout-1 .add-to-cart:before,
#site-header .gallery-layout-1 .info:before,
#main-content .gallery-layout-1 .add-to-cart:before,
#main-content .gallery-layout-1 .info:before,
#site-footer .gallery-layout-1 .add-to-cart:before,
#site-footer .gallery-layout-1 .info:before {
  position: relative;
  top: 1px;
}
#site-header .gallery-layout-1 .add-to-wish-list,
#main-content .gallery-layout-1 .add-to-wish-list,
#site-footer .gallery-layout-1 .add-to-wish-list {
  background-color: transparent;
  padding: 0;
}
#site-header .gallery-layout-1 .add-to-wish-list:before,
#main-content .gallery-layout-1 .add-to-wish-list:before,
#site-footer .gallery-layout-1 .add-to-wish-list:before {
  color: rgb(30, 30, 30);
  font-size: 12.8px;
  font-size: 0.8rem;
}
#site-header .gallery-layout-1 .add-to-wish-list span,
#main-content .gallery-layout-1 .add-to-wish-list span,
#site-footer .gallery-layout-1 .add-to-wish-list span {
  color: rgb(30, 30, 30);
  font-size: 12.8px;
  font-size: 0.8rem;
}

#main-content .gallery.gallery-layout-1 .has-rating .details {
  min-height: 170px !important;
}

.desktop ul.gallery li:hover .add-to-cart,
.desktop ul.gallery li:hover .info {
  filter: alpha(opacity=100);
  opacity: 1;
  right: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Category gallery.
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
@media only screen and (max-width: 767px) {
  .categories.gallery li {
    margin: 0;
    margin-bottom: 10px !important;
  }
}
.categories.gallery .category-info {
  text-decoration: none;
  color: rgb(30, 30, 30);
  font-weight: 300;
  display: block;
  background-color: transparent;
  box-shadow: none;
}
@media only screen and (max-width: 767px) {
  .categories.gallery .category-info {
    padding-top: 0;
    min-height: 170px;
  }
}
@media only screen and (max-width: 479px) {
  .categories.gallery .category-info {
    min-height: 90px;
  }
}
.categories.gallery .category-info figure {
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.25), 0 -1px 0px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s linear;
}
@media only screen and (max-width: 767px) {
  .categories.gallery .category-info figure {
    top: 0;
    left: 0;
  }
}
.categories.gallery .category-info:hover figure {
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
}
.categories.gallery .category-info .details {
  min-height: 0px;
}
@media only screen and (max-width: 767px) {
  .categories.gallery .category-info .details {
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 479px) {
  .categories.gallery .category-info .details {
    min-height: 0px !important;
  }
}
.categories.gallery .category-info .description {
  margin: 0;
}
@media only screen and (max-width: 479px) {
  .categories.gallery.images .category-info {
    padding-left: 100px !important;
  }
}
.categories.gallery.names li:after {
  margin-top: -32px;
  right: 0;
}
.categories.gallery.names .category-info {
  padding: 0 40px 0 0 !important;
}
.categories.gallery.names .category-info:hover h3 {
  color: #1764a7 !important;
}
.categories.gallery.names .details {
  padding: 0;
  min-height: 0 !important;
}
.categories.gallery.names .description {
  text-align: left;
  margin-bottom: 20px !important;
}
.categories.gallery.names .description h3 {
  margin: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Gallery (sidebar)
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
ul.gallery.region-sidebar li {
  width: auto;
  float: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Customer form
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.customer-form-feedback {
  margin-bottom: 60px;
}

.customer-form-block h2 {
  margin-bottom: 20px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * WYSIWYG - Columns
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.column-content .column {
  margin-bottom: 20px;
}

.two.column-content {
  margin-left: -2%;
}
.two.column-content .column {
  float: left;
  margin-left: 2%;
  width: 48%;
}

.three.column-content {
  margin-left: -2%;
}
.three.column-content .column {
  float: left;
  margin-left: 2%;
  width: 31.3333333333%;
}

.four.column-content {
  margin-left: -2%;
}
.four.column-content .column {
  float: left;
  margin-left: 2%;
  width: 23%;
}

#content-sidebar .two.column-content,
#content-sidebar .three.column-content,
#content-sidebar .four.column-content {
  margin-left: -6%;
}
#content-sidebar .two.column-content .column {
  margin-left: 6%;
  width: 44%;
}
#content-sidebar .three.column-content .column {
  margin-left: 6%;
  width: 27.3333333333%;
}
#content-sidebar .four.column-content .column {
  margin-left: 6%;
  width: 19%;
}

@media only screen and (max-width: 767px) {
  #content-sidebar .two.column-content {
    margin-left: -2%;
  }
  #content-sidebar .two.column-content .column {
    float: left;
    margin-left: 2%;
    width: 48%;
  }
  #content-sidebar .three.column-content {
    margin-left: -2%;
  }
  #content-sidebar .three.column-content .column {
    float: left;
    margin-left: 2%;
    width: 31.3333333333%;
  }
  #content-sidebar .four.column-content {
    margin-left: -2%;
  }
  #content-sidebar .four.column-content .column {
    float: left;
    margin-left: 2%;
    width: 23%;
  }
}
@media only screen and (max-width: 479px) {
  .column-content {
    margin-left: 0 !important;
  }
  .column-content .column {
    float: none !important;
    width: auto !important;
    margin-left: 0 !important;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * WYSIWYG - Clear floating images
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * FAQ
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.faq-navigation {
  margin-bottom: 30px;
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}
.faq-navigation a {
  display: block;
  padding-left: 13px;
  position: relative;
}
.faq-navigation a:before {
  content: "•";
  padding-right: 2px;
  position: absolute;
  font-size: 26px;
  top: 0;
  left: 0;
  color: rgb(30, 30, 30);
}

.faq h3 {
  margin-top: 0;
}
.faq dd {
  margin: 0 0 30px 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SELECT LOCALE
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.select-locale-modal {
  margin: 0;
}
.select-locale-modal h3 {
  margin: 0;
}
.select-locale-modal .fieldset {
  margin-top: 0.5em;
}

.select-locale-modal-footer {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.select-locale-modal-footer button {
  width: auto;
}

.select-locale-modal-close-button {
  background-color: transparent;
  color: #000 !important;
  position: relative;
  padding-left: 40px;
}
.select-locale-modal-close-button:before {
  content: "\e61d";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 13px;
  font-size: 13px;
}

.desktop .select-locale-modal-close-button:hover {
  color: #fff;
}

.select-locale-button {
  background-color: transparent;
  color: rgb(30, 30, 30);
  border: 1px solid silver;
  width: auto;
  text-transform: none;
  font-size: 0.9em;
  padding: 0;
}

.select-locale-button-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.select-locale-button-content .icon:before {
  content: "\e907";
}
.select-locale-button-content span {
  padding: 4px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.select-locale-button-content .country,
.select-locale-button-content .language,
.select-locale-button-content .currency {
  border-left: 1px solid silver;
}

.desktop .select-locale-button:hover {
  color: #fff;
}

#site-footer .select-locale-button {
  color: #fff !important;
  border-color: #fff;
}
#site-footer .select-locale-button .country,
#site-footer .select-locale-button .language,
#site-footer .select-locale-button .currency {
  border-color: #fff;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BLOG - Latest posts block
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.blog-latest-posts-block .section:before, .blog-latest-posts-block .section:after {
  content: none;
}
.blog-latest-posts-block .columns {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-right: -20px;
}
.blog-latest-posts-block .columns:before, .blog-latest-posts-block .columns:after {
  content: none;
}
.blog-latest-posts-block .columns .blog-item {
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.25), 0 -1px 0px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s linear;
  margin-right: 20px;
  margin-top: 20px;
  width: calc(25% - 20px);
}
@media only screen and (max-width: 1200px) {
  .blog-latest-posts-block .columns .blog-item {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .blog-latest-posts-block .columns .blog-item {
    width: calc(100% - 20px);
  }
}
.blog-latest-posts-block .columns .blog-item .post-content {
  padding: 10px;
}
.blog-latest-posts-block .columns .blog-item .post-content a {
  color: rgb(30, 30, 30);
  text-decoration: none;
}
.blog-latest-posts-block .columns .blog-item .preamble {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.blog-latest-posts-block .columns .blog-item .preamble a {
  text-transform: capitalize;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BLOG - PAGE
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#blog-page .layout {
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 767px) {
  #blog-page .layout {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
#blog-page .layout #content {
  padding: 0;
}
#blog-page .layout #main-content {
  width: 100%;
}
#blog-page .layout-1 .layout-content.first,
#blog-page .layout-2 .layout-content.last {
  width: auto;
}
#blog-page .has-blog-posts-block #content,
#blog-page .has-blog-categories-block #content,
#blog-page .has-blog-tags-block #content,
#blog-page .has-blog-archive-block #content {
  max-width: none;
}
#blog-page .has-blog-posts-block #main-content,
#blog-page .has-blog-categories-block #main-content,
#blog-page .has-blog-tags-block #main-content,
#blog-page .has-blog-archive-block #main-content {
  width: auto;
}
#blog-page .post-article {
  background: white;
}
@media only screen and (max-width: 500px) {
  #blog-page .post-article {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
#blog-page .media-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
}
#blog-page .media-wrap a {
  height: 100%;
}
#blog-page .media-wrap a img {
  object-fit: cover;
  height: 100%;
  width: 200px;
}
@media only screen and (max-width: 500px) {
  #blog-page .media-wrap a img {
    width: 100%;
  }
}
#blog-page .preamble {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
#blog-page .preamble a {
  text-transform: capitalize;
}
#blog-page .layout-content > .block:first-child .widget-title h3 {
  margin-top: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BLOG POST PAGE
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#blog-post-page .blog-post-block #main-content {
  width: 100%;
}
#blog-post-page .blog-post-block #main-content .post-content {
  margin-left: 0;
  width: auto;
}
#blog-post-page .layout-content > .block:first-child .widget-title h3 {
  margin-top: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Product
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
article {
  position: relative;
}

.previous-next {
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 479px) {
  .previous-next {
    position: static;
    top: auto;
    right: auto;
  }
}
.previous-next a {
  text-decoration: none;
  color: #777777;
  font-size: 12.8px;
  font-size: 0.8rem;
  padding: 10px 15px;
  position: relative;
  line-height: 26px;
  display: inline-block;
}
.previous-next a:hover {
  color: rgb(30, 30, 30);
}
.previous-next .previous:before,
.previous-next .next:before,
.previous-next .next:after {
  position: absolute;
  top: 17px;
}
.previous-next .previous {
  padding-right: 0px;
}
.previous-next .previous:before {
  content: "\f104";
  left: 0;
}
.previous-next .next {
  padding-left: 10px;
}
.previous-next .next:before {
  content: "|";
  top: 10px;
  left: 0;
}
.previous-next .next:after {
  content: "\f105";
  right: 0;
}

figure img {
  display: block;
}
figure.no-image {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 0 1px rgb(240, 240, 240);
}
figure.no-image img {
  margin: 0 auto;
}

.product-block .product-header {
  float: right;
  width: 32.6%;
  float: right;
  margin-left: 4%;
}
@media only screen and (max-width: 1023px) {
  .product-block .product-header {
    display: none;
  }
}

.add-to-cart-wrap .quantity-field {
  display: none;
}
.add-to-cart-wrap .quantity-field .control {
  padding-right: 20px;
  position: relative;
  padding-right: 52%;
}
.add-to-cart-wrap .quantity-field .control .unit {
  position: absolute;
  display: block;
  top: 0;
  line-height: 30px;
  left: 52%;
}
.add-to-cart-wrap button {
  width: 100%;
  position: relative;
}

.product-media {
  width: 63.4%;
  float: left;
  margin-bottom: 20px;
  position: relative;
}
@media only screen and (max-width: 1023px) {
  .product-media > figure {
    max-width: 95%;
  }
}
.product-media figure a:not(.featured) {
  display: none;
}
.product-media.save-percent .save-percent {
  position: absolute;
  top: 100px;
  left: 20%;
  background-color: rgb(33, 33, 33);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
}
.product-media.save-percent .save-percent > span {
  text-shadow: none;
  color: white;
  display: block;
}
.product-media.save-percent .save-percent .label {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 10px;
  margin-top: 17px;
}
.product-media.save-percent .save-percent .percentage {
  white-space: nowrap;
  font-size: 38px;
  line-height: 20px;
  font-weight: 300;
  margin-top: 11px;
}
.product-media.save-percent .save-percent .percentage .value {
  padding-left: 14px;
}
.product-media.save-percent .save-percent .percentage .unit {
  font-size: 18px;
  position: relative;
  top: -13px;
  left: -9px;
}
@media only screen and (max-width: 1023px) {
  .product-media {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    float: none;
    min-height: 0;
    position: static;
    position: relative;
  }
  .product-media figure img {
    max-height: 500px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1300px) {
  .product-media .product-label span {
    font-size: 18px;
    line-height: 29px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .product-media .product-label-1 {
    width: 175px;
    height: 175px;
  }
  .product-media .product-label-1 span {
    top: 60px;
    right: -46px;
    width: 240px;
  }
  .product-media .product-label-0 {
    height: 27px;
  }
}

.product-images,
.social {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
.product-images > li,
.social > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.product-images:before, .product-images:after,
.social:before,
.social:after {
  content: "";
  display: table;
}
.product-images:after,
.social:after {
  clear: both;
}
.product-images li,
.social li {
  float: left;
}
.product-images a,
.social a {
  display: block;
  float: left;
}
.product-images img,
.social img {
  display: block;
}

.product-nav {
  margin-top: 10px;
  margin-left: -2%;
}
.product-nav figure {
  width: 91px;
  height: 91px;
  float: left;
  margin: 0 0 10px 2%;
}
.product-nav figure.current img {
  filter: alpha(opacity=60);
  opacity: 0.6;
}

.cloudzoom-zoom-inside img {
  background-color: white;
}

.cloudzoom-ajax-loader {
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
}

html#product-page .product-offer {
  width: 32.6%;
  float: right;
  margin-left: 4%;
}
@media only screen and (max-width: 767px) {
  html#product-page .product-offer {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    float: none;
    min-height: 0;
    position: static;
  }
}

html#product-page .product-price {
  margin: 10px 0;
}
html#product-page .product-price .price .currency {
  margin-left: 0;
  padding-left: 3px;
}
html#product-page .product-price button.selling-price {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  text-align: left;
  line-height: 25.6px;
  line-height: 1.6rem;
}
html#product-page .product-price .original-price {
  padding-left: 0;
  color: #777777;
}

.bundled-product-fields legend, .bundled-product-fields label {
  display: none;
}
.bundled-product-fields .quantity-field {
  margin-bottom: 0;
}
.bundled-product-fields .quantity-field .control {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.bundled-product-fields .quantity-field .control input {
  -webkit-flex: 1;
          flex: 1;
}
.bundled-product-fields .quantity-field .unit {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  line-height: 1.8em;
  font-size: 0.8rem;
}
.bundled-product-fields .remove-item-action {
  top: 0;
  right: 0;
}

.desktop .bundled-product-fields .remove-item-action:hover {
  background-color: transparent;
  color: #000;
}

.bundled-product {
  padding: 10px 10px 15px;
  background-color: #fff;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
}
.bundled-product .bundled-product-quantity {
  margin-top: 10px;
  line-height: 1.1em;
  font-size: 0.9rem;
}
.bundled-product .bundled-product-quantity .unit {
  padding-left: 0.1em;
}

.bundled-product-image {
  width: 20%;
  text-align: center;
}

.bundled-product-options {
  margin-left: 10px;
  width: calc(80% - 10px);
}

.bundled-product-title {
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2em;
}

.bundled-product-field-fixed-quantity {
  font-size: 0.9rem;
}

.product-offer .add-to-cart:before {
  content: "\e611";
  padding-right: 12px;
}

.product-offer .info:before {
  content: "\e602";
  padding-right: 12px;
}

.add-to-wish-list {
  background-color: transparent;
  color: rgb(30, 30, 30);
  text-transform: none;
  width: auto;
  padding: 0.6em 0;
}
.add-to-wish-list:before {
  content: "\f005";
  padding-right: 5px;
}

.product-status {
  display: none;
  background-color: white;
  padding: 5px;
  position: relative;
}
.product-status:after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
  content: "";
  width: 4px;
  height: 4px;
  border: 10px solid;
  border-color: black transparent transparent transparent;
}

.add-to-cart-form {
  margin-bottom: 10px;
}
.add-to-cart-form.hover .product-status:after {
  border-top-color: #12588e;
}

.status-message,
.view-cart {
  font-size: 14.4px;
  font-size: 0.9rem;
}

.status-message {
  line-height: 1.1em;
}
.status-message a {
  text-decoration: none;
}

.view-cart a {
  text-decoration: none;
  position: relative;
  display: block;
}
.view-cart b {
  font-weight: 300;
  padding-right: 20px;
}
.view-cart .cart-item-count {
  position: absolute;
  display: block;
  top: 4px;
  right: 0;
  color: white;
  background-color: black;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  text-align: center;
  height: 20px;
  min-width: 20px;
  padding: 1px 4px;
  line-height: 17px;
  font-size: 12px;
}

.product-variants {
  margin-bottom: 0px;
  margin-left: -4%;
}
.product-variants legend {
  font-size: 17.6px;
  font-size: 1.1rem;
  display: none;
}
.product-variants .field-wrap {
  float: left;
  width: 46%;
  margin-left: 4%;
}

.order-data {
  margin-top: 10px;
}

.product-details {
  font-size: 14.4px;
  font-size: 0.9rem;
  margin: 0 0 20px;
  color: #777777;
}
.product-details dt, .product-details dd {
  line-height: 1.3em;
}
.product-details dt {
  float: left;
  clear: both;
  width: 42%;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.product-details dt:after {
  content: ":";
}
.product-details dd {
  float: left;
  padding-left: 0;
  margin-left: 0;
  display: block;
  width: 56%;
  margin-bottom: 0.4em;
}
.product-details dd .unit {
  font-size: 9.6px;
  font-size: 0.6rem;
}
.manufacturer-related-products {
  margin: 0 0 20px;
}
.manufacturer-related-products a {
  font-size: 14.4px;
  font-size: 0.9rem;
}

.product-related-categories {
  margin-bottom: 20px;
}
.product-related-categories h3 {
  margin: 0;
}

html#product-page .product-overview {
  clear: left;
  width: 63.4%;
  float: left;
}
@media only screen and (max-width: 767px) {
  html#product-page .product-overview {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    float: none;
    min-height: 0;
    position: static;
  }
}
html#product-page .product-overview header {
  display: none;
}
@media only screen and (max-width: 1023px) {
  html#product-page .product-overview header {
    display: block;
  }
  html#product-page .product-overview header h2 {
    font-size: 33.6px;
    font-size: 2.1rem;
  }
}

html#product-page .short.description {
  margin-top: 0.6em;
  margin-bottom: 20px;
}
html#product-page .product-offer .product-stock-locations-wrap {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
html#product-page .product-offer .product-stock-locations-wrap .product-stock-locations {
  display: block;
  opacity: 0.7;
  font-size: 0.9rem;
  margin-top: 10px;
  line-height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
html#product-page .product-offer .product-stock-locations-wrap .product-stock-location-name {
  float: left;
  width: 42%;
}
html#product-page .product-offer .product-stock-locations-wrap .product-stock-location-name::after {
  content: ":";
}
html#product-page .product-offer .product-stock-locations-wrap .product-stock-location-status {
  width: 56%;
}
html#product-page .product-offer .product-stock-locations-wrap div:nth-child(n+6) {
  display: none;
}
html#product-page .expand-product-stock-locations-button {
  font-size: 15px;
}
html#product-page .expand-product-stock-locations-button .less {
  display: none;
}
html#product-page .has-product-stock-locations-expanded .product-stock-locations div:nth-child(n+6) {
  display: block;
}
html#product-page .has-product-stock-locations-expanded .expand-product-stock-locations-button .more {
  display: none;
}
html#product-page .has-product-stock-locations-expanded .expand-product-stock-locations-button .less {
  display: block;
}

.social {
  margin: 20px 0;
}
.social li {
  margin-left: 10px;
}
.social li:first-child {
  margin-left: 0;
}

.ilightbox-holder.mac {
  padding: 0;
}

.ilightbox-button.ilightbox-next-button.mac.horizontal,
.ilightbox-button.ilightbox-prev-button.mac.horizontal,
.ilightbox-button.mac.disabled {
  background-color: transparent !important;
}

.ilightbox-holder.mac {
  display: block !important;
  transition: top 0.4s ease;
}

.product-bundle-items {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -20px;
}

.product-bundle-item {
  width: calc(33.3333333333% - 20px);
  background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.25), 0 -1px 0px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s linear;
  margin-left: 20px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.product-bundle-item:hover {
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width: 500px) {
  .product-bundle-item {
    width: calc(50% - 10px);
  }
}
.product-bundle-item:nth-child(4n+4):after,
.product-bundle-item .product-bundle-divider:after {
  left: -10px;
}
.product-bundle-item figure {
  position: relative;
  text-align: center;
}
.product-bundle-item figure:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.product-bundle-item figure .figure-content {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.product-bundle-item figure .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(50%, 50%) translateZ(0);
  transform: translate(50%, 50%) translateZ(0);
}
.product-bundle-item figure img {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.product-bundle-item h3 {
  margin-top: 0;
  line-height: 1.1em;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.product-bundle-item .price {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .product-bundle-items {
    margin-left: -10px;
  }
  .product-bundle-item {
    margin-left: 10px;
  }
}
.product-bundle-item-content {
  background-color: #fff;
  -webkit-flex: 1;
          flex: 1;
}

.product-bundle-link {
  display: block;
}

.product-bundle-link,
.desktop .product-bundle-link:hover,
.desktop .product-bundle-link:visited {
  text-decoration: none;
  color: rgb(30, 30, 30);
}

.product-bundle-total {
  display: -webkit-flex;
  display: flex;
}
.product-bundle-total .button {
  font-size: 0.9rem;
  margin-top: 10px;
  width: 100%;
  display: inline-block;
}
.product-bundle-total .price {
  font-size: 0.9em;
}

.product-bundle-cta {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.product-bundle-divider {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.product-bundle-divider:after {
  background-color: #828282;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 50px;
  left: -30px;
  position: absolute;
  z-index: 20;
  content: "+";
}

@media only screen and (max-width: 500px) {
  .product-bundle-divider:after {
    left: -25px;
  }
  .product-bundle-item:nth-child(3n+3) .product-bundle-divider:after, .product-bundle-item:nth-last-child(1):nth-child(odd) .product-bundle-divider:after {
    left: -10px;
  }
}
.product-bundle-item:first-child .product-bundle-divider:after {
  display: none;
}
.product-bundle-item:last-child .product-bundle-divider:after {
  content: "=";
}
.product-bundle-item .product-variants {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.product-bundle-item .product-variants legend {
  font-size: 0.9rem;
  opacity: 0.5;
}
.product-bundle-item .details {
  padding: 10px 10px 20px;
  text-align: center;
}
.product-bundle-item .product-sku {
  margin: 0.6em 0;
  font-family: "Courier New", Courier, monospace;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}
.product-bundle-item .short-description,
.product-bundle-item .product-sku,
.product-bundle-item .original-price {
  opacity: 0.5;
  font-size: 0.8rem;
  line-height: 1.2em;
}
.product-bundle-item .original-price {
  line-height: 1em;
}

.product-bundle .quantity {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-bundle .decrease-quantity-action,
.product-bundle .increase-quantity-action {
  background-color: transparent;
  border: 1px solid #6f6f6f;
  color: #333333;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  font-size: 13px;
  position: relative;
}
.product-bundle .decrease-quantity-action span,
.product-bundle .increase-quantity-action span {
  position: absolute;
  display: block;
  width: 100%;
  top: 4px;
}
.product-bundle .decrease-quantity-action span {
  margin-top: -1px;
}
.product-bundle .quantity-count {
  padding-left: 8px;
  padding-right: 8px;
}

.product-bundle-cta-content {
  background-color: white;
  width: 100%;
  padding: 70px 10px 10px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.product-bundle-cta-text {
  width: 100%;
  line-height: 1.1em;
}

.bundled-product-quantity-info {
  position: absolute;
  overflow: hidden;
  top: -15px;
  left: 50%;
  margin-left: -15px;
  font-size: 1rem;
  background-color: #000;
  font-weight: 300;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 90;
}
.bundled-product-quantity-info:before {
  content: "×";
  padding-right: 0.1rem;
  padding-top: 0.1rem;
  font-size: 0.8rem;
}

.bundle-price-save-amount {
  font-size: 0.8rem;
  opacity: 0.5;
  line-height: 1rem;
  margin-top: 0.5em;
}

.product-bundle .save-percent-info {
  position: absolute;
  top: 50px;
  left: 20px;
  background-color: rgb(33, 33, 33);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
}
.product-bundle .save-percent-info > span {
  text-shadow: none;
  color: white;
  display: block;
}
.product-bundle .save-percent-info .label {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  margin-top: 12px;
}
.product-bundle .save-percent-info .percentage {
  white-space: nowrap;
  font-size: 26px;
  line-height: 20px;
  font-weight: 300;
  margin-top: 4px;
}
.product-bundle .save-percent-info .percentage .value {
  padding-left: 8px;
}
.product-bundle .save-percent-info .percentage .unit {
  font-size: 14px;
  position: relative;
  top: -7px;
  left: -6px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Category
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#product-list-page .price .currency {
  padding-left: 3px;
}
#product-list-page .description p {
  color: #777777;
}

.gallery-header {
  margin: 30px 0 15px;
}

.gallery-header .pagination {
  float: left;
}

.pagination {
  width: 200px;
}
.pagination p {
  float: right;
  width: 142px;
}
.pagination p span {
  display: none;
}
.pagination.single-page p {
  float: left;
  padding-left: 0;
}
.pagination ul {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
  float: left;
}
.pagination ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.pagination ul:before, .pagination ul:after {
  content: "";
  display: table;
}
.pagination ul:after {
  clear: both;
}
.pagination ul li {
  float: left;
}
.pagination ul a {
  display: block;
  float: left;
}
.pagination ul img {
  display: block;
}
.pagination li {
  display: none;
  float: left;
}
.pagination li.previous, .pagination li.next {
  display: block;
}
.pagination p, .pagination a, .pagination ul span {
  display: block;
  line-height: 20px;
  padding: 4px 10px;
  font-size: 14px;
  margin: 0;
  transition: background-color 0.2s linear;
}
.pagination .previous a, .pagination .previous span,
.pagination .next a,
.pagination .next span {
  width: 26px;
  height: 100%;
  color: white;
  line-height: 19px;
}
.pagination .previous > *,
.pagination .next > * {
  text-indent: -9999px;
  position: relative;
}
.pagination .previous > *:after,
.pagination .next > *:after {
  display: block;
  text-indent: 0;
  position: absolute;
  text-align: center;
  top: 3px;
  left: 8px;
  font-size: 20px;
}
.pagination .previous > *:after {
  content: "\f104";
}
.pagination .next > *:after {
  content: "\f105";
  left: 10px;
}
.pagination .next {
  margin-left: 4px;
}
.pagination a {
  background-color: #000;
}
.pagination a:hover {
  text-decoration: none;
  background-color: #333333;
}
.pagination ul span {
  background-color: #d1d1d1;
}

.gallery-layout {
  float: right;
  float: right !important;
  width: auto !important;
}
@media only screen and (max-width: 767px) {
  .gallery-layout {
    display: none;
  }
}
.gallery-layout b, .gallery-layout span {
  display: none;
}
.gallery-layout a, .gallery-layout a:visited {
  display: block;
  width: 26px;
  height: 26px;
  float: left;
  margin-left: 6px;
  color: #d1d1d1;
  text-decoration: none !important;
  transition: color 0.2s linear;
  position: relative;
  text-align: center;
}
.gallery-layout a.current, .gallery-layout a:visited.current {
  cursor: default;
}
.gallery-layout a.current, .gallery-layout a:hover, .gallery-layout a:visited.current, .gallery-layout a:visited:hover {
  color: black;
}
.gallery-layout a:before, .gallery-layout a:visited:before {
  position: absolute;
  width: 26px;
  display: block;
  top: -6px;
  left: -5px;
  font-size: 37px;
}
.gallery-layout a[data-layout="4"], .gallery-layout a:visited[data-layout="4"] {
  margin-left: 0;
}
.gallery-layout a[data-layout="4"]:before, .gallery-layout a:visited[data-layout="4"]:before {
  content: "\e61a";
}
.gallery-layout a[data-layout="3"]:before, .gallery-layout a:visited[data-layout="3"]:before {
  content: "\e61b";
}
.gallery-layout a[data-layout="1"]:before, .gallery-layout a:visited[data-layout="1"]:before {
  content: "\e61c";
}

.sort-order {
  float: right;
  width: 30%;
  margin: 0 6px 0 0;
}
.sort-order .caption {
  display: none;
}
.sort-order .control {
  width: 120px;
  float: right;
}
.sort-order .control:after {
  top: 1px !important;
  font-size: 14px !important;
  line-height: 26px !important;
  width: 24px !important;
  height: 24px !important;
}
.sort-order .control select {
  font-size: 14px;
  min-height: 0;
  padding: 1px 22px 1px 4px;
  text-indent: 0;
}

/* Specific Payment method
 * */
.klarna-checkout-selected-payment-method .select-wrap .summary, .dibs-easy-selected-payment-method .select-wrap .summary {
  display: none;
}
.klarna-checkout-selected-payment-method .submit-wrap, .dibs-easy-selected-payment-method .submit-wrap {
  margin-top: 30px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Checkout
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#checkout-page #main-content {
  width: 100%;
  float: none;
  padding: 0;
}
#checkout-page label {
  font-weight: 400;
}

section h2,
.section h2 {
  margin: 0 0 5px;
}
section.cart,
.section.cart {
  margin-left: 0;
  margin-bottom: 30px;
}
section.cart h2,
.section.cart h2 {
  display: none;
}

aside,
.payment-methods,
.addresses,
.klarna-checkout-block {
  margin-bottom: 30px;
}

.addresses p {
  margin-bottom: 10px;
}

.custom-message:before, .custom-message:after {
  content: "";
  display: table;
}
.custom-message:after {
  clear: both;
}

.payment-shipping-methods .description {
  margin-top: 0.6em;
  color: #848484;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.payment-shipping-methods .description b {
  color: rgb(30, 30, 30);
}

#shipping-methods-checkout .button {
  background: rgba(0, 0, 0, 0.5);
}
#shipping-methods-checkout .button:not(.disabled):hover, #shipping-methods-checkout .button.active {
  background: rgb(0, 0, 0);
  font-weight: 600;
}
#shipping-methods-checkout h4 {
  margin-left: 10px;
}
#shipping-methods-checkout .shipping-method-type-selector {
  display: inline-block;
  width: 100%;
  padding: 0 10px;
  margin-top: 10px;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button {
  position: relative;
  padding: 12px;
  font-size: 0.9rem;
  transition: none;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button:nth-child(1) {
  float: left;
  width: 49.5%;
}
#shipping-methods-checkout .shipping-method-type-selector .shipping-method-type-button:nth-child(2) {
  float: right;
  width: 49.5%;
}
#shipping-methods-checkout .shipping-method-type-selector i {
  position: absolute;
  top: 8px;
  width: 0%;
  background-color: transparent;
  margin-left: 5px;
  padding: 0;
  font-size: 18px;
}
#shipping-methods-checkout .control {
  padding: 7px;
}
#shipping-methods-checkout .control .payment-shipping-method {
  margin-top: 10px;
}
#shipping-methods-checkout .control .payment-shipping-method h3 {
  margin-top: 10px;
  margin-left: 5px;
}
#shipping-methods-checkout .control .payment-shipping-method:first-child h3 {
  margin-top: 0;
}

.shipping-method-wrap .payment-shipping-method {
  background-color: #fff !important;
}

.klarna-checkout-v3-selected-payment-method .section h4 {
  margin-left: 5px;
}
.klarna-checkout-v3-selected-payment-method .section .k-loading {
  border: 1px solid gainsboro;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.klarna-checkout-v3-selected-payment-method .section .field-wrap {
  margin-bottom: 0;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .button {
  background: rgba(0, 0, 0, 0.5);
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .button:not(.disabled):hover, .klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .button.active {
  background: rgb(0, 0, 0);
  font-weight: 600;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .shipping-method-type-button {
  position: relative;
  border-radius: 0px;
  padding: 15px;
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricprecision;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  text-transform: none;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector i {
  position: absolute;
  top: 11px;
  background-color: transparent;
  margin-left: 5px;
  padding: 0;
  font-size: 18px;
  width: 0%;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .shipping-method-type-button:nth-child(1) {
  float: left;
  width: 49.5%;
}
.klarna-checkout-v3-selected-payment-method .shipping-method-type-selector .shipping-method-type-button:nth-child(2) {
  float: right;
  width: 49.5%;
}

p.toggle a {
  text-decoration: none;
}
p.toggle a:before {
  content: "\e616";
  padding-right: 6px;
  color: #787878;
}
p.toggle a:hover:before {
  color: #000;
}
p.toggle.disabled {
  opacity: 0.4;
}
p.toggle.disabled a:hover {
  cursor: default;
}
p.toggle.disabled a:hover:before {
  color: #787878;
}

#checkout-page .payment-shipping-methods .content,
#checkout-page .addresses .content,
#checkout-page aside,
#checkout-page #page-footer,
#checkout-page ul.checkout-cart .cart-item,
#checkout-page .summary .grand-total,
#checkout-page .klarna-checkout-block .content {
  background-color: white;
  padding: 17px 10px 10px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid rgb(220, 220, 220);
  border-top-color: rgb(230, 230, 230);
}

#checkout-page .payment-shipping-methods .content {
  padding: 0;
}

.db-cart-wrap {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .db-cart-wrap {
    margin: 0 0 10px;
    text-align: left;
  }
}

.two.group-items .db-cart-wrap {
  float: right;
}

.db-cart-message,
.toggle.discount-code {
  display: block;
  margin-top: 0.25em;
}

.discount-code-db-cart-wrap {
  line-height: 1em;
}

#discount-code-content .apply-discount-code.field-wrap {
  margin-bottom: 0;
}
#discount-code-content .apply-discount-code.field-wrap button {
  padding: 0px 0;
}

@media only screen and (max-width: 850px) {
  .discount-code-db-cart-wrap .db-cart-message {
    line-height: 1.4em;
  }
  .two.group-items .discount-code,
  .two.group-items .apply-discount-code {
    width: auto;
    float: none;
  }
}
.theme-checkout-layout-1 .discount-code-db-cart-wrap .db-cart-message,
.theme-checkout-layout-2 .discount-code-db-cart-wrap .db-cart-message {
  line-height: 1.4em;
}
.theme-checkout-layout-1 .two.group-items .discount-code,
.theme-checkout-layout-1 .two.group-items .apply-discount-code,
.theme-checkout-layout-2 .two.group-items .discount-code,
.theme-checkout-layout-2 .two.group-items .apply-discount-code {
  width: auto;
  float: none;
}

.checkout-cart .bundled-products,
.order-thanks-details-block .bundled-products {
  margin-top: 30px;
}
.checkout-cart .bundled-product,
.order-thanks-details-block .bundled-product {
  display: -webkit-flex;
  display: flex;
  margin-top: 5px;
  padding: 0;
}
.checkout-cart .bundled-product-image,
.order-thanks-details-block .bundled-product-image {
  width: 50px;
}
.checkout-cart .bundled-product-description,
.order-thanks-details-block .bundled-product-description {
  margin-left: 5px;
}
.checkout-cart .bundled-product-description h5,
.order-thanks-details-block .bundled-product-description h5 {
  font-size: 0.8rem;
  line-height: 1.1em;
  margin: 0;
  font-weight: 400;
}
.checkout-cart .bundled-product-description .variant:before,
.order-thanks-details-block .bundled-product-description .variant:before {
  content: ", " attr(data-title);
}
.checkout-cart .bundled-product-description p,
.order-thanks-details-block .bundled-product-description p {
  margin-top: 0.5em;
  font-size: 0.8rem;
  opacity: 0.5;
  line-height: 1.1em;
}
.checkout-cart .bundled-product-description .sku:before,
.order-thanks-details-block .bundled-product-description .sku:before {
  content: attr(data-title);
}
.checkout-cart .bundled-product-quantity,
.order-thanks-details-block .bundled-product-quantity {
  margin-left: auto;
  font-size: 0.8rem;
  line-height: 1.1em;
  padding-left: 0.5em;
}
.checkout-cart .bundled-product-quantity .unit,
.order-thanks-details-block .bundled-product-quantity .unit {
  text-transform: uppercase;
  font-size: 0.5em;
  text-transform: uppercase;
  padding-left: 0.15em;
}
.checkout-cart .bundled-product-description,
.checkout-cart .bundled-product-quantity,
.order-thanks-details-block .bundled-product-description,
.order-thanks-details-block .bundled-product-quantity {
  color: #000;
}

.summary {
  margin: 10px 0 10px;
}
.summary .price,
.summary .weight {
  font-size: 12.8px;
  font-size: 0.8rem;
  font-weight: 300;
}
.summary .price .currency,
.summary .price .unit,
.summary .weight .currency,
.summary .weight .unit {
  font-size: 0.8em;
}
.summary p {
  margin: 0;
  padding: 0 10px;
  line-height: 1em;
  line-height: 1rem;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.summary p.left-for-free-shipping, .summary p.account {
  margin-top: 10px;
}
.summary p.grand-total {
  margin-top: 10px;
}
.summary p.grand-total .price {
  font-size: 19.2px;
  font-size: 1.2rem;
}
.summary p.grand-total .price .currency {
  font-size: 0.5em;
}

.checkout-cart button,
.import-cart button {
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  color: rgb(30, 30, 30);
  background-color: transparent !important;
  color: #787878;
}
.checkout-cart button:hover,
.import-cart button:hover {
  color: black;
}
.checkout-cart button span,
.import-cart button span {
  display: none;
}
.checkout-cart .increase-quantity-action:after,
.import-cart .increase-quantity-action:after {
  content: "\e616";
}
.checkout-cart .decrease-quantity-action:after,
.import-cart .decrease-quantity-action:after {
  content: "\e60e";
}
.checkout-cart .cart-head,
.import-cart .cart-head {
  padding: 0 10px;
}
@media only screen and (max-width: 767px) {
  .checkout-cart .cart-head,
  .import-cart .cart-head {
    display: none;
  }
}
.checkout-cart .cart-head ul,
.import-cart .cart-head ul {
  margin-top: 10px;
}
.checkout-cart .cart-head li,
.import-cart .cart-head li {
  font-size: 12.8px;
  font-size: 0.8rem;
  line-height: 1em;
  margin: 0 0 5px 0;
}
.checkout-cart .cart-item,
.import-cart .cart-item {
  margin-bottom: 10px;
}
.checkout-cart .cart-item .unit-price,
.checkout-cart .cart-item .price,
.checkout-cart .cart-item .quantity,
.import-cart .cart-item .unit-price,
.import-cart .cart-item .price,
.import-cart .cart-item .quantity {
  font-size: 19.2px;
  font-size: 1.2rem;
}
.checkout-cart .cart-item .weight,
.checkout-cart .cart-item .weight .unit,
.import-cart .cart-item .weight,
.import-cart .cart-item .weight .unit {
  font-size: 12.8px;
  font-size: 0.8rem;
}
.checkout-cart .cart-item .weight .unit,
.import-cart .cart-item .weight .unit {
  text-transform: none;
}
.checkout-cart .cart-item h3, .checkout-cart .cart-item h4,
.import-cart .cart-item h3,
.import-cart .cart-item h4 {
  margin: 0;
  padding-right: 16px;
}
.checkout-cart .cart-item h3,
.import-cart .cart-item h3 {
  font-size: 16px;
  font-size: 1rem;
}
.checkout-cart .cart-item h4,
.import-cart .cart-item h4 {
  margin-top: 5px;
  color: rgb(170, 170, 170);
  font-size: 12.8px;
  font-size: 0.8rem;
}
.checkout-cart .cart-item .title,
.import-cart .cart-item .title {
  padding-left: 10px;
}
@media only screen and (max-width: 767px) {
  .checkout-cart .cart-item .title,
  .import-cart .cart-item .title {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .checkout-cart .cart-item .unit-price,
  .import-cart .cart-item .unit-price {
    display: none;
  }
}
.checkout-cart .cart-item .unit-price,
.checkout-cart .cart-item .total-price,
.import-cart .cart-item .unit-price,
.import-cart .cart-item .total-price {
  line-height: 3rem;
}
.checkout-cart .cart-item .remove-item-action,
.checkout-cart .cart-item .remove-item,
.import-cart .cart-item .remove-item-action,
.import-cart .cart-item .remove-item {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 4px;
  top: 5px;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.checkout-cart .cart-item .quantity button,
.import-cart .cart-item .quantity button {
  font-size: 22.4px;
  font-size: 1.4rem;
  line-height: 3.8rem !important;
  width: 1em;
}
.checkout-cart .cart-item .quantity .quantity-field,
.import-cart .cart-item .quantity .quantity-field {
  margin-top: 8px;
}
@media only screen and (max-width: 768px) {
  .checkout-cart .cart-item .quantity .quantity-field,
  .import-cart .cart-item .quantity .quantity-field {
    display: none;
  }
}
.checkout-cart .cart-item .quantity p,
.checkout-cart .cart-item .quantity button,
.import-cart .cart-item .quantity p,
.import-cart .cart-item .quantity button {
  display: none;
}
@media only screen and (max-width: 768px) {
  .checkout-cart .cart-item .quantity p,
  .checkout-cart .cart-item .quantity button,
  .import-cart .cart-item .quantity p,
  .import-cart .cart-item .quantity button {
    display: block;
  }
}
.checkout-cart .cart-item .quantity p,
.import-cart .cart-item .quantity p {
  min-width: 45px;
  line-height: 3.5rem;
}
@media only screen and (max-width: 479px) {
  .checkout-cart .cart-item .quantity.quantity, .checkout-cart .cart-item .quantity.total-price,
  .import-cart .cart-item .quantity.quantity,
  .import-cart .cart-item .quantity.total-price {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .checkout-cart .cart-item .price-wrap,
  .checkout-cart .cart-item .description,
  .import-cart .cart-item .price-wrap,
  .import-cart .cart-item .description {
    width: 100%;
    float: none;
  }
}

.product-upsell-wrap .related-products .add-to-cart,
.product-upsell-wrap .related-products .info {
  opacity: 1;
  right: 0;
  width: auto;
  padding: 0px 20px !important;
}
.product-upsell-wrap .related-products .add-to-cart:before,
.product-upsell-wrap .related-products .info:before {
  display: none;
}
.product-upsell-wrap .related-products .add-to-cart span,
.product-upsell-wrap .related-products .info span {
  padding: 0;
  position: static;
  top: auto;
  right: auto;
}
.product-upsell-wrap .related-products .add-to-cart span:after,
.product-upsell-wrap .related-products .info span:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  #main-content .product-upsell-wrap .gallery-layout-1 li:after,
  #main-content .product-upsell-wrap .gallery-layout-3 li:after,
  #main-content .product-upsell-wrap .gallery-layout-4 li:after {
    display: none;
  }
  #main-content .product-upsell-wrap .gallery-layout-1 .product-info,
  #main-content .product-upsell-wrap .gallery-layout-3 .product-info,
  #main-content .product-upsell-wrap .gallery-layout-4 .product-info {
    padding-right: 85px;
  }
  #main-content .product-upsell-wrap .gallery-layout-1 .description h3,
  #main-content .product-upsell-wrap .gallery-layout-3 .description h3,
  #main-content .product-upsell-wrap .gallery-layout-4 .description h3 {
    font-size: 12.8px;
    font-size: 0.8rem;
  }
  #main-content .product-upsell-wrap .gallery-layout-1 .product-offer,
  #main-content .product-upsell-wrap .gallery-layout-3 .product-offer,
  #main-content .product-upsell-wrap .gallery-layout-4 .product-offer {
    display: block;
    position: static;
    top: auto;
    right: auto;
  }
  #main-content .product-upsell-wrap .gallery-layout-1 .add-to-cart,
  #main-content .product-upsell-wrap .gallery-layout-1 .info,
  #main-content .product-upsell-wrap .gallery-layout-3 .add-to-cart,
  #main-content .product-upsell-wrap .gallery-layout-3 .info,
  #main-content .product-upsell-wrap .gallery-layout-4 .add-to-cart,
  #main-content .product-upsell-wrap .gallery-layout-4 .info {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.theme-checkout-layout-2 .product-upsell-wrap .gallery-layout-3 li {
  width: 48%;
}
.theme-checkout-layout-2 .product-upsell-wrap .gallery-layout-4 li {
  width: 31.333333%;
}

.product-upsell-wrap .has-product-labels .add-to-cart,
.product-upsell-wrap .has-product-labels .info {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .product-upsell-wrap .has-product-labels .add-to-cart,
  .product-upsell-wrap .has-product-labels .info {
    opacity: 0;
  }
}

.payment-shipping-methods .control > li {
  padding: 10px;
  border-top: 1px solid rgb(230, 230, 230);
  transition: background-color 0.5s linear, box-shadow linear 0.2s;
  background-color: rgb(248, 248, 248);
  min-height: 50px;
}
.payment-shipping-methods .control > li:first-child {
  border-style: none;
}
.payment-shipping-methods .control > li h3 {
  font-size: 16px;
  font-size: 1rem;
  color: #5e5e5e;
  line-height: 13px;
  min-height: 35px;
}
.payment-shipping-methods .control > li h3 input {
  margin: 0 15px 0 0;
}
.payment-shipping-methods .control > li h3 label {
  font-size: 16px;
  font-size: 1rem;
}
.payment-shipping-methods .control > li h3 label i {
  font-style: normal;
  font-weight: 300;
  font-size: 11.2px;
  font-size: 0.7rem;
  display: block;
  line-height: 1.6em;
  color: #b7b7b7;
  margin-top: 4px;
}
.payment-shipping-methods .control > li .price {
  font-size: 16px;
  font-size: 1rem;
}
.payment-shipping-methods .control > li .price:before {
  content: "+";
  padding: 0 3px 0 5px;
}
.payment-shipping-methods .control > li .price b {
  font-weight: 300;
}
.payment-shipping-methods .control > li .price .amount {
  font-size: 16px;
  font-size: 1rem;
}
.payment-shipping-methods .control > li .price .currency {
  font-size: 11.2px;
  font-size: 0.7rem;
  font-weight: 300;
}
.payment-shipping-methods .control > li.checked {
  background-color: rgb(254, 254, 254);
}
.payment-shipping-methods .control > li.checked h3 {
  color: rgb(30, 30, 30);
  font-weight: 400;
}
.payment-shipping-methods .control > li.checked .caption {
  font-weight: 600;
}
.payment-shipping-methods .control > li.checked .price b {
  font-weight: 600;
}
.payment-shipping-methods .control > li.checked p {
  margin-right: 110px;
}

.payment-method-content {
  margin-left: 26px;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.payment-method-content h4 {
  font-size: 14.4px;
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-weight: 400;
}

.payment-method-options label {
  font-weight: 300;
  padding-left: 2px;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.payment-method-options span {
  position: relative;
  padding-right: 20px;
}

.payment-methods .checked .payment-method-options span.selected:after {
  content: "";
  font: 0/0 a;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
  border: 8px solid;
  border-color: transparent #F2F2F2 transparent transparent;
}
.payment-methods .checked .selected-option-info {
  background-color: #F2F2F2;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.payment-methods .checked .selected-option-info b {
  font-weight: 300;
}

button[type=submit] {
  position: relative;
}

.unit-price .amount,
.quantity-field {
  font-weight: 300 !important;
}

.total-price .amount,
.summary .grand-total .amount {
  font-weight: 600;
}

.grand-total small {
  padding-left: 0.3em;
}

#checkout-page .field-wrap.text-type .caption, #checkout-page .field-wrap.email-type .caption, #checkout-page .field-wrap.password-type .caption, #checkout-page .field-wrap.tel-type .caption, #checkout-page .field-wrap.number-type .caption, #checkout-page .field-wrap.select-type .caption, #checkout-page .field-wrap.customer-type .caption, #checkout-page .field-wrap.textarea-type .caption, #checkout-page .field-wrap.account-type .caption {
  display: none;
}
#checkout-page .field-wrap.checkbox-type abbr {
  display: none;
}
#checkout-page fieldset legend,
#checkout-page .payment-shipping .description {
  display: none;
}
#checkout-page .customers-checkout-comment-wrap.show {
  display: block;
}

/* Two & three cloumn layout
 * */
#checkout-page.theme-checkout-layout-1 .cart h2, #checkout-page.theme-checkout-layout-2 .cart h2 {
  display: block;
}
#checkout-page.theme-checkout-layout-1 .cart .summary,
#checkout-page.theme-checkout-layout-1 .cart-head, #checkout-page.theme-checkout-layout-2 .cart .summary,
#checkout-page.theme-checkout-layout-2 .cart-head {
  display: none;
}
#checkout-page.theme-checkout-layout-1 .cart, #checkout-page.theme-checkout-layout-2 .cart {
  margin-bottom: 30px;
}
#checkout-page.theme-checkout-layout-1 .quantity-field, #checkout-page.theme-checkout-layout-2 .quantity-field {
  display: none;
}
#checkout-page.theme-checkout-layout-1 .cart-item .quantity p, #checkout-page.theme-checkout-layout-1 .cart-item .quantity button, #checkout-page.theme-checkout-layout-2 .cart-item .quantity p, #checkout-page.theme-checkout-layout-2 .cart-item .quantity button {
  display: block;
}
#checkout-page.theme-checkout-layout-1 .checkout-cart .quantity, #checkout-page.theme-checkout-layout-2 .checkout-cart .quantity {
  width: 53%;
}
#checkout-page.theme-checkout-layout-1 .checkout-cart .total-price, #checkout-page.theme-checkout-layout-2 .checkout-cart .total-price {
  width: 47%;
}
#checkout-page.theme-checkout-layout-1 .checkout-cart .unit-price, #checkout-page.theme-checkout-layout-2 .checkout-cart .unit-price {
  display: none;
}
#checkout-page.theme-checkout-layout-1 .checkout-cart .cart-item .total-price, #checkout-page.theme-checkout-layout-2 .checkout-cart .cart-item .total-price {
  line-height: 3.5rem;
}

/* Two cloumn layout
 * */
#checkout-page.theme-checkout-layout-1 #content,
#checkout-page.theme-checkout-layout-1 #site-header .wrapper,
#checkout-page.theme-checkout-layout-1 #site-footer .wrapper {
  max-width: 1200px;
}
#checkout-page.theme-checkout-layout-1 .cart,
#checkout-page.theme-checkout-layout-1 .info-wrap {
  width: 48%;
}
#checkout-page.theme-checkout-layout-1 .info-wrap {
  margin-left: 4%;
}
#checkout-page.theme-checkout-layout-1 .cart,
#checkout-page.theme-checkout-layout-1 .info-wrap {
  float: left;
}
@media only screen and (max-width: 900px) {
  #checkout-page.theme-checkout-layout-1 .cart,
  #checkout-page.theme-checkout-layout-1 .info-wrap {
    width: auto;
    float: none;
  }
  #checkout-page.theme-checkout-layout-1 .info-wrap {
    margin-left: 0;
  }
  #checkout-page.theme-checkout-layout-1 .cart .summary {
    display: block;
  }
}

/* Three column layout
 * */
#checkout-page.theme-checkout-layout-2 #content,
#checkout-page.theme-checkout-layout-2 #site-header .wrapper,
#checkout-page.theme-checkout-layout-2 #site-footer .wrapper {
  max-width: 1400px;
}
#checkout-page.theme-checkout-layout-2 .checkout-cart .cart-item .remove-item-action {
  right: 2px;
  top: 2px;
}
#checkout-page.theme-checkout-layout-2 .cart,
#checkout-page.theme-checkout-layout-2 .info-wrap {
  float: left;
}
#checkout-page.theme-checkout-layout-2 .info-wrap {
  margin-left: 2%;
}
#checkout-page.theme-checkout-layout-2 .cart {
  width: 33.666666%;
}
#checkout-page.theme-checkout-layout-2 .info-wrap {
  width: 64.333333%;
}
#checkout-page.theme-checkout-layout-2 .addresses,
#checkout-page.theme-checkout-layout-2 .select-wrap {
  float: left;
  width: 48.333333%;
}
#checkout-page.theme-checkout-layout-2 .addresses .content,
#checkout-page.theme-checkout-layout-2 .checkout-cart .cart-item,
#checkout-page.theme-checkout-layout-2 .payment-shipping-methods .content,
#checkout-page.theme-checkout-layout-2 .klarna-checkout-block .content {
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}
#checkout-page.theme-checkout-layout-2 .payment-shipping-methods .content {
  padding-left: 0;
  padding-right: 0;
}
#checkout-page.theme-checkout-layout-2 .select-wrap {
  margin-left: 3.1%;
}
@media only screen and (max-width: 1200px) {
  #checkout-page.theme-checkout-layout-2 .addresses,
  #checkout-page.theme-checkout-layout-2 .select-wrap {
    width: auto;
    float: none;
  }
  #checkout-page.theme-checkout-layout-2 .select-wrap {
    margin-left: 0;
  }
  #checkout-page.theme-checkout-layout-2 .cart,
  #checkout-page.theme-checkout-layout-2 .info-wrap {
    width: 48%;
  }
}
@media only screen and (max-width: 900px) {
  #checkout-page.theme-checkout-layout-2 .cart,
  #checkout-page.theme-checkout-layout-2 .info-wrap,
  #checkout-page.theme-checkout-layout-2 .select-wrap {
    width: auto;
    float: none;
    margin-left: 0;
  }
  #checkout-page.theme-checkout-layout-2 .cart .summary {
    display: block;
  }
}

.select-list-adresses .toggle-favorite-address,
.select-list-adresses .icon-selected-address {
  position: absolute;
  display: block;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.select-list-adresses .icon-selected-address {
  display: none;
}
.select-list-adresses .select-list-option-selected .icon-selected-address {
  display: -webkit-flex;
  display: flex;
}
.select-list-adresses .select-list-option-selected .icon-selected-address:before {
  content: "\e909";
}
.select-list-adresses .toggle-favorite-address {
  top: 4px;
  right: 10px;
  font-size: 20px;
  width: 30px;
  height: 30px;
}
.select-list-adresses .icon-selected-address {
  top: 11px;
  left: 10px;
  font-size: 14px;
  width: 15px;
  height: 15px;
}
.select-list-adresses .favorite .toggle-favorite-address:before {
  content: "\f005";
}
.select-list-adresses .select-list-option-extended {
  display: none;
}
.select-list-adresses .toggle-favorite-address:before {
  content: "\e908";
}
.select-list-adresses .select-list-option {
  position: relative;
  padding-left: 30px;
  padding-right: 50px;
}
.select-list-adresses .select-list-option-text {
  display: block;
}
.select-list-adresses.select-list-show-extended-list-options .select-list-option-extended {
  display: block;
}
.select-list-adresses.select-list-show-extended-list-options .show-extended-list-options {
  display: none;
}

.desktop .select-list-adresses .favorite .toggle-favorite-address:hover {
  cursor: pointer;
}
.desktop .select-list-adresses .favorite .toggle-favorite-address:hover:before {
  content: "\e908";
}
.desktop .select-list-adresses .non-favorite .toggle-favorite-address:hover {
  cursor: pointer;
}
.desktop .select-list-adresses .non-favorite .toggle-favorite-address:hover:before {
  content: "\f005";
}

.touch .select-list-adresses .select-list-option {
  padding-left: 38px;
}
.touch .select-list-adresses .icon-selected-address {
  font-size: 16px;
  top: 4px;
  left: 8px;
  width: 30px;
  height: 30px;
}
.touch .icon-selected-address {
  display: -webkit-flex;
  display: flex;
}
.touch .icon-selected-address:before {
  content: "\e90a";
}
.touch .select-list-option-selected .icon-selected-address:before {
  content: "\e90b";
}

.product-items {
  margin-top: 0.5em;
}

.product-item {
  display: grid;
  grid-template-columns: 100px auto 90px 90px 90px;
  grid-gap: 10px;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}
@media only screen and (max-width: 700px) {
  .product-item {
    grid-template-columns: 100px auto auto auto;
  }
}
.product-item .price,
.product-item .quantity {
  font-size: 1.2rem;
}

.product-image {
  text-align: center;
  margin-left: -10px;
}

.product-items-header {
  background-color: transparent;
  padding: 0 10px;
  line-height: 1.2em;
  opacity: 0.6;
  margin-bottom: 5px;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.product-items-header .product-image {
  text-align: left;
}
@media only screen and (max-width: 700px) {
  .product-items-header {
    display: none;
  }
}

.product-title a {
  color: rgb(30, 30, 30);
}
.product-title h3 {
  margin-top: 0;
  font-size: 1.1rem;
  line-height: 1.2em;
}
.product-title p {
  margin-top: 0;
  opacity: 0.4;
  line-height: 1.2em;
}
.product-title .weight:before,
.product-title .sku:before {
  content: ", " attr(data-title);
  padding-right: 4px;
}
.product-title .weight:first-of-type:before,
.product-title .sku:first-of-type:before {
  content: attr(data-title);
}
.product-title .sku,
.product-title .weight,
.product-title .weight .unit {
  font-size: 0.8rem;
}
@media only screen and (max-width: 700px) {
  .product-title {
    grid-column: 2/5;
  }
}

.desktop .product-title a:hover {
  text-decoration: none;
}

.product-unit-price .unit {
  display: none;
}
@media only screen and (max-width: 700px) {
  .product-unit-price {
    grid-column: 1/2;
  }
}

.product-unit-price,
.product-quantity,
.product-total-price {
  text-align: right;
}

@media only screen and (max-width: 700px) {
  .product-total-price {
    grid-column: 3/5;
  }
}

.order-summary-items {
  border-bottom: 1px dotted silver;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.order-summary-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  line-height: 1rem;
  opacity: 0.6;
}

.order-summary-item .caption,
.order-summary-item .weight,
.order-summary-item .price {
  font-size: 0.8rem;
}
.order-summary-item .caption .unit,
.order-summary-item .caption .currency,
.order-summary-item .weight .unit,
.order-summary-item .weight .currency,
.order-summary-item .price .unit,
.order-summary-item .price .currency {
  font-size: 0.8em;
}

.order-summary-grand-total {
  opacity: 1;
  line-height: 1.6em;
  text-transform: uppercase;
}
.order-summary-grand-total .caption,
.order-summary-grand-total .weight,
.order-summary-grand-total .price {
  font-size: 1rem;
}

.order-discount-code .caption:after {
  content: ":";
  padding-right: 4px;
}
.order-discount-code .code {
  font-weight: 600;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Terms
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.terms-page .tb-mobile-menu,
.terms-page #tb-container,
.terms-page #console {
  display: none;
}
.terms-page #page-container {
  margin: 30px 0 90px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Search Results
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.search-query:before {
  content: "“";
}
.search-query:after {
  content: "”";
}

.search-results-content {
  margin-top: 20px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Customer account
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.customer-info-block .field-wrap.text-type .caption, .customer-info-block .field-wrap.email-type .caption, .customer-info-block .field-wrap.password-type .caption, .customer-info-block .field-wrap.tel-type .caption, .customer-info-block .field-wrap.number-type .caption, .customer-info-block .field-wrap.select-type .caption, .customer-info-block .field-wrap.customer-type .caption, .customer-info-block .field-wrap.textarea-type .caption, .customer-info-block .field-wrap.account-type .caption {
  display: none;
}

.customer-content .pagination p {
  color: #848484;
}

.customer-content {
  border: 1px solid rgb(230, 230, 230);
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: white;
  margin: 0 0 20px;
  padding: 20px 20px 15px;
  min-height: 300px;
}
.customer-content table {
  width: 100%;
  border-bottom: 1px solid rgb(230, 230, 230);
  margin: 7px 0 10px;
}
.customer-content table th {
  font-weight: 300;
  text-align: left;
  color: #848484;
  font-size: 14.4px;
  font-size: 0.9rem;
}
.customer-content table td {
  background-color: white;
  border-top: 1px solid rgb(230, 230, 230);
  border-left: 1px dotted rgb(245, 240, 240);
  font-size: 14.4px;
  font-size: 0.9rem;
  line-height: 1.4em;
}
.customer-content table td, .customer-content table th {
  padding: 3px 1em 2px 3px;
}
.customer-content table td.quantity, .customer-content table td.vat-rate, .customer-content table td.unit-price, .customer-content table td.total-price, .customer-content table td.discount, .customer-content table th.quantity, .customer-content table th.vat-rate, .customer-content table th.unit-price, .customer-content table th.total-price, .customer-content table th.discount {
  text-align: right;
}
.customer-content table td:first-child, .customer-content table th:first-child {
  border-left-style: none;
}
.customer-content table td.total-price, .customer-content table th.total-price {
  padding-right: 0;
}
@media only screen and (max-width: 767px) {
  .customer-content table thead {
    display: none;
    visibility: hidden;
  }
  .customer-content table tbody, .customer-content table tr, .customer-content table th, .customer-content table td {
    border: 0;
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  .customer-content table tr {
    margin-bottom: 10px;
  }
  .customer-content table td, .customer-content table tr:first-child td {
    border-style: none;
    border-bottom: 1px dotted rgb(245, 240, 240);
    font-size: 12.8px;
    font-size: 0.8rem;
    text-align: right !important;
    padding-left: 6px;
    position: relative;
  }
  .customer-content table td:first-child, .customer-content table tr:first-child td:first-child {
    text-transform: uppercase;
    font-weight: 600;
    border-top: 1px solid rgb(230, 230, 230);
    font-size: 16px;
    font-size: 1rem;
    padding: 6px 1em 2px 0px;
    text-align: left !important;
    border-bottom-style: none;
  }
  .customer-content table td:first-child a, .customer-content table tr:first-child td:first-child a {
    text-decoration: underline;
  }
  .customer-content table td:first-child td:before, .customer-content table td:before, .customer-content table tr:first-child td:first-child td:before, .customer-content table tr:first-child td:before {
    content: attr(data-title) ": ";
    text-transform: none;
    font-size: 12.8px;
    font-size: 0.8rem;
    position: absolute;
    left: 0;
    color: rgb(30, 30, 30);
  }
  .customer-content table td.total-price, .customer-content table td.total-price:before, .customer-content table tr:first-child td.total-price, .customer-content table tr:first-child td.total-price:before {
    font-weight: 600;
    color: rgb(30, 30, 30);
    font-size: 14.4px;
    font-size: 0.9rem;
  }
  .customer-content table td:first-child:before, .customer-content table tr:first-child td:first-child:before {
    display: none;
  }
  .customer-content table td:empty, .customer-content table tr:first-child td:empty {
    display: none;
  }
}

#cart-product-import .status-message {
  line-height: 32px;
}

.order-document-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
.order-document-header h1 {
  margin: 0;
  line-height: 1.3em;
}
.order-document-header .logo img {
  max-height: 75px;
}

.order-document-title {
  font-size: 24px;
  font-size: 1.5rem;
}

.order-document-sub-title {
  font-size: 16px;
  font-size: 1rem;
  margin: 0;
  font-style: italic;
  color: rgb(230, 230, 230);
}

.customer-content .columns .column-left {
  float: left;
}
.customer-content .columns .column-right {
  float: right;
}
.customer-content .columns .column-left,
.customer-content .columns .column-right {
  width: 49%;
}
@media only screen and (max-width: 767px) {
  .customer-content .columns .column-left,
  .customer-content .columns .column-right {
    width: auto;
    float: none;
    margin-bottom: 20px;
  }
}

.order-document-addresses h3,
.order-document-order-info h3 {
  margin-top: 0;
  font-size: 12.8px;
  font-size: 0.8rem;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .order-document-addresses,
  .order-document-order-info {
    margin: 0 0 20px;
  }
}
.order-document-addresses ul,
.order-document-order-info ul {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
  margin-bottom: 1em;
}
.order-document-addresses ul > li,
.order-document-order-info ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}
.order-document-addresses .delivery-address,
.order-document-order-info .delivery-address {
  margin-top: 2em;
  margin-bottom: 1em;
}
.order-document-addresses .order-document-reference,
.order-document-order-info .order-document-reference {
  margin-top: 1em;
}
.order-document-addresses .order-document-reference span,
.order-document-order-info .order-document-reference span {
  display: block;
}
.order-document-addresses .order-document-reference .reference-label,
.order-document-order-info .order-document-reference .reference-label {
  font-style: italic;
}

.order-document-addresses ul,
.order-document-order-info ul,
.customer-content table td,
.order-document-dialogue {
  font-size: 12.8px;
  font-size: 0.8rem;
  line-height: 1.4em;
}

.customer-content .order-document-products {
  margin-top: 40px;
}
.customer-content .order-document-products .quantity {
  font-size: 16px;
  font-size: 1rem;
}

.order-documents {
  font-size: 12.8px;
  font-size: 0.8rem;
}

.order-document-dialogue {
  margin: 20px 0;
  padding: 10px;
  border: 1px dashed rgb(230, 230, 230);
}

.order-document-summary {
  text-align: right;
  padding: 10px 0;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.order-document-summary p {
  position: relative;
  padding-right: 100px;
  margin: 0;
  line-height: 1.3em;
}
.order-document-summary caption {
  font-style: italic;
}
.order-document-summary .price {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.order-document-summary .order-document-grand-total {
  margin: 0.6em 0 0;
  font-weight: 600;
}

.order-document-footer {
  margin-top: 40px;
  border-top: 1px solid rgb(230, 230, 230);
  padding-top: 10px;
}
.order-document-footer b {
  font-weight: normal;
}
.order-document-footer .column {
  float: left;
  width: 33.3333%;
  padding: 0 10px;
  margin-top: 0;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 1.4em;
}
@media only screen and (max-width: 767px) {
  .order-document-footer .column {
    width: auto;
    float: none;
    margin-bottom: 10px;
    padding: 0;
  }
}
.order-document-footer .column h4, .order-document-footer .column p {
  margin: 0;
  font-size: 11.2px;
  font-size: 0.7rem;
}
.order-document-footer .column:first-child {
  padding-left: 0;
}

.order-document-invoice-footer .payment-info,
.order-document-invoice-footer .payment-info b {
  font-weight: bold;
}

nav.customer-pages {
  margin: 20px 0 0 0;
  position: relative;
}
@media only screen and (max-width: 767px) {
  nav.customer-pages {
    padding-top: 20px;
  }
}
nav.customer-pages ul {
  list-style: none;
  margin: 20px 0 0 -1%;
  padding: 0;
}
nav.customer-pages ul li, nav.customer-pages ul a, nav.customer-pages ul span {
  display: block;
  float: left;
}
nav.customer-pages ul li {
  margin: 0 0 -1px 1%;
}
nav.customer-pages ul a, nav.customer-pages ul a:visited, nav.customer-pages ul span {
  font-size: 14.4px;
  font-size: 0.9rem;
  padding: 4px 1em;
  background-color: #919191;
  color: white !important;
  text-decoration: none;
  transition: background-color 0.2s ease;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
nav.customer-pages ul a:hover {
  background-color: black;
  color: white;
}
nav.customer-pages ul .current * {
  background-color: white;
  color: rgb(30, 30, 30) !important;
  border: 1px solid rgb(230, 230, 230);
  border-bottom-style: none;
}
nav.customer-pages ul .current *:hover {
  background-color: white;
  color: rgb(30, 30, 30) !important;
  cursor: default;
}
nav.customer-pages ul .logout {
  float: right;
}
nav.customer-pages ul .logout a {
  background-color: transparent;
  color: rgb(30, 30, 30) !important;
}
nav.customer-pages ul .logout a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  nav.customer-pages ul li {
    width: 48%;
    margin-bottom: 5px;
    float: left !important;
    text-align: center;
  }
  nav.customer-pages ul li a, nav.customer-pages ul li span {
    width: 100%;
    border: 1px solid rgb(230, 230, 230) !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  nav.customer-pages ul li.logout a {
    background-color: #919191;
    color: white;
  }
  nav.customer-pages ul li.logout a:hover {
    background-color: black;
    color: white;
    text-decoration: none;
  }
}

.customer-child-customers .child-customer-details td:first-child {
  padding: 10px;
  background-color: transparent;
}
.customer-child-customers .button {
  display: inline-block;
}

.customer-child-customers-table td:first-child {
  font-weight: inherit;
}
.customer-child-customers-table h4 {
  font-weight: bold;
  margin-top: 0;
}
.customer-child-customers-table a {
  display: inline;
}
.customer-child-customers-table .customer-orders-table th, .customer-child-customers-table .customer-orders-table td {
  padding-left: 0;
}
@media only screen and (max-width: 1000px) {
  .customer-child-customers-table .invoice-adress-column,
  .customer-child-customers-table .delivery-adress-column {
    display: none;
  }
}

.child-customer-details {
  background-color: #f7f7f7;
}
.child-customer-details .three.column-content .column {
  float: none;
  width: auto;
}
.child-customer-details td {
  padding-bottom: 10px;
}
.child-customer-details .column-content {
  padding-top: 10px;
  padding-right: 10px;
}
.child-customer-details .last-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.child-customer-details .last-column .button {
  margin-top: 1em;
}
@media only screen and (max-width: 650px) {
  .child-customer-details td:first-child {
    padding-left: 10px;
  }
}

.child-customer-details-list {
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-column-gap: 1em;
  grid-row-gap: 2px;
  -webkit-align-items: start;
          align-items: start;
  line-height: 1.2em;
  margin-bottom: 1em;
}
.child-customer-details-list br {
  display: none;
}
.child-customer-details-list .label {
  opacity: 0.6;
}

@media only screen and (max-width: 767px) {
  .customer-content table .child-customer-details td:first-child {
    font-size: 0.8em;
    font-weight: inherit;
    text-transform: none;
    padding-left: 10px;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Reset Password
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.password-reset .field-wrap .caption,
.password-recovery .field-wrap .caption {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Shop Closed
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#shop-closed-page #home {
  margin-top: 5%;
}
#shop-closed-page #content {
  margin-top: 12%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #shop-closed-page #content h1 {
    font-size: 25.6px;
    font-size: 1.6rem;
  }
  #shop-closed-page #content h2 {
    font-size: 19.2px;
    font-size: 1.2rem;
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PAGE - Swish payment
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
#swish-payment .price {
  font-size: 19.2px;
  font-size: 1.2rem;
}
#swish-payment .price .amount {
  font-weight: 600;
}
#swish-payment .price .currency {
  font-weight: 300;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * SOCIAL
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.gallery.ratings .details {
  min-height: 150px;
}
.gallery.ratings .details .description {
  min-height: 58px;
}
@media only screen and (max-width: 767px) {
  .gallery.ratings .details {
    min-height: 170px;
  }
}
.gallery.ratings .details.no-price {
  min-height: 72px;
}

.rating {
  font: 0/0 a;
  margin-top: 6px;
  text-align: center;
  min-height: 12px;
}
.rating span {
  display: none;
}
.rating:before, .rating:after {
  display: inline;
  font-size: 12px;
}
.rating:before {
  color: #ebbf3c;
}
.rating:after {
  color: #E0E0E0;
}

.rating-1:before {
  content: "\f005";
}
.rating-1:after {
  content: "\f005\f005\f005\f005";
}

.rating-2:before {
  content: "\f005\f005";
}
.rating-2:after {
  content: "\f005\f005\f005";
}

.rating-3:before {
  content: "\f005\f005\f005";
}
.rating-3:after {
  content: "\f005\f005";
}

.rating-4:before {
  content: "\f005\f005\f005\f005";
}
.rating-4:after {
  content: "\f005";
}

.rating-5:before {
  content: "\f005\f005\f005\f005\f005";
}
.rating-5:after {
  content: "";
}

.social-sharing {
  margin: 40px 0 20px;
}
.social-sharing h3 {
  margin: 0;
}
.social-sharing a {
  display: block;
  float: left;
  margin-left: 0px;
}
.social-sharing a:first-child {
  margin-left: 0;
}

.product-rating a, .product-rating a:hover {
  text-decoration: none;
}
.product-rating.review-count-0, .product-rating:before {
  display: none;
}
.product-rating .rating:before, .product-rating .rating:after {
  font-size: 24px;
}
.product-rating .info {
  display: block;
}
.product-rating .info.review-count-0, .product-rating .info:before {
  display: none;
}
.product-rating .count {
  display: none;
}
.product-rating .info,
.product-rating .add-new {
  display: block;
}
.product-rating .info:hover,
.product-rating .add-new:hover {
  text-decoration: underline;
}

#product-reviews .product-rating .title {
  float: left;
  padding-right: 10px;
}
#product-reviews .product-rating .info {
  display: block;
}
#product-reviews .product-rating .info {
  color: #9e9e9e;
  font-size: 12.8px;
  font-size: 0.8rem;
}
#product-reviews .product-rating .info:hover {
  text-decoration: none;
}

.review-ratings {
  margin-top: 10px;
}
.review-ratings .caption {
  color: #9e9e9e;
  font-size: 12.8px;
  font-size: 0.8rem;
  font-weight: 300;
}
.review-ratings .rating-selector input {
  display: none;
}
.review-ratings .rating-selector .caption {
  display: none;
}
.review-ratings .pending-review {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 300;
  line-height: 1.1em;
  padding: 3px 8px;
  background-color: #cccccc;
  color: white;
  font-size: 12.8px;
  font-size: 0.8rem;
}
@media only screen and (max-width: 500px) {
  .review-ratings li {
    padding-left: 10px;
  }
  .review-ratings li .rating,
  .review-ratings li .rating-score-name,
  .review-ratings li .rating-selector {
    position: static;
    top: auto;
    left: auto;
  }
  .review-ratings li .facebook-login-button,
  .review-ratings li a.button {
    float: none;
  }
  .review-ratings li a.button {
    width: 100%;
    margin-top: 10px;
    display: block;
    text-align: center;
  }
  .review-ratings li .rating-selector {
    width: auto;
  }
  .review-ratings li .rating-selector label {
    width: 26px;
    height: 26px;
  }
  .review-ratings li .rating-selector label:after {
    line-height: 26px;
    font-size: 26px;
  }
}

.review-rating,
.add-review-comment {
  position: relative;
  padding: 10px 10px 20px 130px;
  margin-bottom: 10px;
  background-color: white;
}
.review-rating form,
.add-review-comment form {
  margin: 0;
}
.review-rating .rating,
.review-rating .rating-selector,
.add-review-comment .rating,
.add-review-comment .rating-selector {
  position: absolute;
  top: 6px;
  left: 10px;
  width: 120px;
  text-align: left;
}
.review-rating .rating:before, .review-rating .rating:after,
.review-rating .rating-selector:before,
.review-rating .rating-selector:after,
.add-review-comment .rating:before,
.add-review-comment .rating:after,
.add-review-comment .rating-selector:before,
.add-review-comment .rating-selector:after {
  font-size: 18px;
}
.review-rating .rating-score-name,
.add-review-comment .rating-score-name {
  position: absolute;
  top: 30px;
  left: 10px;
  width: 120px;
}
.review-rating time,
.review-rating .rating-score-name,
.add-review-comment time,
.add-review-comment .rating-score-name {
  color: #9e9e9e;
  font-size: 12.8px;
  font-size: 0.8rem;
}
.review-rating .login-label,
.add-review-comment .login-label {
  font-size: 12.8px;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.add-review-comment {
  margin-bottom: 0;
}

.add-review-comment .rating-selector {
  top: 13px;
}

.add-review-comment .facebook-login-button {
  float: left;
}
.add-review-comment a.button {
  float: right;
  width: auto;
}

.review-author {
  font-weight: 600;
}

.review-content {
  padding-top: 10px;
}

.rating-selector .option {
  float: left;
}

.rating-selector .caption {
  line-height: 1.2em;
  margin-top: 4px;
}
.rating-selector label {
  display: block;
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  cursor: pointer;
  position: relative;
}
.rating-selector label:after {
  display: block;
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  color: #E0E0E0;
  content: "\f005";
  text-indent: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.rating-selector .rating-score-name {
  position: absolute;
  top: 17px;
  left: 0px;
  width: 105px;
}

.error.rating-error:before {
  content: "←";
}

.rating-selector .selected label:after {
  color: #ebbf3c;
}

.ratings.gallery-layout-1 .rating {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .ratings.gallery .details .description {
    min-height: 0;
  }
  .ratings.gallery .rating {
    text-align: left;
  }
}
@media only screen and (max-width: 479px) {
  .ratings.gallery .details {
    min-height: 0;
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * IMPORT CART
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.import-cart-wrap.no-items,
.import-file-wrap,
.import-file-options-wrap {
  display: none;
}

.import-cart {
  margin-top: 20px;
}
.import-cart .cart-item {
  padding: 22px 10px 10px 10px;
  border: 1px solid #dcdcdc;
  border-top-color: #e6e6e6;
}
@media only screen and (max-width: 768px) {
  .import-cart .unit-price {
    display: none;
  }
}
.import-cart .cart-head .unit-price {
  width: 21%;
}
.import-cart .cart-head .quantity {
  width: 13%;
}
.import-cart .cart-head .price {
  width: 14%;
}

.clear-import-items,
.cancel-preview-cart-import {
  background-color: #d1d1d1;
  padding-left: 40px;
}
.clear-import-items:before,
.cancel-preview-cart-import:before {
  content: "\e61d";
  padding-right: 5px;
  margin-top: -6px;
  font-size: 13px;
}

.cancel-preview-cart-import:before {
  display: none;
}

.import-file-wrap {
  border: 1px solid #dcdcdc;
  border-top-color: #e6e6e6;
  padding: 10px;
  margin-top: 20px;
}

.import-file-upload label {
  display: none;
}

.customer-cart-import .field-wrap {
  margin-top: 5px;
}

.preview-cart-import {
  margin-top: 40px;
}
.preview-cart-import .data-table {
  overflow-y: scroll;
}
.preview-cart-import label {
  display: none;
}
.preview-cart-import table {
  width: 100%;
  margin-top: 20px;
}
.preview-cart-import table td {
  padding: 0 4px;
}
.preview-cart-import table th {
  text-align: left;
  padding-right: 4px;
}
.preview-cart-import table tbody td {
  border: 1px dotted silver;
  background-color: white;
}

.import-cart-footer {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BACK IN STOCK
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.in-stock-notify-modal h3 {
  margin: 0 0 0.2em;
  line-height: 1.2em;
}
.in-stock-notify-modal h4 {
  margin: 0 0 0.6em;
  line-height: 1.3em;
}
.in-stock-notify-modal label {
  display: none;
}
@media only screen and (max-width: 800px) {
  .in-stock-notify-modal input, .in-stock-notify-modal button {
    font-size: 14.4px;
    font-size: 0.9rem;
  }
}

.sw-modal-window .main .in-stock-notify-modal p {
  line-height: 1.3em;
  margin-bottom: 0.6em;
  font-size: 12.8px;
  font-size: 0.8rem;
}

.customer-account-logged-in .in-stock-notify-modal .field-wrap {
  display: none;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * PRUDUCT FILTER
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.filter-header {
  float: right;
}

.filter-groups,
.has-product-filter .product-list {
  float: left;
}

.filter-groups {
  width: 20%;
}

.filter-header,
.has-product-filter .product-list {
  width: 78%;
  margin-left: 2%;
}

@media only screen and (max-width: 800px) {
  .filter-groups,
  .filter-header,
  .has-product-filter .product-list {
    float: none;
    width: auto;
    margin-left: 0;
  }
}
.filter-groups,
.filter-items,
.sort-order-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.active-filters-match {
  margin: 0;
}

.show-product-filter-loader .product-list-loading {
  position: relative;
}
.show-product-filter-loader .product-list-loading .loader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.show-product-filter-loader .product-list-loading .loader span {
  display: block;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -50px;
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid rgba(0, 0, 0, 0.8);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.show-product-filter-loader .product-list-loading .loader span:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sort-order-item label,
.filter-item label {
  text-shadow: none;
}

.filter-menu {
  margin-top: 10px;
  margin-left: -2%;
  display: -webkit-flex;
  display: flex;
}

.filter-menu-item {
  -webkit-flex: 1;
          flex: 1;
  margin-left: 2%;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.filter-menu-item:before {
  padding-right: 4px;
}

.toggle-active-filters:before {
  content: "\e904";
}

.toggle-sort-order:before {
  content: "\e600";
}

@media only screen and (min-width: 800px) {
  .filter-menu {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .filter-menu-item {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.has-active-filter-items .active-filters {
  margin-bottom: 10px;
  margin-left: -5px;
}
@media only screen and (max-width: 800px) {
  .has-active-filter-items .active-filters {
    margin-top: 15px;
  }
}

.remove-active-filter-action,
.clear-active-filters-action {
  float: left;
  margin-left: 5px;
  margin-bottom: 5px;
  width: auto;
  padding: 4px 14px;
  display: block;
  font-size: 14.4px;
  font-size: 0.9rem;
}

.has-active-filter-items #active-filters-count:before {
  content: "(";
}
.has-active-filter-items #active-filters-count:after {
  content: ")";
}

.remove-active-filter-action {
  color: white;
  background-color: black;
  text-transform: none;
  line-height: 24px;
  font-size: 12.8px;
  font-size: 0.8rem;
  position: relative;
  padding-right: 30px;
}
.remove-active-filter-action:after {
  content: "\e61d";
  line-height: 15px;
  font-size: 14px;
  position: absolute;
  margin-top: -7px;
  top: 50%;
  right: 10px;
}
@media only screen and (max-width: 800px) {
  .remove-active-filter-action {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.clear-active-filters-action {
  padding: 3px 5px;
  display: none;
}
@media only screen and (max-width: 800px) {
  .clear-active-filters-action {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.has-multiple-filter-items .clear-active-filters-action {
  display: block;
}

.filter-group {
  transition: padding 0.15s ease;
  margin-bottom: 3%;
}

.filter-group-closed {
  padding-bottom: 0;
}
.filter-group-closed .filter-group-title {
  margin: 0;
}
.filter-group-closed .filter-group-title:after {
  content: "\e900";
}
.filter-group-closed .filter-items {
  max-height: 0;
  overflow: hidden;
}
.filter-group-closed .filter-group-content {
  padding-top: 0;
  padding-bottom: 0;
}

.filter-group-count {
  display: none;
}

.filter-group-count-0 .filter-group-title,
.filter-group-count-0 .filter-item label {
  opacity: 0.5;
  cursor: default;
}
.filter-group-count-0 .filter-group-count {
  display: none;
}

.filter-group-title {
  padding: 10px 50px 10px 15px;
  margin: 0;
  position: relative;
  display: block;
  cursor: pointer;
  text-transform: none;
  border: 1px solid #E0DEDE;
  background-color: #fff;
  border-radius: 2px;
  font-size: 14.4px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-group-title:hover {
  text-decoration: none;
}
.filter-group-title:after {
  content: "\e901";
  position: absolute;
  font-size: 20px;
  top: 50%;
  right: 10px;
  margin-top: -11px;
}
@media only screen and (max-width: 800px) {
  .filter-group-title {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.filter-group-open .filter-group-title {
  color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
  background-color: #000;
}

.filter-group-content,
.sort-order-content {
  background-color: #fff;
  padding: 15px;
  position: relative;
  margin-top: -2px;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.filter-group-open .filter-group-content,
.sort-order-content {
  border: 1px solid #E0DEDE;
}

.sort-order-content {
  display: none;
  margin-top: 1%;
}

@media only screen and (max-width: 800px) {
  .filter-groups,
  .active-filters,
  .gallery-header {
    display: none;
  }
  .filter-group-storlek {
    display: block;
  }
  .filter-groups {
    margin-top: 1%;
  }
  .filter-group {
    margin-bottom: 1%;
  }
  .filter-menu-show-filters .filter-groups,
  .filter-menu-show-filters .active-filters {
    display: block;
  }
  .filter-menu-show-sort-order .sort-order-content {
    display: block;
  }
}
.filter-items {
  transition: max-height 0.1s ease;
  max-height: 5000px;
}

.filter-items,
.sort-order-items,
.filter-items label,
.sort-order-items label {
  font-size: 12.8px;
  font-size: 0.8rem;
}

.filter-item-type-checkbox {
  position: relative;
  padding-left: 20px;
  margin-bottom: 6px;
}
.filter-item-type-checkbox label {
  display: block;
  line-height: 1.4em;
}

.filter-type-checkbox {
  top: 1px;
  left: 0;
  position: absolute;
}

.see-more-filter-items,
.see-less-filter-items {
  display: none;
  white-space: nowrap;
}

.has-sidebar .filter-groups,
.has-sidebar .filter-header,
.has-sidebar .has-product-filter .product-list {
  float: none;
  width: 100%;
  margin-left: 0;
}
.has-sidebar .filter-groups {
  margin-left: -1%;
  margin-top: 10px;
}
.has-sidebar .filter-group-title {
  padding-top: 10px;
  padding-bottom: 10px;
}
.has-sidebar .filter-group {
  float: left;
  width: 19%;
  margin-left: 1%;
  margin-bottom: 1%;
  position: relative;
  padding-bottom: 0;
}
.has-sidebar .filter-group-closed {
  box-shadow: none;
}
.has-sidebar .filter-group-content,
.has-sidebar .sort-order-content {
  width: 100%;
}
.has-sidebar .filter-group-content {
  z-index: 9999;
  position: absolute;
  top: 40px;
  left: 0;
}
.has-sidebar .filter-item {
  padding-left: 0;
}
.has-sidebar .has-active-filter-items .active-filters {
  margin-bottom: 0;
}
@media only screen and (max-width: 800px) {
  .has-sidebar .filter-groups {
    margin-left: 0;
  }
  .has-sidebar .filter-group-content {
    position: static;
    top: auto;
    left: auto;
    box-shadow: none;
  }
  .has-sidebar .filter-group {
    float: none;
    width: 100%;
    margin-left: 0;
    box-shadow: none;
  }
}

@media only screen and (max-width: 800px) {
  .irs-slider {
    background-image: none;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 0px 1px rgba(0, 0, 0, 0.05), 0 0 0 1px #DEDBDB inset;
    border-radius: 50%;
    background: #F9F9F9;
    background: linear-gradient(to bottom, #F9F9F9 0%, #E4E4E4 60%);
  }
  .irs-slider:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    top: 9px;
    left: 50%;
    border-radius: 50%;
    background: linear-gradient(to bottom, #D8D8D8 21%, #FFFEFE 100%);
    box-shadow: 0 0 0 1px rgba(64, 63, 63, 0.1) inset;
  }
}
.product-filter .sort-order-items {
  margin-left: -1%;
}
.product-filter .filter-item,
.product-filter .sort-order-item {
  display: inline-block;
  margin-left: 1%;
  margin-bottom: 1%;
  position: relative;
  padding-left: 0;
}
.product-filter .see-more-filter-items,
.product-filter .see-less-filter-items {
  margin-left: 2%;
}
.product-filter label {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  padding: 2px 6px;
  background-color: white;
  box-shadow: 0 0 0 1px #CECECE;
  text-align: center;
  margin-bottom: 0.3em;
  max-width: 100%;
  word-break: break-word;
}
.product-filter .filter-item-type-range {
  display: block;
  margin: 0;
}
.product-filter .filter-item-type-range label {
  display: none;
}
.product-filter input:checked + label {
  background-color: #000;
  color: white;
}
.product-filter .filter-item-count {
  opacity: 0.5;
  padding-left: 3px;
}
.product-filter .filter-item-count:before {
  content: "[";
}
.product-filter .filter-item-count:after {
  content: "]";
}
.product-filter .filter-item-count-0 label {
  opacity: 0.5;
  cursor: default;
}
.product-filter input {
  display: none;
}
@media only screen and (max-width: 800px) {
  .product-filter label {
    padding: 12px;
  }
}

.select-list {
  margin-top: 10px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 10px;
}

.select-list-control {
  border: 1px solid lightgray;
  background-color: white;
}

.placeholder-text {
  color: #a0a0a0;
}

.select-list-label {
  display: none;
}

.select-list:before {
  content: "\f0dc";
  position: absolute;
  line-height: 36px;
  right: 0;
  top: -1px;
  width: 32px;
  height: 32px;
  text-align: center;
  color: silver;
  font-size: 1rem;
}

.select-list-control {
  position: relative;
}

.select-list-options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 1px;
  background-color: #eeeeee;
  z-index: 1000;
  display: none;
  font-size: 1rem;
  line-height: 1.2em;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.select-list-show-options .select-list-options {
  display: block;
}

.select-list-placeholder {
  padding: 3px 32px 3px 6px;
}

.select-list-option {
  padding: 10px;
  border-bottom: 1px solid silver;
}

.desktop .select-list-option-hover {
  background-color: #4a99fb;
  color: #fff;
}

.desktop .select-list-option-text:hover,
.desktop .select-list-control:hover {
  cursor: pointer;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * BROWSER SPECIFIC
 * - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
html.ie .field-wrap.select-type .control:after {
  display: none;
}
html.ie .sort-order .control select {
  padding-right: 0;
}

html.lt-ie9 ul.gallery figure .figure-content,
html.lt-ie9 .product-media figure .figure-content {
  text-align: center;
  font: 0/0 a;
  display: block;
}
html.lt-ie9 ul.gallery figure .img-wrap:before,
html.lt-ie9 .product-media figure .img-wrap:before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
html.lt-ie9 ul.gallery figure img,
html.lt-ie9 .product-media figure img {
  position: static;
  vertical-align: middle;
}

html.lt-ie9 ul.gallery .add-to-cart,
html.lt-ie9 ul.gallery .info {
  padding: 0 !important;
  right: 0px;
  left: auto;
  max-width: none !important;
  width: 100%;
}
html.lt-ie9 ul.gallery .add-to-cart:before,
html.lt-ie9 ul.gallery .info:before {
  display: none;
}
html.lt-ie9 ul.gallery .add-to-cart span,
html.lt-ie9 ul.gallery .info span {
  position: static;
  width: 100%;
  height: auto;
  top: auto;
  right: auto;
}
html.lt-ie9 ul.gallery .add-to-cart span:after,
html.lt-ie9 ul.gallery .info span:after {
  display: none;
}
html.lt-ie9 .slideshow-media .fotorama__html .caption {
  background: url(img/rgba-0-0-0-60.png);
}
html.lt-ie9 #site-header .cart-block .header .cart-icon:after,
html.lt-ie9 #site-header nav > ul > li > a:after {
  display: none;
}
html.lt-ie9 img {
  width: auto;
}
html.lt-ie9 #content,
html.lt-ie9 #site-header .wrapper,
html.lt-ie9 #site-footer .wrapper {
  width: 980px;
}
html.lt-ie9 ul.gallery > li > a {
  border: 1px solid rgb(220, 220, 220);
}
html.lt-ie9 ul.checkout-cart li.cart-item .remove-item-action {
  top: 2px;
  right: 5px;
}

html.firefox .sort-order .control select {
  padding-right: 0;
}
html.firefox input::-webkit-input-placeholder,
html.firefox textarea::-webkit-input-placeholder {
  color: rgb(120, 120, 120);
}
html.firefox input:-moz-placeholder,
html.firefox textarea:-moz-placeholder {
  color: rgb(120, 120, 120);
}
html.firefox input::-moz-placeholder,
html.firefox textarea::-moz-placeholder {
  color: rgb(120, 120, 120);
}
html.firefox input:-ms-input-placeholder,
html.firefox textarea:-ms-input-placeholder {
  color: rgb(120, 120, 120);
}

html.ie .pagination .previous > *:after,
html.firefox .pagination .previous > *:after,
html.mobile .pagination .previous > *:after {
  left: 9px;
}
html.ie .pagination .previous > *:after,
html.ie .pagination .next > *:after,
html.firefox .pagination .previous > *:after,
html.firefox .pagination .next > *:after,
html.mobile .pagination .previous > *:after,
html.mobile .pagination .next > *:after {
  top: 4px;
}

html.mobile .pagination .previous > *:after,
html.mobile .pagination .next > *:after {
  font-weight: 600;
}

html.touch *:hover {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
html.touch input:hover,
html.touch textarea:hover {
  -webkit-user-select: text;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * *	THEME - Customize
 * *	- - - - - - - - - - - - - - - - - - - - - - - - - - -
 * */
.theme-color-scheme-1 {
  background-color: white;
}
.theme-color-scheme-1 a {
  color: #1764a7;
}
.theme-color-scheme-1 a:visited {
  color: #1764a7;
}
.theme-color-scheme-1 a:hover {
  color: #1764a7;
}
.theme-color-scheme-1 a:active {
  color: #1764a7;
}
.theme-color-scheme-1 #home:hover, .theme-color-scheme-1 #home:visited:hover,
.theme-color-scheme-1 #site-header nav.category ul li a:hover,
.theme-color-scheme-1 .categories.gallery.names .category-info:hover h3 {
  color: #1764a7 !important;
}
.theme-color-scheme-1 #site-footer:after {
  border-top-color: white;
}
.theme-color-scheme-1 .gallery-layout a,
.theme-color-scheme-1 .gallery-layout a:visited {
  color: #f0f0f0;
}
.theme-color-scheme-1 .pagination ul span {
  background-color: #fdfdfd;
}
.theme-color-scheme-1 #site-header nav.custom ul li a,
.theme-color-scheme-1 #site-header .cart-block .header,
.theme-color-scheme-1 #menu:before {
  color: white;
}
.theme-color-scheme-1 #site-header .cart-block .header .cart-icon:after {
  background-color: white;
}
.theme-color-scheme-1 #site-header nav.custom ul li a:hover,
.theme-color-scheme-1 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-1 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-1 .clear-import-items,
.theme-color-scheme-1 .cancel-preview-cart-import {
  background-color: #e4e4e4;
}
.theme-color-scheme-1 h1,
.theme-color-scheme-1 h2,
.theme-color-scheme-1 h3,
.theme-color-scheme-1 h4,
.theme-color-scheme-1 .gallery-layout a.current,
.theme-color-scheme-1 .gallery-layout a:hover,
.theme-color-scheme-1 #site-header .cart-block .header .cart-icon:after {
  color: rgb(100, 100, 100);
}
.theme-color-scheme-1 #site-header:before,
.theme-color-scheme-1 #site-footer,
.theme-color-scheme-1 .button,
.theme-color-scheme-1 button,
.theme-color-scheme-1 input[type=button],
.theme-color-scheme-1 input[type=submit],
.theme-color-scheme-1 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-1 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-1 #content-sidebar .cart-block .header,
.theme-color-scheme-1 .pagination a {
  background-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 .button,
.theme-color-scheme-1 .button:hover,
.theme-color-scheme-1 .button:visited,
.theme-color-scheme-1 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-1 ul.gallery .info,
.theme-color-scheme-1 ul.gallery .add-to-cart {
  background-color: rgb(100, 100, 100) !important;
}
.theme-color-scheme-1 ul.gallery .info:hover,
.theme-color-scheme-1 ul.gallery .add-to-cart:hover {
  background-color: #7e7e7e !important;
}
.theme-color-scheme-1 .product-status:after {
  border-top-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 #site-header .search-block button:hover:before, .theme-color-scheme-1 #site-header .search-block button:focus:before,
.theme-color-scheme-1 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-1 #content-sidebar .search-block button:focus:before {
  color: rgb(100, 100, 100) !important;
}
.theme-color-scheme-1 .button:hover,
.theme-color-scheme-1 button:hover,
.theme-color-scheme-1 input[type=button]:hover,
.theme-color-scheme-1 input[type=submit]:hover,
.theme-color-scheme-1 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-1 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-1 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-1 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-1 ul.gallery a.info:hover,
.theme-color-scheme-1 button:focus,
.theme-color-scheme-1 input[type=button]:focus,
.theme-color-scheme-1 input[type=submit]:focus,
.theme-color-scheme-1 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-1 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-1 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-1 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-1 ul.gallery a.info:focus {
  background-color: #7e7e7e;
}
.theme-color-scheme-1 .sidr-close, .theme-color-scheme-1 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-1 #site-header .cart-block .header .cart-icon:after {
  border-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 ul.gallery button span:after,
.theme-color-scheme-1 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-1 ul.gallery .info:hover span:after {
  border-left-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 #site-header .cart-block .cart-content:after,
.theme-color-scheme-1 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-1 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 input[type=text], .theme-color-scheme-1 input[type=password], .theme-color-scheme-1 input[type=email], .theme-color-scheme-1 input[type=number], .theme-color-scheme-1 input[type=search], .theme-color-scheme-1 input[type=tel], .theme-color-scheme-1 input[type=time], .theme-color-scheme-1 input[type=url], .theme-color-scheme-1 input:focus {
  border-color: #979797 !important;
  box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.3) inset, 0px 0px 8px rgba(100, 100, 100, 0.3);
}
.theme-color-scheme-1 .add-to-cart-form.hover .product-status:after {
  border-top-color: #7e7e7e;
}
.theme-color-scheme-1 #content-sidebar nav ul li a:hover {
  color: #1764a7;
}
.theme-color-scheme-1 #site-header nav.custom ul li ul li a:hover {
  color: #1764a7 !important;
}
.theme-color-scheme-1 .add-to-cart:hover:before,
.theme-color-scheme-1 .info:hover:before {
  color: rgb(100, 100, 100);
}
.theme-color-scheme-1 .select-locale-button,
.theme-color-scheme-1 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-1 .autocomplete-suggestions {
  border-color: #979797;
  box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.3);
  background-color: white;
}
.theme-color-scheme-1 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-1 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #1764a7;
}
.theme-color-scheme-1 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #1764a7;
}

.theme-color-scheme-2 {
  background-color: #edead8;
}
.theme-color-scheme-2 a {
  color: #C27F08;
}
.theme-color-scheme-2 a:visited {
  color: #C27F08;
}
.theme-color-scheme-2 a:hover {
  color: #C27F08;
}
.theme-color-scheme-2 a:active {
  color: #C27F08;
}
.theme-color-scheme-2 #home:hover, .theme-color-scheme-2 #home:visited:hover,
.theme-color-scheme-2 #site-header nav.category ul li a:hover,
.theme-color-scheme-2 .categories.gallery.names .category-info:hover h3 {
  color: #C27F08 !important;
}
.theme-color-scheme-2 #site-footer:after {
  border-top-color: #edead8;
}
.theme-color-scheme-2 .gallery-layout a,
.theme-color-scheme-2 .gallery-layout a:visited {
  color: #cdc9ab;
}
.theme-color-scheme-2 .pagination ul span {
  background-color: #d7d3bb;
}
.theme-color-scheme-2 #site-header nav.custom ul li a,
.theme-color-scheme-2 #site-header .cart-block .header,
.theme-color-scheme-2 #menu:before {
  color: #edead8;
}
.theme-color-scheme-2 #site-header .cart-block .header .cart-icon:after {
  background-color: #edead8;
}
.theme-color-scheme-2 #site-header nav.custom ul li a:hover,
.theme-color-scheme-2 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-2 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-2 .clear-import-items,
.theme-color-scheme-2 .cancel-preview-cart-import {
  background-color: #c4bf9b;
}
.theme-color-scheme-2 h1,
.theme-color-scheme-2 h2,
.theme-color-scheme-2 h3,
.theme-color-scheme-2 h4,
.theme-color-scheme-2 .gallery-layout a.current,
.theme-color-scheme-2 .gallery-layout a:hover,
.theme-color-scheme-2 #site-header .cart-block .header .cart-icon:after {
  color: #5e5202;
}
.theme-color-scheme-2 #site-header:before,
.theme-color-scheme-2 #site-footer,
.theme-color-scheme-2 .button,
.theme-color-scheme-2 button,
.theme-color-scheme-2 input[type=button],
.theme-color-scheme-2 input[type=submit],
.theme-color-scheme-2 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-2 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-2 #content-sidebar .cart-block .header,
.theme-color-scheme-2 .pagination a {
  background-color: #5e5202;
}
.theme-color-scheme-2 .button,
.theme-color-scheme-2 .button:hover,
.theme-color-scheme-2 .button:visited,
.theme-color-scheme-2 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-2 ul.gallery .info,
.theme-color-scheme-2 ul.gallery .add-to-cart {
  background-color: #5e5202 !important;
}
.theme-color-scheme-2 ul.gallery .info:hover,
.theme-color-scheme-2 ul.gallery .add-to-cart:hover {
  background-color: #766b1d !important;
}
.theme-color-scheme-2 .product-status:after {
  border-top-color: #5e5202;
}
.theme-color-scheme-2 #site-header .search-block button:hover:before, .theme-color-scheme-2 #site-header .search-block button:focus:before,
.theme-color-scheme-2 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-2 #content-sidebar .search-block button:focus:before {
  color: #5e5202 !important;
}
.theme-color-scheme-2 .button:hover,
.theme-color-scheme-2 button:hover,
.theme-color-scheme-2 input[type=button]:hover,
.theme-color-scheme-2 input[type=submit]:hover,
.theme-color-scheme-2 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-2 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-2 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-2 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-2 ul.gallery a.info:hover,
.theme-color-scheme-2 button:focus,
.theme-color-scheme-2 input[type=button]:focus,
.theme-color-scheme-2 input[type=submit]:focus,
.theme-color-scheme-2 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-2 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-2 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-2 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-2 ul.gallery a.info:focus {
  background-color: #766b1d;
}
.theme-color-scheme-2 .sidr-close, .theme-color-scheme-2 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-2 #site-header .cart-block .header .cart-icon:after {
  border-color: #5e5202;
}
.theme-color-scheme-2 ul.gallery button span:after,
.theme-color-scheme-2 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-2 ul.gallery .info:hover span:after {
  border-left-color: #5e5202;
}
.theme-color-scheme-2 #site-header .cart-block .cart-content:after,
.theme-color-scheme-2 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-2 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #5e5202;
}
.theme-color-scheme-2 input[type=text], .theme-color-scheme-2 input[type=password], .theme-color-scheme-2 input[type=email], .theme-color-scheme-2 input[type=number], .theme-color-scheme-2 input[type=search], .theme-color-scheme-2 input[type=tel], .theme-color-scheme-2 input[type=time], .theme-color-scheme-2 input[type=url], .theme-color-scheme-2 input:focus {
  border-color: #9f9027 !important;
  box-shadow: 0px 1px 2px rgba(94, 82, 2, 0.3) inset, 0px 0px 8px rgba(94, 82, 2, 0.3);
}
.theme-color-scheme-2 .add-to-cart-form.hover .product-status:after {
  border-top-color: #766b1d;
}
.theme-color-scheme-2 #content-sidebar nav ul li a:hover {
  color: #C27F08;
}
.theme-color-scheme-2 #site-header nav.custom ul li ul li a:hover {
  color: #C27F08 !important;
}
.theme-color-scheme-2 .add-to-cart:hover:before,
.theme-color-scheme-2 .info:hover:before {
  color: #5e5202;
}
.theme-color-scheme-2 .select-locale-button,
.theme-color-scheme-2 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-2 .autocomplete-suggestions {
  border-color: #9f9027;
  box-shadow: 0px 3px 8px rgba(94, 82, 2, 0.3);
  background-color: white;
}
.theme-color-scheme-2 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-2 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #C27F08;
}
.theme-color-scheme-2 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #C27F08;
}

.theme-color-scheme-3 {
  background-color: #e3ebd3;
}
.theme-color-scheme-3 a {
  color: #A57D42;
}
.theme-color-scheme-3 a:visited {
  color: #A57D42;
}
.theme-color-scheme-3 a:hover {
  color: #A57D42;
}
.theme-color-scheme-3 a:active {
  color: #A57D42;
}
.theme-color-scheme-3 #home:hover, .theme-color-scheme-3 #home:visited:hover,
.theme-color-scheme-3 #site-header nav.category ul li a:hover,
.theme-color-scheme-3 .categories.gallery.names .category-info:hover h3 {
  color: #A57D42 !important;
}
.theme-color-scheme-3 #site-footer:after {
  border-top-color: #e3ebd3;
}
.theme-color-scheme-3 .gallery-layout a,
.theme-color-scheme-3 .gallery-layout a:visited {
  color: #c0cca5;
}
.theme-color-scheme-3 .pagination ul span {
  background-color: #cbd5b6;
}
.theme-color-scheme-3 #site-header nav.custom ul li a,
.theme-color-scheme-3 #site-header .cart-block .header,
.theme-color-scheme-3 #menu:before {
  color: #e3ebd3;
}
.theme-color-scheme-3 #site-header .cart-block .header .cart-icon:after {
  background-color: #e3ebd3;
}
.theme-color-scheme-3 #site-header nav.custom ul li a:hover,
.theme-color-scheme-3 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-3 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-3 .clear-import-items,
.theme-color-scheme-3 .cancel-preview-cart-import {
  background-color: #b4c395;
}
.theme-color-scheme-3 h1,
.theme-color-scheme-3 h2,
.theme-color-scheme-3 h3,
.theme-color-scheme-3 h4,
.theme-color-scheme-3 .gallery-layout a.current,
.theme-color-scheme-3 .gallery-layout a:hover,
.theme-color-scheme-3 #site-header .cart-block .header .cart-icon:after {
  color: #3c5801;
}
.theme-color-scheme-3 #site-header:before,
.theme-color-scheme-3 #site-footer,
.theme-color-scheme-3 .button,
.theme-color-scheme-3 button,
.theme-color-scheme-3 input[type=button],
.theme-color-scheme-3 input[type=submit],
.theme-color-scheme-3 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-3 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-3 #content-sidebar .cart-block .header,
.theme-color-scheme-3 .pagination a {
  background-color: #3c5801;
}
.theme-color-scheme-3 .button,
.theme-color-scheme-3 .button:hover,
.theme-color-scheme-3 .button:visited,
.theme-color-scheme-3 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-3 ul.gallery .info,
.theme-color-scheme-3 ul.gallery .add-to-cart {
  background-color: #3c5801 !important;
}
.theme-color-scheme-3 ul.gallery .info:hover,
.theme-color-scheme-3 ul.gallery .add-to-cart:hover {
  background-color: #56721a !important;
}
.theme-color-scheme-3 .product-status:after {
  border-top-color: #3c5801;
}
.theme-color-scheme-3 #site-header .search-block button:hover:before, .theme-color-scheme-3 #site-header .search-block button:focus:before,
.theme-color-scheme-3 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-3 #content-sidebar .search-block button:focus:before {
  color: #3c5801 !important;
}
.theme-color-scheme-3 .button:hover,
.theme-color-scheme-3 button:hover,
.theme-color-scheme-3 input[type=button]:hover,
.theme-color-scheme-3 input[type=submit]:hover,
.theme-color-scheme-3 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-3 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-3 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-3 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-3 ul.gallery a.info:hover,
.theme-color-scheme-3 button:focus,
.theme-color-scheme-3 input[type=button]:focus,
.theme-color-scheme-3 input[type=submit]:focus,
.theme-color-scheme-3 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-3 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-3 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-3 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-3 ul.gallery a.info:focus {
  background-color: #56721a;
}
.theme-color-scheme-3 .sidr-close, .theme-color-scheme-3 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-3 #site-header .cart-block .header .cart-icon:after {
  border-color: #3c5801;
}
.theme-color-scheme-3 ul.gallery button span:after,
.theme-color-scheme-3 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-3 ul.gallery .info:hover span:after {
  border-left-color: #3c5801;
}
.theme-color-scheme-3 #site-header .cart-block .cart-content:after,
.theme-color-scheme-3 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-3 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #3c5801;
}
.theme-color-scheme-3 input[type=text], .theme-color-scheme-3 input[type=password], .theme-color-scheme-3 input[type=email], .theme-color-scheme-3 input[type=number], .theme-color-scheme-3 input[type=search], .theme-color-scheme-3 input[type=tel], .theme-color-scheme-3 input[type=time], .theme-color-scheme-3 input[type=url], .theme-color-scheme-3 input:focus {
  border-color: #759b24 !important;
  box-shadow: 0px 1px 2px rgba(60, 88, 1, 0.3) inset, 0px 0px 8px rgba(60, 88, 1, 0.3);
}
.theme-color-scheme-3 .add-to-cart-form.hover .product-status:after {
  border-top-color: #56721a;
}
.theme-color-scheme-3 #content-sidebar nav ul li a:hover {
  color: #A57D42;
}
.theme-color-scheme-3 #site-header nav.custom ul li ul li a:hover {
  color: #A57D42 !important;
}
.theme-color-scheme-3 .add-to-cart:hover:before,
.theme-color-scheme-3 .info:hover:before {
  color: #3c5801;
}
.theme-color-scheme-3 .select-locale-button,
.theme-color-scheme-3 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-3 .autocomplete-suggestions {
  border-color: #759b24;
  box-shadow: 0px 3px 8px rgba(60, 88, 1, 0.3);
  background-color: white;
}
.theme-color-scheme-3 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-3 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #A57D42;
}
.theme-color-scheme-3 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #A57D42;
}

.theme-color-scheme-4 {
  background-color: #eaeeef;
}
.theme-color-scheme-4 a {
  color: #B45018;
}
.theme-color-scheme-4 a:visited {
  color: #B45018;
}
.theme-color-scheme-4 a:hover {
  color: #B45018;
}
.theme-color-scheme-4 a:active {
  color: #B45018;
}
.theme-color-scheme-4 #home:hover, .theme-color-scheme-4 #home:visited:hover,
.theme-color-scheme-4 #site-header nav.category ul li a:hover,
.theme-color-scheme-4 .categories.gallery.names .category-info:hover h3 {
  color: #B45018 !important;
}
.theme-color-scheme-4 #site-footer:after {
  border-top-color: #eaeeef;
}
.theme-color-scheme-4 .gallery-layout a,
.theme-color-scheme-4 .gallery-layout a:visited {
  color: #c3c8ca;
}
.theme-color-scheme-4 .pagination ul span {
  background-color: #d0d4d6;
}
.theme-color-scheme-4 #site-header nav.custom ul li a,
.theme-color-scheme-4 #site-header .cart-block .header,
.theme-color-scheme-4 #menu:before {
  color: #eaeeef;
}
.theme-color-scheme-4 #site-header .cart-block .header .cart-icon:after {
  background-color: #eaeeef;
}
.theme-color-scheme-4 #site-header nav.custom ul li a:hover,
.theme-color-scheme-4 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-4 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-4 .clear-import-items,
.theme-color-scheme-4 .cancel-preview-cart-import {
  background-color: #b5bcbe;
}
.theme-color-scheme-4 h1,
.theme-color-scheme-4 h2,
.theme-color-scheme-4 h3,
.theme-color-scheme-4 h4,
.theme-color-scheme-4 .gallery-layout a.current,
.theme-color-scheme-4 .gallery-layout a:hover,
.theme-color-scheme-4 #site-header .cart-block .header .cart-icon:after {
  color: #0e5266;
}
.theme-color-scheme-4 #site-header:before,
.theme-color-scheme-4 #site-footer,
.theme-color-scheme-4 .button,
.theme-color-scheme-4 button,
.theme-color-scheme-4 input[type=button],
.theme-color-scheme-4 input[type=submit],
.theme-color-scheme-4 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-4 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-4 #content-sidebar .cart-block .header,
.theme-color-scheme-4 .pagination a {
  background-color: #0e5266;
}
.theme-color-scheme-4 .button,
.theme-color-scheme-4 .button:hover,
.theme-color-scheme-4 .button:visited,
.theme-color-scheme-4 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-4 ul.gallery .info,
.theme-color-scheme-4 ul.gallery .add-to-cart {
  background-color: #0e5266 !important;
}
.theme-color-scheme-4 ul.gallery .info:hover,
.theme-color-scheme-4 ul.gallery .add-to-cart:hover {
  background-color: #316676 !important;
}
.theme-color-scheme-4 .product-status:after {
  border-top-color: #0e5266;
}
.theme-color-scheme-4 #site-header .search-block button:hover:before, .theme-color-scheme-4 #site-header .search-block button:focus:before,
.theme-color-scheme-4 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-4 #content-sidebar .search-block button:focus:before {
  color: #0e5266 !important;
}
.theme-color-scheme-4 .button:hover,
.theme-color-scheme-4 button:hover,
.theme-color-scheme-4 input[type=button]:hover,
.theme-color-scheme-4 input[type=submit]:hover,
.theme-color-scheme-4 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-4 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-4 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-4 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-4 ul.gallery a.info:hover,
.theme-color-scheme-4 button:focus,
.theme-color-scheme-4 input[type=button]:focus,
.theme-color-scheme-4 input[type=submit]:focus,
.theme-color-scheme-4 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-4 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-4 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-4 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-4 ul.gallery a.info:focus {
  background-color: #316676;
}
.theme-color-scheme-4 .sidr-close, .theme-color-scheme-4 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-4 #site-header .cart-block .header .cart-icon:after {
  border-color: #0e5266;
}
.theme-color-scheme-4 ul.gallery button span:after,
.theme-color-scheme-4 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-4 ul.gallery .info:hover span:after {
  border-left-color: #0e5266;
}
.theme-color-scheme-4 #site-header .cart-block .cart-content:after,
.theme-color-scheme-4 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-4 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #0e5266;
}
.theme-color-scheme-4 input[type=text], .theme-color-scheme-4 input[type=password], .theme-color-scheme-4 input[type=email], .theme-color-scheme-4 input[type=number], .theme-color-scheme-4 input[type=search], .theme-color-scheme-4 input[type=tel], .theme-color-scheme-4 input[type=time], .theme-color-scheme-4 input[type=url], .theme-color-scheme-4 input:focus {
  border-color: #40859a !important;
  box-shadow: 0px 1px 2px rgba(14, 82, 102, 0.3) inset, 0px 0px 8px rgba(14, 82, 102, 0.3);
}
.theme-color-scheme-4 .add-to-cart-form.hover .product-status:after {
  border-top-color: #316676;
}
.theme-color-scheme-4 #content-sidebar nav ul li a:hover {
  color: #B45018;
}
.theme-color-scheme-4 #site-header nav.custom ul li ul li a:hover {
  color: #B45018 !important;
}
.theme-color-scheme-4 .add-to-cart:hover:before,
.theme-color-scheme-4 .info:hover:before {
  color: #0e5266;
}
.theme-color-scheme-4 .select-locale-button,
.theme-color-scheme-4 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-4 .autocomplete-suggestions {
  border-color: #40859a;
  box-shadow: 0px 3px 8px rgba(14, 82, 102, 0.3);
  background-color: white;
}
.theme-color-scheme-4 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-4 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #B45018;
}
.theme-color-scheme-4 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #B45018;
}

.theme-color-scheme-5 {
  background-color: #e1e4e7;
}
.theme-color-scheme-5 a {
  color: #A334A0;
}
.theme-color-scheme-5 a:visited {
  color: #A334A0;
}
.theme-color-scheme-5 a:hover {
  color: #A334A0;
}
.theme-color-scheme-5 a:active {
  color: #A334A0;
}
.theme-color-scheme-5 #home:hover, .theme-color-scheme-5 #home:visited:hover,
.theme-color-scheme-5 #site-header nav.category ul li a:hover,
.theme-color-scheme-5 .categories.gallery.names .category-info:hover h3 {
  color: #A334A0 !important;
}
.theme-color-scheme-5 #site-footer:after {
  border-top-color: #e1e4e7;
}
.theme-color-scheme-5 .gallery-layout a,
.theme-color-scheme-5 .gallery-layout a:visited {
  color: #bebebe;
}
.theme-color-scheme-5 .pagination ul span {
  background-color: #cbcbcb;
}
.theme-color-scheme-5 #site-header nav.custom ul li a,
.theme-color-scheme-5 #site-header .cart-block .header,
.theme-color-scheme-5 #menu:before {
  color: #e1e4e7;
}
.theme-color-scheme-5 #site-header .cart-block .header .cart-icon:after {
  background-color: #e1e4e7;
}
.theme-color-scheme-5 #site-header nav.custom ul li a:hover,
.theme-color-scheme-5 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-5 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-5 .clear-import-items,
.theme-color-scheme-5 .cancel-preview-cart-import {
  background-color: #b1b1b1;
}
.theme-color-scheme-5 h1,
.theme-color-scheme-5 h2,
.theme-color-scheme-5 h3,
.theme-color-scheme-5 h4,
.theme-color-scheme-5 .gallery-layout a.current,
.theme-color-scheme-5 .gallery-layout a:hover,
.theme-color-scheme-5 #site-header .cart-block .header .cart-icon:after {
  color: #0f2e54;
}
.theme-color-scheme-5 #site-header:before,
.theme-color-scheme-5 #site-footer,
.theme-color-scheme-5 .button,
.theme-color-scheme-5 button,
.theme-color-scheme-5 input[type=button],
.theme-color-scheme-5 input[type=submit],
.theme-color-scheme-5 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-5 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-5 #content-sidebar .cart-block .header,
.theme-color-scheme-5 .pagination a {
  background-color: #0f2e54;
}
.theme-color-scheme-5 .button,
.theme-color-scheme-5 .button:hover,
.theme-color-scheme-5 .button:visited,
.theme-color-scheme-5 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-5 ul.gallery .info,
.theme-color-scheme-5 ul.gallery .add-to-cart {
  background-color: #0f2e54 !important;
}
.theme-color-scheme-5 ul.gallery .info:hover,
.theme-color-scheme-5 ul.gallery .add-to-cart:hover {
  background-color: #314865 !important;
}
.theme-color-scheme-5 .product-status:after {
  border-top-color: #0f2e54;
}
.theme-color-scheme-5 #site-header .search-block button:hover:before, .theme-color-scheme-5 #site-header .search-block button:focus:before,
.theme-color-scheme-5 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-5 #content-sidebar .search-block button:focus:before {
  color: #0f2e54 !important;
}
.theme-color-scheme-5 .button:hover,
.theme-color-scheme-5 button:hover,
.theme-color-scheme-5 input[type=button]:hover,
.theme-color-scheme-5 input[type=submit]:hover,
.theme-color-scheme-5 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-5 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-5 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-5 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-5 ul.gallery a.info:hover,
.theme-color-scheme-5 button:focus,
.theme-color-scheme-5 input[type=button]:focus,
.theme-color-scheme-5 input[type=submit]:focus,
.theme-color-scheme-5 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-5 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-5 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-5 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-5 ul.gallery a.info:focus {
  background-color: #314865;
}
.theme-color-scheme-5 .sidr-close, .theme-color-scheme-5 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-5 #site-header .cart-block .header .cart-icon:after {
  border-color: #0f2e54;
}
.theme-color-scheme-5 ul.gallery button span:after,
.theme-color-scheme-5 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-5 ul.gallery .info:hover span:after {
  border-left-color: #0f2e54;
}
.theme-color-scheme-5 #site-header .cart-block .cart-content:after,
.theme-color-scheme-5 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-5 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #0f2e54;
}
.theme-color-scheme-5 input[type=text], .theme-color-scheme-5 input[type=password], .theme-color-scheme-5 input[type=email], .theme-color-scheme-5 input[type=number], .theme-color-scheme-5 input[type=search], .theme-color-scheme-5 input[type=tel], .theme-color-scheme-5 input[type=time], .theme-color-scheme-5 input[type=url], .theme-color-scheme-5 input:focus {
  border-color: #426187 !important;
  box-shadow: 0px 1px 2px rgba(15, 46, 84, 0.3) inset, 0px 0px 8px rgba(15, 46, 84, 0.3);
}
.theme-color-scheme-5 .add-to-cart-form.hover .product-status:after {
  border-top-color: #314865;
}
.theme-color-scheme-5 #content-sidebar nav ul li a:hover {
  color: #A334A0;
}
.theme-color-scheme-5 #site-header nav.custom ul li ul li a:hover {
  color: #A334A0 !important;
}
.theme-color-scheme-5 .add-to-cart:hover:before,
.theme-color-scheme-5 .info:hover:before {
  color: #0f2e54;
}
.theme-color-scheme-5 .select-locale-button,
.theme-color-scheme-5 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-5 .autocomplete-suggestions {
  border-color: #426187;
  box-shadow: 0px 3px 8px rgba(15, 46, 84, 0.3);
  background-color: white;
}
.theme-color-scheme-5 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-5 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #A334A0;
}
.theme-color-scheme-5 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #A334A0;
}

.theme-color-scheme-6 {
  background-color: #ece7ef;
}
.theme-color-scheme-6 a {
  color: #DF7406;
}
.theme-color-scheme-6 a:visited {
  color: #DF7406;
}
.theme-color-scheme-6 a:hover {
  color: #DF7406;
}
.theme-color-scheme-6 a:active {
  color: #DF7406;
}
.theme-color-scheme-6 #home:hover, .theme-color-scheme-6 #home:visited:hover,
.theme-color-scheme-6 #site-header nav.category ul li a:hover,
.theme-color-scheme-6 .categories.gallery.names .category-info:hover h3 {
  color: #DF7406 !important;
}
.theme-color-scheme-6 #site-footer:after {
  border-top-color: #ece7ef;
}
.theme-color-scheme-6 .gallery-layout a,
.theme-color-scheme-6 .gallery-layout a:visited {
  color: #c7bfcb;
}
.theme-color-scheme-6 .pagination ul span {
  background-color: #d3cdd6;
}
.theme-color-scheme-6 #site-header nav.custom ul li a,
.theme-color-scheme-6 #site-header .cart-block .header,
.theme-color-scheme-6 #menu:before {
  color: #ece7ef;
}
.theme-color-scheme-6 #site-header .cart-block .header .cart-icon:after {
  background-color: #ece7ef;
}
.theme-color-scheme-6 #site-header nav.custom ul li a:hover,
.theme-color-scheme-6 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-6 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-6 .clear-import-items,
.theme-color-scheme-6 .cancel-preview-cart-import {
  background-color: #bab1bf;
}
.theme-color-scheme-6 h1,
.theme-color-scheme-6 h2,
.theme-color-scheme-6 h3,
.theme-color-scheme-6 h4,
.theme-color-scheme-6 .gallery-layout a.current,
.theme-color-scheme-6 .gallery-layout a:hover,
.theme-color-scheme-6 #site-header .cart-block .header .cart-icon:after {
  color: #470b66;
}
.theme-color-scheme-6 #site-header:before,
.theme-color-scheme-6 #site-footer,
.theme-color-scheme-6 .button,
.theme-color-scheme-6 button,
.theme-color-scheme-6 input[type=button],
.theme-color-scheme-6 input[type=submit],
.theme-color-scheme-6 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-6 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-6 #content-sidebar .cart-block .header,
.theme-color-scheme-6 .pagination a {
  background-color: #470b66;
}
.theme-color-scheme-6 .button,
.theme-color-scheme-6 .button:hover,
.theme-color-scheme-6 .button:visited,
.theme-color-scheme-6 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-6 ul.gallery .info,
.theme-color-scheme-6 ul.gallery .add-to-cart {
  background-color: #470b66 !important;
}
.theme-color-scheme-6 ul.gallery .info:hover,
.theme-color-scheme-6 ul.gallery .add-to-cart:hover {
  background-color: #5e2d77 !important;
}
.theme-color-scheme-6 .product-status:after {
  border-top-color: #470b66;
}
.theme-color-scheme-6 #site-header .search-block button:hover:before, .theme-color-scheme-6 #site-header .search-block button:focus:before,
.theme-color-scheme-6 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-6 #content-sidebar .search-block button:focus:before {
  color: #470b66 !important;
}
.theme-color-scheme-6 .button:hover,
.theme-color-scheme-6 button:hover,
.theme-color-scheme-6 input[type=button]:hover,
.theme-color-scheme-6 input[type=submit]:hover,
.theme-color-scheme-6 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-6 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-6 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-6 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-6 ul.gallery a.info:hover,
.theme-color-scheme-6 button:focus,
.theme-color-scheme-6 input[type=button]:focus,
.theme-color-scheme-6 input[type=submit]:focus,
.theme-color-scheme-6 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-6 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-6 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-6 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-6 ul.gallery a.info:focus {
  background-color: #5e2d77;
}
.theme-color-scheme-6 .sidr-close, .theme-color-scheme-6 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-6 #site-header .cart-block .header .cart-icon:after {
  border-color: #470b66;
}
.theme-color-scheme-6 ul.gallery button span:after,
.theme-color-scheme-6 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-6 ul.gallery .info:hover span:after {
  border-left-color: #470b66;
}
.theme-color-scheme-6 #site-header .cart-block .cart-content:after,
.theme-color-scheme-6 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-6 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #470b66;
}
.theme-color-scheme-6 input[type=text], .theme-color-scheme-6 input[type=password], .theme-color-scheme-6 input[type=email], .theme-color-scheme-6 input[type=number], .theme-color-scheme-6 input[type=search], .theme-color-scheme-6 input[type=tel], .theme-color-scheme-6 input[type=time], .theme-color-scheme-6 input[type=url], .theme-color-scheme-6 input:focus {
  border-color: #7b3b9c !important;
  box-shadow: 0px 1px 2px rgba(71, 11, 102, 0.3) inset, 0px 0px 8px rgba(71, 11, 102, 0.3);
}
.theme-color-scheme-6 .add-to-cart-form.hover .product-status:after {
  border-top-color: #5e2d77;
}
.theme-color-scheme-6 #content-sidebar nav ul li a:hover {
  color: #DF7406;
}
.theme-color-scheme-6 #site-header nav.custom ul li ul li a:hover {
  color: #DF7406 !important;
}
.theme-color-scheme-6 .add-to-cart:hover:before,
.theme-color-scheme-6 .info:hover:before {
  color: #470b66;
}
.theme-color-scheme-6 .select-locale-button,
.theme-color-scheme-6 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-6 .autocomplete-suggestions {
  border-color: #7b3b9c;
  box-shadow: 0px 3px 8px rgba(71, 11, 102, 0.3);
  background-color: white;
}
.theme-color-scheme-6 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-6 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #DF7406;
}
.theme-color-scheme-6 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #DF7406;
}

.theme-color-scheme-7 {
  background-color: #efeaed;
}
.theme-color-scheme-7 a {
  color: #A72CA2;
}
.theme-color-scheme-7 a:visited {
  color: #A72CA2;
}
.theme-color-scheme-7 a:hover {
  color: #A72CA2;
}
.theme-color-scheme-7 a:active {
  color: #A72CA2;
}
.theme-color-scheme-7 #home:hover, .theme-color-scheme-7 #home:visited:hover,
.theme-color-scheme-7 #site-header nav.category ul li a:hover,
.theme-color-scheme-7 .categories.gallery.names .category-info:hover h3 {
  color: #A72CA2 !important;
}
.theme-color-scheme-7 #site-footer:after {
  border-top-color: #efeaed;
}
.theme-color-scheme-7 .gallery-layout a,
.theme-color-scheme-7 .gallery-layout a:visited {
  color: #c8c5c7;
}
.theme-color-scheme-7 .pagination ul span {
  background-color: #d4d2d3;
}
.theme-color-scheme-7 #site-header nav.custom ul li a,
.theme-color-scheme-7 #site-header .cart-block .header,
.theme-color-scheme-7 #menu:before {
  color: #efeaed;
}
.theme-color-scheme-7 #site-header .cart-block .header .cart-icon:after {
  background-color: #efeaed;
}
.theme-color-scheme-7 #site-header nav.custom ul li a:hover,
.theme-color-scheme-7 #site-header .cart-block .header:hover {
  color: white !important;
}
.theme-color-scheme-7 #site-header .cart-block .header:hover .cart-icon:after {
  background-color: white !important;
}
.theme-color-scheme-7 .clear-import-items,
.theme-color-scheme-7 .cancel-preview-cart-import {
  background-color: #bbb8ba;
}
.theme-color-scheme-7 h1,
.theme-color-scheme-7 h2,
.theme-color-scheme-7 h3,
.theme-color-scheme-7 h4,
.theme-color-scheme-7 .gallery-layout a.current,
.theme-color-scheme-7 .gallery-layout a:hover,
.theme-color-scheme-7 #site-header .cart-block .header .cart-icon:after {
  color: #641047;
}
.theme-color-scheme-7 #site-header:before,
.theme-color-scheme-7 #site-footer,
.theme-color-scheme-7 .button,
.theme-color-scheme-7 button,
.theme-color-scheme-7 input[type=button],
.theme-color-scheme-7 input[type=submit],
.theme-color-scheme-7 #site-header .cart-block .footer a.checkout-link,
.theme-color-scheme-7 #content-sidebar .cart-block .footer a.checkout-link,
.theme-color-scheme-7 #content-sidebar .cart-block .header,
.theme-color-scheme-7 .pagination a {
  background-color: #641047;
}
.theme-color-scheme-7 .button,
.theme-color-scheme-7 .button:hover,
.theme-color-scheme-7 .button:visited,
.theme-color-scheme-7 .checkout-link:visited {
  color: white;
}
.theme-color-scheme-7 ul.gallery .info,
.theme-color-scheme-7 ul.gallery .add-to-cart {
  background-color: #641047 !important;
}
.theme-color-scheme-7 ul.gallery .info:hover,
.theme-color-scheme-7 ul.gallery .add-to-cart:hover {
  background-color: #73345d !important;
}
.theme-color-scheme-7 .product-status:after {
  border-top-color: #641047;
}
.theme-color-scheme-7 #site-header .search-block button:hover:before, .theme-color-scheme-7 #site-header .search-block button:focus:before,
.theme-color-scheme-7 #content-sidebar .search-block button:hover:before,
.theme-color-scheme-7 #content-sidebar .search-block button:focus:before {
  color: #641047 !important;
}
.theme-color-scheme-7 .button:hover,
.theme-color-scheme-7 button:hover,
.theme-color-scheme-7 input[type=button]:hover,
.theme-color-scheme-7 input[type=submit]:hover,
.theme-color-scheme-7 #site-header .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-7 #site-header .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-7 #content-sidebar .cart-block .footer a.checkout-link:hover,
.theme-color-scheme-7 #content-sidebar .cart-block .footer a.cart-content-close:hover,
.theme-color-scheme-7 ul.gallery a.info:hover,
.theme-color-scheme-7 button:focus,
.theme-color-scheme-7 input[type=button]:focus,
.theme-color-scheme-7 input[type=submit]:focus,
.theme-color-scheme-7 #site-header .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-7 #site-header .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-7 #content-sidebar .cart-block .footer a.checkout-link:focus,
.theme-color-scheme-7 #content-sidebar .cart-block .footer a.cart-content-close:focus,
.theme-color-scheme-7 ul.gallery a.info:focus {
  background-color: #73345d;
}
.theme-color-scheme-7 .sidr-close, .theme-color-scheme-7 .sidr-close:hover {
  background-color: transparent;
}
.theme-color-scheme-7 #site-header .cart-block .header .cart-icon:after {
  border-color: #641047;
}
.theme-color-scheme-7 ul.gallery button span:after,
.theme-color-scheme-7 ul.gallery .add-to-cart:hover span:after,
.theme-color-scheme-7 ul.gallery .info:hover span:after {
  border-left-color: #641047;
}
.theme-color-scheme-7 #site-header .cart-block .cart-content:after,
.theme-color-scheme-7 #content-sidebar .cart-block .cart-content:after,
.theme-color-scheme-7 #content-sidebar .cart-block.open.header-hover .cart-content:after {
  border-top-color: #641047;
}
.theme-color-scheme-7 input[type=text], .theme-color-scheme-7 input[type=password], .theme-color-scheme-7 input[type=email], .theme-color-scheme-7 input[type=number], .theme-color-scheme-7 input[type=search], .theme-color-scheme-7 input[type=tel], .theme-color-scheme-7 input[type=time], .theme-color-scheme-7 input[type=url], .theme-color-scheme-7 input:focus {
  border-color: #96447a !important;
  box-shadow: 0px 1px 2px rgba(100, 16, 71, 0.3) inset, 0px 0px 8px rgba(100, 16, 71, 0.3);
}
.theme-color-scheme-7 .add-to-cart-form.hover .product-status:after {
  border-top-color: #73345d;
}
.theme-color-scheme-7 #content-sidebar nav ul li a:hover {
  color: #A72CA2;
}
.theme-color-scheme-7 #site-header nav.custom ul li ul li a:hover {
  color: #A72CA2 !important;
}
.theme-color-scheme-7 .add-to-cart:hover:before,
.theme-color-scheme-7 .info:hover:before {
  color: #641047;
}
.theme-color-scheme-7 .select-locale-button,
.theme-color-scheme-7 .select-locale-modal-close-button {
  background-color: transparent;
}
.theme-color-scheme-7 .autocomplete-suggestions {
  border-color: #96447a;
  box-shadow: 0px 3px 8px rgba(100, 16, 71, 0.3);
  background-color: white;
}
.theme-color-scheme-7 .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #F0F0F0;
}
.theme-color-scheme-7 .autocomplete-suggestions .autocomplete-suggestion strong {
  color: #A72CA2;
}
.theme-color-scheme-7 .autocomplete-suggestions .autocomplete-group strong {
  border-bottom: 1px solid #A72CA2;
}

#color-scheme li[data-option="0"] a:before {
  background-color: rgb(33, 33, 33);
}

#color-scheme li[data-option="1"] a:before {
  background-color: rgb(100, 100, 100);
}

#color-scheme li[data-option="2"] a:before {
  background-color: #5e5202;
}

#color-scheme li[data-option="3"] a:before {
  background-color: #3c5801;
}

#color-scheme li[data-option="4"] a:before {
  background-color: #0e5266;
}

#color-scheme li[data-option="5"] a:before {
  background-color: #0f2e54;
}

#color-scheme li[data-option="6"] a:before {
  background-color: #470b66;
}

#color-scheme li[data-option="7"] a:before {
  background-color: #641047;
}

#background-patterns li {
  background-color: rgb(33, 33, 33);
}

#background-pattern li[data-option="1"] a:before,
.theme-background-pattern-1 {
  background-image: url(../img/background-pattern/01.png);
}

#background-pattern li[data-option="2"] a:before,
.theme-background-pattern-2 {
  background-image: url(../img/background-pattern/02.png);
}

#background-pattern li[data-option="3"] a:before,
.theme-background-pattern-3 {
  background-image: url(../img/background-pattern/03.png);
}

#background-pattern li[data-option="4"] a:before,
.theme-background-pattern-4 {
  background-image: url(../img/background-pattern/04.png);
}

#background-pattern li[data-option="5"] a:before,
.theme-background-pattern-5 {
  background-image: url(../img/background-pattern/05.png);
}

#background-pattern li[data-option="6"] a:before,
.theme-background-pattern-6 {
  background-image: url(../img/background-pattern/06.png);
}

#background-pattern li[data-option="7"] a:before,
.theme-background-pattern-7 {
  background-image: url(../img/background-pattern/07.png);
}

#checkout-layout li a {
  text-align: center;
  border-style: none;
  color: black;
}
#checkout-layout li a:hover {
  border-style: none;
  color: #682877;
}
#checkout-layout li a:before {
  font-size: 60px;
}
#checkout-layout li a b {
  display: none;
}
#checkout-layout li[data-option="0"] a:before {
  content: "\e604";
}
#checkout-layout li[data-option="1"] a:before {
  content: "\e603";
}
#checkout-layout li[data-option="2"] a:before {
  content: "\e605";
}

.theme-color-scheme-0 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-0 .filter-group-open .filter-group-title,
.theme-color-scheme-0 .irs-bar,
.theme-color-scheme-0 .irs-from,
.theme-color-scheme-0 .irs-to,
.theme-color-scheme-0 .irs-single,
.theme-color-scheme-0 .current-filter-menu-item {
  background-color: rgb(33, 33, 33);
  color: #fff;
}
.theme-color-scheme-0 .irs-from:after,
.theme-color-scheme-0 .irs-to:after,
.theme-color-scheme-0 .irs-single:after {
  border-top-color: rgb(33, 33, 33);
}
.theme-color-scheme-0 .favorite-cart-block .product-item-count .number, .theme-color-scheme-0 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid rgb(33, 33, 33);
}
.theme-color-scheme-0 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-0 .shopping-favorite-cart .info-link:active, .theme-color-scheme-0 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:focus {
  background-color: rgb(33, 33, 33);
  color: white;
}
.theme-color-scheme-0 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-0 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-0 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-0 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-0 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: rgb(33, 33, 33);
  color: white;
}

.theme-color-scheme-1 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-1 .filter-group-open .filter-group-title,
.theme-color-scheme-1 .irs-bar,
.theme-color-scheme-1 .irs-from,
.theme-color-scheme-1 .irs-to,
.theme-color-scheme-1 .irs-single,
.theme-color-scheme-1 .current-filter-menu-item {
  background-color: rgb(100, 100, 100);
  color: #fff;
}
.theme-color-scheme-1 .irs-from:after,
.theme-color-scheme-1 .irs-to:after,
.theme-color-scheme-1 .irs-single:after {
  border-top-color: rgb(100, 100, 100);
}
.theme-color-scheme-1 .favorite-cart-block .product-item-count .number, .theme-color-scheme-1 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid rgb(100, 100, 100);
}
.theme-color-scheme-1 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-1 .shopping-favorite-cart .info-link:active, .theme-color-scheme-1 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:focus {
  background-color: rgb(100, 100, 100);
  color: white;
}
.theme-color-scheme-1 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-1 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-1 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-1 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-1 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: rgb(100, 100, 100);
  color: white;
}

.theme-color-scheme-2 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-2 .filter-group-open .filter-group-title,
.theme-color-scheme-2 .irs-bar,
.theme-color-scheme-2 .irs-from,
.theme-color-scheme-2 .irs-to,
.theme-color-scheme-2 .irs-single,
.theme-color-scheme-2 .current-filter-menu-item {
  background-color: #5e5202;
  color: #fff;
}
.theme-color-scheme-2 .irs-from:after,
.theme-color-scheme-2 .irs-to:after,
.theme-color-scheme-2 .irs-single:after {
  border-top-color: #5e5202;
}
.theme-color-scheme-2 .favorite-cart-block .product-item-count .number, .theme-color-scheme-2 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #5e5202;
}
.theme-color-scheme-2 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-2 .shopping-favorite-cart .info-link:active, .theme-color-scheme-2 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #5e5202;
  color: white;
}
.theme-color-scheme-2 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-2 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-2 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-2 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-2 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #5e5202;
  color: white;
}

.theme-color-scheme-3 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-3 .filter-group-open .filter-group-title,
.theme-color-scheme-3 .irs-bar,
.theme-color-scheme-3 .irs-from,
.theme-color-scheme-3 .irs-to,
.theme-color-scheme-3 .irs-single,
.theme-color-scheme-3 .current-filter-menu-item {
  background-color: #3c5801;
  color: #fff;
}
.theme-color-scheme-3 .irs-from:after,
.theme-color-scheme-3 .irs-to:after,
.theme-color-scheme-3 .irs-single:after {
  border-top-color: #3c5801;
}
.theme-color-scheme-3 .favorite-cart-block .product-item-count .number, .theme-color-scheme-3 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #3c5801;
}
.theme-color-scheme-3 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-3 .shopping-favorite-cart .info-link:active, .theme-color-scheme-3 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #3c5801;
  color: white;
}
.theme-color-scheme-3 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-3 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-3 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-3 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-3 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #3c5801;
  color: white;
}

.theme-color-scheme-4 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-4 .filter-group-open .filter-group-title,
.theme-color-scheme-4 .irs-bar,
.theme-color-scheme-4 .irs-from,
.theme-color-scheme-4 .irs-to,
.theme-color-scheme-4 .irs-single,
.theme-color-scheme-4 .current-filter-menu-item {
  background-color: #0e5266;
  color: #fff;
}
.theme-color-scheme-4 .irs-from:after,
.theme-color-scheme-4 .irs-to:after,
.theme-color-scheme-4 .irs-single:after {
  border-top-color: #0e5266;
}
.theme-color-scheme-4 .favorite-cart-block .product-item-count .number, .theme-color-scheme-4 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #0e5266;
}
.theme-color-scheme-4 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-4 .shopping-favorite-cart .info-link:active, .theme-color-scheme-4 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #0e5266;
  color: white;
}
.theme-color-scheme-4 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-4 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-4 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-4 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-4 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #0e5266;
  color: white;
}

.theme-color-scheme-5 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-5 .filter-group-open .filter-group-title,
.theme-color-scheme-5 .irs-bar,
.theme-color-scheme-5 .irs-from,
.theme-color-scheme-5 .irs-to,
.theme-color-scheme-5 .irs-single,
.theme-color-scheme-5 .current-filter-menu-item {
  background-color: #0f2e54;
  color: #fff;
}
.theme-color-scheme-5 .irs-from:after,
.theme-color-scheme-5 .irs-to:after,
.theme-color-scheme-5 .irs-single:after {
  border-top-color: #0f2e54;
}
.theme-color-scheme-5 .favorite-cart-block .product-item-count .number, .theme-color-scheme-5 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #0f2e54;
}
.theme-color-scheme-5 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-5 .shopping-favorite-cart .info-link:active, .theme-color-scheme-5 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #0f2e54;
  color: white;
}
.theme-color-scheme-5 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-5 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-5 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-5 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-5 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #0f2e54;
  color: white;
}

.theme-color-scheme-6 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-6 .filter-group-open .filter-group-title,
.theme-color-scheme-6 .irs-bar,
.theme-color-scheme-6 .irs-from,
.theme-color-scheme-6 .irs-to,
.theme-color-scheme-6 .irs-single,
.theme-color-scheme-6 .current-filter-menu-item {
  background-color: #470b66;
  color: #fff;
}
.theme-color-scheme-6 .irs-from:after,
.theme-color-scheme-6 .irs-to:after,
.theme-color-scheme-6 .irs-single:after {
  border-top-color: #470b66;
}
.theme-color-scheme-6 .favorite-cart-block .product-item-count .number, .theme-color-scheme-6 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #470b66;
}
.theme-color-scheme-6 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-6 .shopping-favorite-cart .info-link:active, .theme-color-scheme-6 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #470b66;
  color: white;
}
.theme-color-scheme-6 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-6 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-6 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-6 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-6 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #470b66;
  color: white;
}

.theme-color-scheme-7 .filter-menu-item {
  background-color: #fff;
  border: 1px solid #E0DEDE;
  color: #000;
}
.theme-color-scheme-7 .filter-group-open .filter-group-title,
.theme-color-scheme-7 .irs-bar,
.theme-color-scheme-7 .irs-from,
.theme-color-scheme-7 .irs-to,
.theme-color-scheme-7 .irs-single,
.theme-color-scheme-7 .current-filter-menu-item {
  background-color: #641047;
  color: #fff;
}
.theme-color-scheme-7 .irs-from:after,
.theme-color-scheme-7 .irs-to:after,
.theme-color-scheme-7 .irs-single:after {
  border-top-color: #641047;
}
.theme-color-scheme-7 .favorite-cart-block .product-item-count .number, .theme-color-scheme-7 .favorite-cart-block .favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  border: 1px solid #641047;
}
.theme-color-scheme-7 .shopping-favorite-cart .info-link:hover, .theme-color-scheme-7 .shopping-favorite-cart .info-link:active, .theme-color-scheme-7 .shopping-favorite-cart .info-link:focus,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:hover,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:active,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:focus {
  background-color: #641047;
  color: white;
}
.theme-color-scheme-7 .shopping-favorite-cart .info-link:hover:before, .theme-color-scheme-7 .shopping-favorite-cart .info-link:active:before, .theme-color-scheme-7 .shopping-favorite-cart .info-link:focus:before,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:hover:before,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:active:before,
.theme-color-scheme-7 .shopping-favorite-cart .add-to-cart:focus:before {
  color: white;
}
.theme-color-scheme-7 .shopping-favorite-cart .shopping-favorite-cart-header .number {
  background-color: #641047;
  color: white;
}

.favorite-cart-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  top: 10px;
  color: rgb(220, 220, 220);
  right: 4px;
}

.favorite-cart {
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.favorite-cart-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  padding: 0 3px;
}

.favorite-cart-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: -1;
          order: -1;
  height: 100%;
}
.favorite-cart-icon:before {
  content: "\e611";
  font-size: 30px;
}

.favorite {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: auto;
  position: relative;
  cursor: pointer;
  height: 100%;
}
.favorite:before {
  content: "\e90f";
  font-size: 30px;
  margin-top: 0;
}

.favorite-active:before {
  content: "\e90c";
  color: #ff3838;
}

.toggle-favorite-action {
  cursor: pointer;
}

.favorite-list,
.shopping-cart-list {
  display: none;
}

.favorite-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  position: relative;
  right: 10px;
  bottom: 5px;
  border-radius: 50%;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 17px;
  font-size: 12px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.favorite-has-items .favorite-item-count,
.product-has-items .product-item-count {
  display: block;
}

.favorite-item-count,
.product-item-count {
  margin-left: 0.1em;
  display: none;
}

.product-item-count .number {
  background-color: rgb(220, 220, 220);
  color: black;
  position: relative;
  right: 10px;
  bottom: 5px;
  border-radius: 50%;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 17px;
  font-size: 12px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.product-item-count .unit {
  font-size: 0.5em;
  text-transform: uppercase;
  display: none;
}

.open-off-canvas-favorites .favorite:before {
  margin-bottom: 8px;
}

@media only screen and (max-width: 980px) {
  #page-container {
    overflow-y: auto;
  }
}
#page-container:after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(92, 92, 92, 0.4);
  opacity: 0;
  z-index: 999999;
  transition: opacity 0.7s;
}

html.has-visible-shopping-favorite-cart,
html.has-visible-shopping-cart {
  height: 100%;
}
html.has-visible-shopping-favorite-cart body,
html.has-visible-shopping-cart body {
  -webkit-transform: translate3d(-350px, 0, 0);
          transform: translate3d(-350px, 0, 0);
  overflow-y: hidden;
  height: 100%;
}
@media only screen and (max-width: 374px) {
  html.has-visible-shopping-favorite-cart body,
  html.has-visible-shopping-cart body {
    -webkit-transform: translate3d(-300px, 0, 0);
            transform: translate3d(-300px, 0, 0);
  }
}
html.has-visible-shopping-favorite-cart body .off-canvas-cart,
html.has-visible-shopping-cart body .off-canvas-cart {
  display: none;
}
html.has-visible-shopping-favorite-cart #page-container,
html.has-visible-shopping-cart #page-container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
html.has-visible-shopping-favorite-cart #page-container:after,
html.has-visible-shopping-cart #page-container:after {
  opacity: 1;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
html.has-visible-shopping-favorite-cart.has-fixed-site-header .admin-toolbar-phantom,
html.has-visible-shopping-cart.has-fixed-site-header .admin-toolbar-phantom {
  display: none;
}
html.has-visible-shopping-favorite-cart #page-container:not(.shopping-favorite-cart),
html.has-visible-shopping-cart #page-container:not(.shopping-favorite-cart) {
  opacity: 0.7;
}

.favorite-list .favorite-details {
  width: 229px;
  padding: 12px 40px 12px 7px;
}
@media only screen and (max-width: 374px) {
  .favorite-list .favorite-details {
    width: 204px;
  }
}
.favorite-list .img-wrap {
  width: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
}
@media only screen and (max-width: 374px) {
  .favorite-list .img-wrap {
    width: 40px;
  }
}
.favorite-list .info-link,
.favorite-list .add-to-cart {
  background: transparent;
  border-right: 1px solid #dedede;
  width: 55px;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 374px) {
  .favorite-list .info-link,
  .favorite-list .add-to-cart {
    width: 45px;
  }
}
.favorite-list .info-link:hover,
.favorite-list .add-to-cart:hover {
  color: #ffffff;
}
.favorite-list .add-to-cart:before {
  content: "\e611";
  font-size: 20px;
  color: black;
}
.favorite-list .add-to-cart:hover {
  background-color: black;
}
.favorite-list .add-to-cart:hover:before {
  color: white;
}
.favorite-list .info-link {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.favorite-list .info-link:hover {
  background-color: black;
  color: white;
}

.shopping-cart-list {
  margin-bottom: 128px;
}
@media only screen and (max-width: 980px) {
  .shopping-cart-list {
    margin-bottom: 118px;
  }
}
.shopping-cart-list .favorite-details {
  width: 284px;
  padding: 12px 40px 12px 7px;
}
@media only screen and (max-width: 374px) {
  .shopping-cart-list .favorite-details {
    width: 249px;
  }
}
.shopping-cart-list .img-wrap {
  width: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
}
@media only screen and (max-width: 374px) {
  .shopping-cart-list .img-wrap {
    width: 40px;
  }
}

.favorite-list.favorite-list-visible,
.shopping-cart-list.product-list-visible {
  display: block;
  -webkit-flex: 1;
          flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.shopping-favorite-cart {
  background-color: white;
  color: black;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-left: 1px solid #dedede;
  top: 0;
  right: 0;
  width: 350px;
  bottom: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 374px) {
  .shopping-favorite-cart {
    width: 300px;
  }
}
.shopping-favorite-cart .favorite-cart-icon:before,
.shopping-favorite-cart .favorite:before {
  margin-top: 17px;
  margin-bottom: auto;
}
.shopping-favorite-cart .db-cart-message {
  text-align: left;
  padding: 15px 20px;
  line-height: 1.1em;
  margin-top: 0;
  font-size: 0.8rem;
}
.shopping-favorite-cart .db-cart-message:nth-child(2) {
  padding-top: 0;
}
.shopping-favorite-cart .db-cart-message:last-of-type {
  border-bottom: 1px solid #dedede;
}
.shopping-favorite-cart .pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2px;
}
.shopping-favorite-cart .pagination a:hover {
  background-color: #12588e;
  color: white;
}
.shopping-favorite-cart .added-to-cart {
  background: #000;
  color: white;
}
.shopping-favorite-cart .favorite-item-count,
.shopping-favorite-cart .product-item-count {
  margin-top: 16px;
}
.shopping-favorite-cart .selected:after {
  content: "";
  height: 3px;
  background-color: black;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33px;
}
.shopping-favorite-cart .cart-item-count {
  margin-top: 16px;
}
.shopping-favorite-cart .cart-block.selected:after {
  left: 9px;
}
.shopping-favorite-cart .favorite.selected,
.shopping-favorite-cart .selected .cart-header {
  cursor: default;
}
.shopping-favorite-cart .shopping-favorite-cart-header {
  padding: 0 10px 0 70px;
  height: 65px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #dedede;
}
.shopping-favorite-cart .selected-header {
  font-size: 1.25rem;
}
@media only screen and (max-width: 500px) {
  .shopping-favorite-cart .selected-header {
    font-size: 1rem;
  }
}
.shopping-favorite-cart .favorite-items {
  margin: 0;
  padding: 0;
  background-image: none;
  list-style: none;
}
.shopping-favorite-cart .favorite-item {
  border-bottom: 1px solid #dedede;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.shopping-favorite-cart .favorite-item a {
  color: rgb(30, 30, 30);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.shopping-favorite-cart .favorite-item a:hover {
  text-decoration: none;
}
.shopping-favorite-cart .favorite-details h3 {
  font-size: 1rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.shopping-favorite-cart .favorite-details .price {
  font-size: 1rem;
}
.shopping-favorite-cart .favorite-details .number,
.shopping-favorite-cart .favorite-details .unit {
  font-size: 0.7rem;
}
.shopping-favorite-cart .stock-status,
.shopping-favorite-cart .sku {
  font-size: 0.75rem;
}
.shopping-favorite-cart .item-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  line-height: 1.2em;
}
.shopping-favorite-cart .item-info .product-price .old-price {
  text-decoration: line-through;
  color: #969696;
  margin-right: 5px;
}
.shopping-favorite-cart .item-info .product-price .old-price .price {
  font-size: 14px;
}
.shopping-favorite-cart .item-details {
  width: 100%;
}
.shopping-favorite-cart .remove-item-action {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 20px;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.shopping-favorite-cart .remove-item-action:hover {
  background: transparent;
  color: rgb(30, 30, 30);
}
.shopping-favorite-cart .remove-item-action:before {
  margin-top: auto;
  margin-bottom: auto;
}
.shopping-favorite-cart .erase-list {
  border-bottom: 1px solid #dedede;
  text-align: right;
}
.shopping-favorite-cart .erase-button {
  font-size: 0.75rem;
  width: 30%;
  color: white;
  display: inline-block;
  padding: 8px 10px 8px;
  margin: 0 10px 15px 0;
  cursor: pointer;
  text-transform: none;
}
.shopping-favorite-cart .cart-content-close {
  position: absolute;
  display: block;
  top: 10px;
  left: 15px;
  text-indent: -9999px;
  background-color: transparent !important;
  color: black !important;
  width: 40px;
  height: 40px;
  padding: 0;
}
.shopping-favorite-cart .cart-content-close:after {
  content: "\e61d";
  text-indent: 0;
  display: block;
  font-size: 32px;
  margin-top: -0.3em;
}
.shopping-favorite-cart .shopping-cart {
  border-top: 0;
}
.shopping-favorite-cart .cart-footer {
  width: 100%;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top: 1px solid #dedede;
  background: rgba(255, 255, 255, 0.96);
}
.shopping-favorite-cart .cart-footer p {
  margin: 0;
}
.shopping-favorite-cart .cart-footer .checkout-link {
  width: auto;
  margin: 0 20px 20px 20px;
  display: block;
}
.shopping-favorite-cart .cart-footer .cart-summary {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 16px;
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal {
  font-size: 14px;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal {
    font-size: 12px;
  }
}
.shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal .price {
  font-size: 1.125rem;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .cart-grand-total.subtotal .price {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary {
    padding: 15px 10px;
  }
}
.shopping-favorite-cart .cart-footer .cart-summary .price {
  font-size: 1.375rem;
  margin: 0;
}
@media only screen and (max-width: 980px) {
  .shopping-favorite-cart .cart-footer .cart-summary .price {
    font-size: 1.125rem;
  }
}

.icon-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: auto;
}
.icon-container .favorite,
.icon-container .quick-view {
  display: -webkit-flex;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.icon-container .favorite:before {
  content: "\e90f";
  font-size: 20px;
}
.icon-container .favorite-active:before {
  content: "\e90c";
  color: #ff3838;
}
.icon-container .quick-view:before {
  content: "\e608";
  font-size: 20px;
}

.has-favorite-option .product-info {
  padding-bottom: 20px;
}

.product-offer .favorite {
  display: inline-block;
}

body {
  position: relative;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media only screen and (max-width: 980px) {
  #page-container {
    overflow-y: auto;
  }
}
.infinite-loading-container {
  font-size: 0.8rem;
  opacity: 0.5;
  padding: 15px 15px 20px;
  line-height: 1.1em;
  font-style: italic;
}

.has-favorite-cart .add-to-cart-form {
  position: relative;
}
.has-favorite-cart .add-to-cart-form .product-variants {
  margin-left: 0;
  width: calc(100% - 45px);
  margin-right: 45px;
}
.has-favorite-cart .add-to-cart-form .product-variants div:first-of-type {
  margin-left: 0;
}
.has-favorite-cart .add-to-cart-form .product-variants .field-wrap {
  width: 48%;
}
.has-favorite-cart .add-to-cart-form .order-data {
  -webkit-order: 100;
          order: 100;
  margin-right: 45px;
  width: calc(100% - 45px);
}
.has-favorite-cart .add-to-cart-form .product-add-to-cart-action,
.has-favorite-cart .add-to-cart-form .in-stock-notify,
.has-favorite-cart .add-to-cart-form .quantity-field,
.has-favorite-cart .add-to-cart-form .product-status {
  margin-right: 45px;
  width: calc(100% - 45px);
}
.has-favorite-cart .add-to-cart-form .toggle-favorite-action {
  width: 30px;
  position: absolute;
  right: 0;
  display: inline-block;
  padding-top: 5px;
}
.has-favorite-cart .favorite-wrap {
  margin-right: 20px;
}
.has-favorite-cart .favorite-wrap.favorite-has-items {
  margin-right: 0;
}
.has-favorite-cart .watchable .add-to-cart-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.has-favorite-cart .watchable .add-to-cart-form .in-stock-notify {
  margin-right: 15px;
}
.has-favorite-cart .watchable .add-to-cart-form .add-to-cart-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: 1;
          order: 1;
}
.has-favorite-cart .watchable .add-to-cart-form .add-to-cart-wrap .toggle-favorite-action {
  position: static;
}
.has-favorite-cart .buyable .add-to-cart-form {
  margin-bottom: 20px;
}
.has-favorite-cart .non-watchable-non-buyable .add-to-cart-form .toggle-favorite-action {
  position: static;
}

html#product-page .shopping-favorite-cart .product-price {
  margin: 0;
}

.quick-search-results {
  background: #ffffff;
  min-width: 355px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  z-index: 199;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .quick-search-results {
    min-width: 315px;
  }
}
.quick-search-results .wrapper-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px 10px 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.quick-search-results .header-products {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.quick-search-results .search-link {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.quick-search-results .search-link a {
  font-weight: 600;
}
.quick-search-results .quick-search-result {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 10px;
  line-height: 1.3em;
  cursor: pointer;
}
.quick-search-results .quick-search-result:last-of-type {
  margin-bottom: 20px;
}
.quick-search-results .quick-search-result .image {
  width: 100%;
  max-width: 55px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.quick-search-results .quick-search-result .image img {
  display: block;
  max-height: 55px;
}
.quick-search-results .quick-search-result .price {
  margin: 0 0 auto auto;
  padding-top: 5px;
  font-size: 1rem;
}
.quick-search-results .quick-search-result .wrapper-content {
  margin-left: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  padding-right: 20px;
}
.quick-search-results .quick-search-result .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 5px;
}
.quick-search-results .quick-search-result .name strong {
  font-weight: 300;
}
.quick-search-results .quick-search-result .category {
  font-size: 0.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 2px;
}

.unifaun-checkout-clip-box {
  overflow: hidden;
}

.unifaun-checkout-option-header0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 10px;
  position: relative;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-radio {
  position: relative;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-radio input {
  position: absolute;
  top: 5px;
  left: 0;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-price {
  position: absolute;
  top: 0;
  right: 0;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-columns {
  width: 100%;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-agents-wrapper {
  position: relative;
  width: 100%;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-agents-wrapper:after {
  position: absolute;
  content: "\f0dc";
  line-height: 30px;
  right: 1px;
  top: 3px;
  width: 32px;
  height: 32px;
  text-align: center;
  color: silver;
  font-size: 1rem;
  transition: color linear 0.2s;
  pointer-events: none;
}
.unifaun-checkout-option-header0 .unifaun-checkout-option-agents-wrapper:hover:after {
  color: #1e1e1e;
}

.unifaun-checkout-addon-description {
  margin-left: 10px;
}

.unifaun-checkout-list-input-label {
  margin-top: 10px;
}

.unifaun-checkout-option-sub-options .unifaun-checkout-addon-label,
.unifaun-checkout-option-sub-options .unifaun-checkout-text-input-label {
  display: block;
  padding-top: 10px;
}
.unifaun-checkout-option-sub-options .unifaun-checkout-text-input-input {
  margin: 0;
  outline: 0;
  color: #1e1e1e;
  cursor: text;
  display: block;
  padding: 4px 3px 4px 6px;
  border: 1px solid silver;
  width: 100%;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  background-color: white;
  border-radius: 2px;
  text-indent: 0;
  line-height: 22px;
}

.unifaun-checkout-text-input-label-message {
  background-color: #e23535;
  padding: 2px 6px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1.1em;
  display: none;
  -webkit-order: 100;
          order: 100;
}

.unifaun-checkout-invalid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.unifaun-checkout-invalid .unifaun-checkout-text-input-label-message {
  display: block;
}

.unifaun-checkout-option-sub-column1 {
  padding-bottom: 10px;
}

.unifaun-checkout-option-column2 {
  padding-top: 10px;
  padding-left: 25px;
}

.unifaun-checkout-option-agent-panel {
  padding-bottom: 10px;
}

.unifaun-checkout-option-column1 {
  position: relative;
}
@media only screen and (max-width: 500px) {
  .unifaun-checkout-option-column1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.unifaun-checkout-option-delivery {
  padding-left: 25px;
  color: #b7b7b7;
  font-weight: 300;
  font-size: 0.7rem;
}

.unifaun-checkout-option-column1-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.unifaun-checkout-option-column1-text br {
  display: none;
}

.unifaun-checkout-option-suffix-icon {
  position: absolute;
  top: 0;
  right: 75px;
  display: block;
}
@media only screen and (max-width: 500px) {
  .unifaun-checkout-option-suffix-icon {
    position: static;
    padding-left: 25px;
    -webkit-order: -1;
            order: -1;
  }
}
.unifaun-checkout-option-suffix-icon img {
  height: auto;
  max-width: 80px;
  max-height: 40px;
}

#checkout-page .unifaun-checkout-option-column1-text label {
  font-weight: 600;
  line-height: 18px;
  font-size: 1rem;
  padding-left: 25px;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods {
  -webkit-order: 0;
          order: 0;
  margin-bottom: 30px;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content {
  background-color: rgb(248, 248, 248);
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content .unifaun-checkout-option0 {
  padding: 10px;
  border-top: 1px solid rgb(230, 230, 230);
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content .unifaun-checkout-option0.unifaun-checkout-selected0 {
  background-color: rgb(254, 254, 254);
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content .unifaun-checkout-option0.unifaun-checkout-selected0 > .unifaun-checkout-clip-box {
  padding-left: 25px;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content > .group-items.two {
  padding: 10px;
  background-color: white;
  margin-left: 0;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content > .group-items.two .group-item {
  width: 49%;
  float: left;
  margin-left: 2%;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .shipping-methods .content > .group-items.two .group-item:first-child {
  margin-left: 0;
}
#checkout-page .has-unifaun-delivery-checkout .select-wrap > .payment-shipping-methods .payment-methods {
  -webkit-order: 1;
          order: 1;
}